import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./GraphicDesigner.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import BigTitle from "../../text/BigTitle";
import MarketeerPic from "../../../assets/img/sk_marketeer.jpg";
import StrategyBlock from "../strategy/StrategyBlock";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import Helmet from "react-helmet";
import JobTitle from "../../text/JobTitle";
import SimpleText from "../../text/SimpleText";
interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class GraphicDesigner extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | Jobs - Graphic Designer</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <BigTitle
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                      lineHeight: 1,
                      maxWidth: 300,
                      textAlign: "left",
                    }}
                  >
                    Gezocht:
                  </BigTitle>
                  <JobTitle>GRAPHIC DESIGNER</JobTitle>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <StrategyBlock
              right={true}
              src={MarketeerPic}
              title="We want you!"
              text="Voor de groei van Skillmedia zijn we op zoek naar iemand om ons team van graphic designers te versterken. Reward: een job waar je graag bent én je creatieve ei kwijt kan."
            />
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <Grid container className={classes.topBox}>
              <Grid container className={classes.specsHolder}>
                <Grid item xs={12} md={6}>
                  <JobTitle>GRAPHIC DESIGNER</JobTitle>
                  <SimpleText style={{ textAlign: "left", marginTop: 10 }}>
                    Om ons team uit te breiden is Skillmedia op zoek naar een
                    ambitieuze en ervaren Graphic Designer.
                    <br />
                    <br />
                    Als Graphic Designer ben je samen met je team
                    verantwoordelijk voor het grafische materiaal van de
                    campagnes van onze klanten. Je helpt de klant zijn markt aan
                    te spreken met jouw frisse en creatieve designs. Dit doe je
                    natuurlijk niet alleen, je krijgt alle hulp van je team
                    wanneer je die nodig hebt.
                    <br />
                    <br />
                    Je hebt een zeer proactieve houding gecombineerd met een
                    aansturende rol en dat dringt door in je werk. Je bent
                    creatief, vooruitdenkend, en hebt altijd wel ‘een ideetje’
                    om iets tot een mooie visual om te toveren. Je zet alles op
                    alles om het maximale te bereiken voor je klanten en zoekt
                    steeds naar originele concepten en designs.
                  </SimpleText>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 100 }}
                className={classes.specsHolder}
              >
                <Grid item xs={12} md={6}>
                  <SimpleText style={{ textAlign: "left", marginRight: 40 }}>
                    <strong>Profiel</strong>
                    <ul>
                      <li>
                        Je hebt minimaal 2 jaar Grafische Design-ervaring of
                        vergelijkbaar en werkt graag in een ambitieus team.
                      </li>
                      <li>
                        Je bent zeer ambitieus ingesteld en zorgt er zelfstandig
                        voor dat je altijd op de hoogte bent van de laatste
                        ontwikkelingen en trends binnen de sector.
                      </li>
                      <li>
                        Je hebt een proactieve houding en bent een creatieve
                        duizendpoot die graag zijn/haar skills toont.
                      </li>
                      <li>
                        Je bent stressbestendig, zeer communicatief en
                        klantvriendelijk ingesteld.
                      </li>
                      <li>
                        Je kan een abstract idee omzetten in een Graphic
                        Masterpiece.
                      </li>
                      <li>
                        Je aarzelt niet om met creatieve ideeën te komen, hoe
                        wild ze ook bedacht zijn.
                      </li>
                      <li>
                        Je ontwerpt allerlei promotiemateriaal zoals flyers,
                        brochures, productcatalogussen, advertenties, websites
                        en kan deze files in een handomdraai drukklaar maken.
                      </li>
                      <li>
                        Je hebt oog voor detail én nieuwe trends en bent
                        leergierig.
                      </li>
                      <li>
                        De programma's Indesign, Photoshop, Illustrator,
                        Premiere Pro, After Effects en UX Design hebben weinig
                        tot geen geheimen voor jou.
                      </li>
                      <li>
                        Skills in Marketing, Social Media en Copywrting zijn een
                        ++
                      </li>
                    </ul>
                    <br />
                    <br />
                    <strong>
                      De uitdagingen die we voor jou bij Skillmedia hebben
                      klaarstaan:
                    </strong>
                    <br />
                    Realisaties van logo’s, huisstijlen, websitedesigns,
                    drukwerk, online advertenties (ook video), advertorials,
                    animation, publicaties, campagnebeelden...
                    <br />
                    Bedenken van creatieve concepten en ideeën.
                    <br />
                    Marketing projecten visueel uitwerken.
                    <br />
                    Promotiemateriaal ontwerpen en drukklaar maken.
                    <br />
                    Fotobewerking én video’s editen die een streling worden voor
                    het oog.
                    <br />
                    Social Media content creatie.
                    <br />
                    <br />
                    <strong>Aanbod</strong>
                    <ul>
                      <li>
                        Als Graphic Designer kom je in een jonge werkomgeving
                        terecht van eendynamisch en sfeervol agency
                      </li>
                      <li>Een aantrekkelijk en competitief loon</li>
                      <li>
                        Opleidingsbudget voor evenementen, beurzen en nieuwe
                        programma’s
                      </li>
                      <li>Legale voordelen (maaltijdcheques, ..)</li>
                      <li>
                        Samenwerken in een gedreven, jonge en dynamische
                        werkomgeving met mensen die elkaar ondersteunen en
                        helpen groeien
                      </li>
                      <li>
                        Constante mogelijkheid om jouw kennisniveau te
                        vergroten, ook onder werktijd
                      </li>
                    </ul>
                  </SimpleText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Heb jij de skills die wij zoeken?
            </BigTitle>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SubTitle
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  maxWidth: 800,
                  fontSize: 20,
                  lineHeight: 1,
                }}
              >
                Stuur je motivatie en sollicitatie in en wie weet word je
                binnenkort onderdeel van het team.
              </SubTitle>
            </div>
            <div>
              <a
                href="mailto:hr@skillmedia.be?subject=Sollicitatie Digital Marketeer&body=Hierbij stuur ik mijn sollicitatie met motivatie en CV."
                style={{ textDecoration: "none" }}
              >
                <StyledButton inverse={true}>
                  STUUR JE SOLLICITATIE IN
                </StyledButton>
              </a>
            </div>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(GraphicDesigner));
