import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    blogTags: {
      marginTop: 16,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 18,
      textTransform: "uppercase",
      fontWeight: "bold",
      letterSpacing: -1,
      lineHeight: 0.9,
      color: "#779ada",
    },
    blogHolder: {
      display: "flex",
      justifyContent: "center",
    },
    blogBox: {
      width: "100%",
      maxWidth: 680,
    },
  });

export default styles;
