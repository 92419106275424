import { makeStyles } from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const useStyles = makeStyles({
  styledBtn: {
    fontSize: 24,
    fontFamily: "Plantin MT Pro, sans serif",
    color: "black",
    textTransform: "none",
    marginTop: 20,
    marginBottom: 20,
    borderBottom: "1px solid black",
  },
});

interface OwnProps {
  className?: any;
  style?: any;
  children?: any;
}

type Props = OwnProps;

const StyledSimpleButton = (props: Props) => {
  const classes = useStyles();
  const { className, style, ...restOfProps } = props;
  return (
    <span
      className={classNames(
        classes.styledBtn,
        props.className ? props.className : null,
      )}
      style={style ? style : null}
      {...restOfProps}
    >
      {props.children}
    </span>
  );
};

export default StyledSimpleButton;
