import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./DigitalMarketeer.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import BigTitle from "../../text/BigTitle";
import MarketeerPic from "../../../assets/img/sk_marketeer.jpg";
import StrategyBlock from "../strategy/StrategyBlock";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import Helmet from "react-helmet";
import JobTitle from "../../text/JobTitle";
import SimpleText from "../../text/SimpleText";
interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class FullStackDeveloper extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | Jobs - Fullstack Developer</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <BigTitle
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                      lineHeight: 1,
                      maxWidth: 300,
                      textAlign: "left",
                    }}
                  >
                    Gezocht:
                  </BigTitle>
                  <JobTitle>JUNIOR FULLSTACK DEVELOPER</JobTitle>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <StrategyBlock
              right={true}
              src={MarketeerPic}
              title="We want you!"
              text="Voor de groei van Skillmedia zijn we op zoek naar iemand om ons team van developers te versterken. Reward: een job waar je graag bent."
            />
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <Grid container className={classes.topBox}>
              <Grid container className={classes.specsHolder}>
                <Grid item xs={12} md={6}>
                  <JobTitle>JUNIOR FULLSTACK DEVELOPER</JobTitle>
                  <SimpleText style={{ textAlign: "left", marginTop: 10 }}>
                    Om ons team uit te breiden is Skillmedia op zoek naar een
                    ambitieuze developer.
                    <br />
                    <br />
                    Als Fullstack developer ben je samen met je team
                    verantwoordelijk voor het ontwikkelen van kick-ass websites,
                    tools en applicaties van onze klanten. Je zet met jouw
                    kennis van code een creatief design om in een innovatief
                    product. Je kan zelfstandig het front-end gedeelte voor jouw
                    rekening nemen. Wat er zich achter de schermen in het
                    back-end gedeelte af speelt is voor jou geen onbekend
                    terrein. Met jouw database skills zorg je ervoor dat apps
                    voorzien worden van content. Dit doe je natuurlijk niet
                    alleen, het team vult je aan wanneer je het nodig hebt.
                    <br />
                    <br />
                    Je bent analytisch ingesteld en je hebt een zeer proactieve
                    houding. Je bent een perfectionist en dat dringt door in je
                    werk. Je bent creatief, vooruitdenkend, en hebt altijd wel
                    ‘een oplossing’ om het idee van de klant in een innovatief
                    product te toveren. Je zet alles op alles om het maximale te
                    bereiken voor je klanten en je streeft er steeds naar om het
                    beste af te leveren. Je bent zeer gedreven om je product te
                    optimaliseren tot in het kleinste detail om snelheid en
                    gebruiksvriendelijkheid te garanderen.
                  </SimpleText>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 100 }}
                className={classes.specsHolder}
              >
                <Grid item xs={12} md={6}>
                  <SimpleText style={{ textAlign: "left", marginRight: 40 }}>
                    <strong>Profiel</strong>
                    <ul>
                      <li>
                        Je hebt een bachelor diploma Computer Science,
                        Toegapaste Informatica of Informatiemanagement en
                        -systemen.
                      </li>
                      <li>
                        Je hebt minimaal 2 jaar developer ervaring of
                        vergelijkbaar en werkt graag in een ambitieus team.
                      </li>
                      <li>
                        Je bent analytisch ingesteld en je kan zeer snel
                        oplossingen voorstellen.
                      </li>
                      <li>
                        Je zorgt er zelfstandig voor dat je altijd op de hoogte
                        bent van de laatste ontwikkelingen en trends binnen de
                        sector.
                      </li>
                      <li>
                        Je hebt een proactieve houding en bent een creatieve
                        duizendpoot die graag zijn/haar skills toont.
                      </li>
                      <li>
                        Je bent stressbestendig, zeer communicatief en
                        klantvriendelijk ingesteld.
                      </li>
                      <li>
                        Je kan een abstract idee omzetten in een perfect werkend
                        stuk code.
                      </li>
                      <li>
                        Je hebt kennis van React, Javascript en PHP. Kennis van
                        React Native is een +
                      </li>
                      <li>
                        Je kan zonder problemen een REST API ontwikkelen in
                        NodeJS
                      </li>
                      <li>
                        Je hebt ervaring met Wordpress websites en Woocommerce
                      </li>
                      <li>
                        Je hebt kennis van databases zoals MSSQL en MYSQL.
                        Kennis van MongoDB is een +
                      </li>
                      <li>
                        Je hebt een zeer goede kennis van CSS en HTML5. Je kan
                        eender welk ontwerp omzetten in een werkende website
                      </li>
                      <li>
                        Je kan werken volgens het MVC principe en je hebt
                        ervaring in component based coding
                      </li>
                      <li>Je kan werken met Git</li>
                      <li>
                        Je kan zonder problemen files op FTP zetten, MYSQL
                        databases aanmaken en bewerken
                      </li>
                      <li>
                        Je kan een PWA bouwen, je kan je creaties optimaliseren
                        met Google Lighthouse en je ervaring met unit-testing is
                        een +
                      </li>
                      <li>Je kan perfect Engelstalig communiceren</li>
                    </ul>
                    <br />
                    <br />
                    <strong>Aanbod</strong>
                    <ul>
                      <li>
                        Als Fullstack developer kom je in een jonge werkomgeving
                        terecht van een dynamisch en sfeervol agency.
                      </li>
                      <li>Een aantrekkelijk en competitief loon.</li>
                      <li>
                        Opleidingsbudget voor evenementen, beurzen en nieuwe
                        programma’s.
                      </li>
                      <li>
                        Samenwerken in een gedreven, jonge en dynamische
                        werkomgeving met mensen die elkaar ondersteunen en
                        helpen groeien
                      </li>
                      <li>Legale voordelen (maaltijdcheques, ..)</li>
                      <li>
                        Constante mogelijkheid om jouw kennisniveau te
                        vergroten, ook onder werktijd
                      </li>
                    </ul>
                    <br />
                    <br />
                    <strong>
                      De uitdagingen die we voor jou bij Skillmedia hebben
                      klaarstaan:
                    </strong>
                    <br />
                    Ontwikkelen van innovatieve van websites en applicaties.
                    <br />
                    Bedenken van creatieve oplossigen.
                    <br />
                    Implementeren van talrijke API’s.
                    <br />
                    Werken met de nieuwste ontwikkelingen en de beste hardware.
                  </SimpleText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Heb jij de skills die wij zoeken?
            </BigTitle>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SubTitle
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  maxWidth: 800,
                  fontSize: 20,
                  lineHeight: 1,
                }}
              >
                Stuur je motivatie en sollicitatie in en wie weet word je
                binnenkort onderdeel van het team.
              </SubTitle>
            </div>
            <div>
              <a
                href="mailto:hr@skillmedia.be?subject=Sollicitatie Digital Marketeer&body=Hierbij stuur ik mijn sollicitatie met motivatie en CV."
                style={{ textDecoration: "none" }}
              >
                <StyledButton inverse={true}>
                  STUUR JE SOLLICITATIE IN
                </StyledButton>
              </a>
            </div>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(FullStackDeveloper));
