import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  smallTitle: {
    fontSize: 28,
    color: "#161616",
    fontFamily: "Plantin MT Pro, sans serif",
    fontWeight: "bold",
    textDecoration: "underline",
    [theme.breakpoints.down("lg")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      lineHeight: 1.1,
    },
    "&:hover": {
      color: "#779ada",
    },
  },
}));

const SmallLink = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.smallTitle}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default SmallLink;
