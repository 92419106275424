import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Video.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import BigText from "../../text/BigText";
import BigTitle from "../../text/BigTitle";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import { NavLink } from "react-router-dom";
import SmallText from "../../text/SmallText";
import HomeSimpleBlock from "../home/HomeSimpleBlock";
import StyledSimpleButton from "../../button/StyledSimpleButton";
import Helmet from "react-helmet";
import Translate from "../../../Translate/Translate";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Marketing extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | {Translate.translate("videoTitle")}</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              marginBottom: 0,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <BigTitle
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                      lineHeight: 1,
                      maxWidth: 500,
                      textAlign: "left",
                    }}
                  >
                    {Translate.translate("videoTitle2")}
                  </BigTitle>
                  <BigText
                    style={{
                      maxWidth: 460,
                      marginTop: 0,
                      paddingTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    {Translate.translate("videoSubtitle")}
                  </BigText>
                </div>
                <Grid container style={{ height: 40 }} />
                <NavLink to="/contact" style={{ textDecoration: "none" }}>
                  <StyledButton inverse style={{ marginTop: 0 }}>
                    {Translate.translate("videoExperience")}
                  </StyledButton>
                </NavLink>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <NavLink
                  to="/skills/productie"
                  style={{ textDecoration: "none", marginRight: 60 }}
                >
                  <StyledSimpleButton>
                    {Translate.translate("videoBack")}
                  </StyledSimpleButton>
                </NavLink>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container style={{ height: 50 }} />
              <SmallText style={{ maxWidth: 800 }}>
                {Translate.translate("videoSatisfied")}
              </SmallText>
            </Grid>
            <Grid container style={{ height: 50 }} />
            <div className={classes.videoHolder}>
              <iframe
                title="ginsonline"
                className={classes.videoH}
                width="100%"
                height="600"
                src="https://www.youtube.com/embed/yeu7fRjhhBQ"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Grid>
          <Grid container style={{ height: 150 }} />
          <Grid
            container
            className={classes.midRoot}
            style={{
              marginBottom: 60,
              minHeight: theWindowHeight - 60,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              backgroundColor: "white",
              paddingTop: 140,
              paddingBottom: 100,
            }}
          >
            <BigTitle style={{ width: "100%", marginBottom: 50 }} variant="h2">
              {Translate.translate("homeHowWeWork")}
            </BigTitle>
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HomeSimpleBlock
                first={true}
                className="animate__animated animate__backInRight"
                number="1"
                title={Translate.translate("videoHowTitle1")}
                text={Translate.translate("videoHowText1")}
              />
              <HomeSimpleBlock
                className="animate__animated animate__backInLeft"
                number="2"
                title={Translate.translate("videoHowTitle2")}
                text={Translate.translate("videoHowText2")}
              />
              <HomeSimpleBlock
                className="animate__animated animate__backInRight"
                number="3"
                title={Translate.translate("videoHowTitle3")}
                text={Translate.translate("videoHowText3")}
              />
              <HomeSimpleBlock
                className="animate__animated animate__backInLeft"
                number="4"
                title={Translate.translate("videoHowTitle4")}
                text={Translate.translate("videoHowText4")}
              />
              <HomeSimpleBlock
                className="animate__animated animate__backInRight"
                number="5"
                title={Translate.translate("videoHowTitle5")}
                text={Translate.translate("videoHowText5")}
              />
              <NavLink to="/contact" style={{ textDecoration: "none" }}>
                <StyledButton
                  className={
                    "animate__animated animate__bounceInUp animate__delay-1s"
                  }
                  style={{ marginTop: 60 }}
                >
                  {Translate.translate("homeLetsGrow")}
                </StyledButton>
              </NavLink>
            </div>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{
                width: "100%",
                marginBottom: 0,
                paddingBottom: 0,
                maxWidth: 600,
              }}
              variant="h2"
            >
              {Translate.translate("videoContact")}
            </BigTitle>
            <SubTitle
              style={{
                marginTop: 0,
                paddingTop: 0,
                width: "100%",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              {Translate.translate("videoContactSubtitle")}
            </SubTitle>
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <StyledButton inverse={true}>
                {Translate.translate("contactLetsWork")}
              </StyledButton>
            </NavLink>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Marketing));
