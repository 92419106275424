import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  hugeTitle: {
    fontSize: 74,
    color: "#161616",
    fontFamily: "Plantin MT Pro, sans serif",
    fontWeight: "bold",
    [theme.breakpoints.down("lg")]: {
      fontSize: 70,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 64,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 54,
      lineHeight: 1.1,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 48,
      lineHeight: 1.1,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 44,
      lineHeight: 1.1,
    },
    textAlign: "center",
  },
}));

const HugeTitle = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.hugeTitle}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default HugeTitle;
