import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  bigTitle: {
    fontSize: 38,
    color: "#161616",
    fontWeight: "bold",
    background:
      "linear-gradient(to right, rgb(103, 195, 234,1), rgba(105, 141, 200, 1) 50%);",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("lg")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 34,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      lineHeight: 1.1,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
      lineHeight: 1.1,
    },
  },
}));

const JobTitle = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      variant={props.variant || "h3"}
      className={classes.bigTitle}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default JobTitle;
