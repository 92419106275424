import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../../redux/reducers";
import Header from "../../../partials/Header";
import PageView from "../../../partials/PageView";
import styles from "./Sipwell.styles";
import { connect } from "react-redux";
import Footer from "../../../partials/Footer";
import BigText from "../../../text/BigText";
import BigTitle from "../../../text/BigTitle";
import SubTitle from "../../../text/SubTitle";
import StyledButton from "../../../button/StyledButton";
import { NavLink } from "react-router-dom";
import RadiusImg from "../../../image/RadiusImg";
import TopImg from "../../../../assets/img/case_sipwell_banner.jpg";
import Content1 from "../../../../assets/img/case_sipwell_content_1.png";
import Content2 from "../../../../assets/img/case_sipwell_content_2.png";
import Content3 from "../../../../assets/img/case_sipwell_content_3.png";
import { ThemeAbstracts } from "../../../../config/MuiTheme";
import SmallTitle from "../../../text/SmallTitle";
import SmallText from "../../../text/SmallText";
import Content4 from "../../../../assets/img/case_sipwell_content_4.png";
import Content5 from "../../../../assets/img/case_sipwell_content_5.png";
import Content6 from "../../../../assets/img/case_sipwell_content_6.png";
import Content7 from "../../../../assets/img/case_sipwell_content_7.png";
import Content8 from "../../../../assets/img/case_sipwell_content_8.png";
import Helmet from "react-helmet";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Sipwell extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | Case Sipwell - Datastructuur en groei</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 680,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <BigTitle
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        lineHeight: 1,
                        maxWidth: 600,
                        textAlign: "left",
                      }}
                      bigger={true}
                    >
                      Sipwell.
                    </BigTitle>
                    <SmallText
                      style={{
                        maxWidth: 500,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Datastructuur en groei voor de Belgische marktleider in
                      waterlevering
                    </SmallText>
                    <p className={classes.blogTags}>
                      strategy - branding - digital marketing - graphic design -
                      copywriting - development - analytics
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <div
              style={{ width: "100%" }}
              className={"animate__animated animate__flipInX"}
            >
              <RadiusImg
                src={TopImg}
                maxWidth={ThemeAbstracts.layout.maxPageWidth}
                maxHeight={460}
                noEffect={true}
              />
            </div>
            <Grid container style={{ height: 100 }} />
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  Groeien als marktleider
                </SmallTitle>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  SipWell is een Belgisch bedrijf en marktleider in
                  waterlevering voor zowel bedrijven als particulieren. Om hun
                  positie in de markt te versterken, bundelde SipWell haar
                  krachten met Skillmedia om meer structuur en inzicht in hun
                  online marketing efforts te realiseren. Door een krachtige en
                  proactieve samenwerking kon SipWell meer gerichte beslissingen
                  maken met als gevolg: een sterke groei in nieuwe klanten. Hoe
                  hebben we dit gedaan?
                </SmallText>
                <Grid container direction="row" style={{ marginTop: 40 }}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content1} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content2} width="100%" alt="content2" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content3} width="100%" alt="content3" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#e9e9e9",
              fontSize: 20,
              marginTop: 80,
              paddingTop: 60,
              paddingBottom: 60,
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
            }}
          >
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  Keihard leads genereren
                </SmallTitle>
                <div style={{ lineHeight: 1.4 }}>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Om SipWell optimaal te ondersteunen in hun salesproces
                    werden alle marketinginspanningen opgezet in fuctie van hun
                    salesteam. Door het genereren van online leads kon ‘Sales'
                    en ‘Marketing' als een geoliede machine samenwerken.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Om dit te verwezenlijken identificeerden we alle touchpoints
                    die potentiële SipWell-klanten kunnen ervaren. Zo zorgden we
                    voor een duidelijke opdeling in een overzichtelijke Customer
                    Journey.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Zodra alle routes naar de contactformulieren van SipWell
                    duidelijk in kaart werden gebracht, ging de bal vlot aan het
                    rollen.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    We weten nu op welke manieren potentiële klanten in contact
                    komen met SipWell en wat de doelstellingen van de campagnes
                    zijn. We deelden de markt op in kwalitatieve doelgroepen
                    zodat we een optimaal resultaat uit de campagnes konden
                    genereren.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Vervolgens splitsten we het doelpubliek van SipWell in B2C
                    en B2B. Gezien de complexiteit van België hielden we
                    rekening met de taalgrens. Zo deelden we de doelgroepen nog
                    eens op in zowel Nederlands als Frans.
                  </p>
                  <p
                    className="margin-bottom-3"
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Door het opsplitsen van deze doelgroepen konden we een op
                    maat gemaakte Marketing Funnel maken zodat we elke doelgroep
                    zo goed mogelijk konden aanspreken. We deelden de funnel als
                    volgt in:
                  </p>
                  <img
                    className="margin-bottom-3"
                    src={Content4}
                    alt="awareness content"
                    width="100%"
                  />
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Het gebruik van een Marketing Funnel geeft ons de
                    mogelijkheid om kwalitatieve personen (lees: personen met
                    een hoge kans om tot klant te converteren) uit de doelgroep
                    meer aandacht te geven.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Door het uitwerken van de Customer Journey en het opdelen
                    van de campagnes naargelang de Marketing Funnel, konden we
                    de juiste boodschap naar de juiste doelgroep sturen in
                    verhouding met de fase waar ze zich in bevonden.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    <i>
                      "De juiste boodschap, op het juiste moment, aan de juiste
                      personen tonen"
                    </i>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              fontSize: 20,
              paddingTop: 60,
              paddingBottom: 60,
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
            }}
          >
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <Grid item xs={12} style={{ lineHeight: 1.4 }}>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Visueel kunnen we de KPI's als volgt indelen en systematisch
                    maandelijks bijsturen waar nodig:
                  </p>
                  <img src={Content5} alt="awareness content 5" width="100%" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#e9e9e9",
              fontSize: 20,
              marginTop: 80,
              paddingTop: 60,
              paddingBottom: 60,
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
            }}
          >
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <Grid
                  container
                  direction="row"
                  style={{ lineHeight: 1.4, paddingBottom: 40 }}
                >
                  <Grid item xs={12} md={8}>
                    <h3>Voorbeelden</h3>
                    <SmallTitle
                      style={{
                        marginTop: 20,
                        lineHeight: 1.3,
                        textAlign: "left",
                        marginBottom: 20,
                      }}
                    >
                      Awareness Ads
                    </SmallTitle>
                    <p
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Geadverteerd door heel België met als doel mensen te
                      informeren en bewust te maken dat je voldoende water moet
                      drinken.
                    </p>
                    <p
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      <strong>Doel:</strong> Interesse en naambekendheid creëren
                      voor SipWell d.m.v. likes, comments, Social Media of
                      websitebezoek.
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                      textAlign: "right",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Content6}
                      width="150"
                      height="150"
                      alt="awareness content7"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  style={{
                    lineHeight: 1.4,
                    fontSize: 20,
                    paddingTop: 40,
                    paddingBottom: 40,
                    borderTop: "1px solid lightgrey",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Content7}
                      width="150"
                      height="150"
                      alt="awareness content8"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <SmallTitle
                      style={{
                        marginTop: 20,
                        lineHeight: 1.3,
                        textAlign: "left",
                        marginBottom: 20,
                      }}
                    >
                      Persuasion Ads
                    </SmallTitle>
                    <p
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Gericht naar mensen die reeds de diensten van SipWell
                      zochten via Google of engagement toonden op de Awareness
                      campagne van SipWell.
                    </p>
                    <p
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      <strong>Doel:</strong> Mensen verder informeren over de
                      voordelen van SipWell en hun waterkoelers d.m.v een
                      website bezoek.
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  style={{
                    lineHeight: 1.4,
                    fontSize: 20,
                    paddingTop: 40,
                    paddingBottom: 40,
                    borderTop: "1px solid lightgrey",
                  }}
                >
                  <Grid item xs={12} md={8}>
                    <SmallTitle
                      style={{
                        marginTop: 20,
                        lineHeight: 1.3,
                        textAlign: "left",
                        marginBottom: 20,
                      }}
                    >
                      Conversion Ads
                    </SmallTitle>
                    <p
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Door de juiste cohorts (datasets) te creëren zagen we dat
                      92% van de mensen binnen de week een contactformulier
                      invulde omdat we urgentie creërden met slimme remarketing
                      ads. Deze campagne adverteerden we dus naar mensen die
                      zich de afgelopen 7 dagen engageerden via Social Media of
                      de website bezochten via andere kanalen zoals Google
                      search, display of rechtstreeks via andere media.
                    </p>
                    <p
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      <strong>Doel:</strong> Leads genereren via speciaal
                      gecreëerde landingspagina's.
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Content8}
                      width="150"
                      height="150"
                      alt="awareness content9"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  style={{
                    lineHeight: 1.4,
                    fontSize: 20,
                    paddingTop: 40,
                    paddingBottom: 40,
                    borderTop: "1px solid lightgrey",
                  }}
                >
                  <Grid item xs={12}>
                    <SmallTitle
                      style={{
                        marginTop: 20,
                        lineHeight: 1.3,
                        textAlign: "left",
                        marginBottom: 20,
                      }}
                    >
                      Altijd raak
                    </SmallTitle>
                    <p
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Om onze samenwerking optimaal te laten verlopen, werkten
                      we telkens een ‘Marketing Monthly’- rapportage uit met een
                      duidelijke beeld over de behaalde cijfers en volgende
                      stappen.
                    </p>
                    <p
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Doordat SipWell de krachten bundelde met Skillmedia,
                      werden hun targets 3 jaar op rij behaald en groeien ze
                      jaar op jaar verder.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <NavLink
              to="/work"
              style={{ marginTop: 60, textDecoration: "none" }}
            >
              <StyledButton
                className={"animate__animated animate__bounceInUp"}
                style={{ marginTop: 60 }}
              >
                MEER CASES
              </StyledButton>
            </NavLink>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Contacteer ons
            </BigTitle>
            <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
              Ontdek hoe Skillmedia je helpt groeien
            </SubTitle>
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <StyledButton inverse={true}>LATEN WE SAMENWERKEN</StyledButton>
            </NavLink>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Sipwell));
