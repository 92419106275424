import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";
const styles = (theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
    },
    topHolder: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    },
    topH: {
      justifyContent: "center",
      alignItems: "center",
    },
    topBox: {
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 100,
      paddingBottom: 100,
    },
  });

export default styles;
