import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Contact.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import ContactForm from "./ContactForm";
import Helmet from "react-helmet";
import Translate from "../../../Translate/Translate";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Contact extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | {Translate.translate("contactTitle")}</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="row"
            className={classes.topBox}
            style={{ paddingTop: 80, paddingLeft: 10 }}
          >
            <Grid item xs={12} md={6}>
              <h1 className={classes.title}>
                TELL US WHAT
                <br />
                YOU NEED<span className="blinker">_</span>
              </h1>
              <h2 className={classes.subTitle}>
                {Translate.translate("contactSubtitle")}
              </h2>
            </Grid>
            <Grid item xs={12} md={6} className={classes.rightSide}>
              <p className={classes.call}>
                {Translate.translate("contactCall")}
              </p>
              <a className={classes.callLink} href="tel:0032493473331">
                +32 (0)493 47 33 31
              </a>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.topBox}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <ContactForm
              title="Contact"
              subTitle="Hoi"
              sendText={Translate.translate("contactSend")}
            />
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.topBox}
            style={{ paddingLeft: 10 }}
          >
            <Grid item xs={12} md={4}>
              <p className={classes.bottomHQ}>Skillmedia HQ</p>
              <p className={classes.bottomText}>Rijnkaai 37 Bus 16</p>
              <p className={classes.bottomText}>2000 Antwerpen</p>
              <a
                href="https://www.google.be/maps/place/Skillmedia/@51.2303064,4.4005029,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3f4181cd24dff:0xbc716d185cbf361a!8m2!3d51.2303064!4d4.4026969"
                className={classes.bottomSmallLink}
              >
                check location
              </a>
            </Grid>
            <Grid item xs={12} md={4} className={classes.middleGrid}>
              <a href="tel:0032493473331" className={classes.bottomLink}>
                +32 (0)493 47 33 31
              </a>
              <a
                href="mailto:info@skillmedia.be"
                className={classes.bottomLink}
              >
                info@skillmedia.be
              </a>
            </Grid>
            <Grid item xs={12} md={4} className={classes.rightGrid}></Grid>
          </Grid>
          <Grid container style={{ height: 90 }} />
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Contact));
