import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../../config/MuiTheme";

const styles = (theme: Theme) =>
    createStyles({
        topContainer: {
            justifyContent: "flex-start",
            alignItems: "center",
        },
        // container: {
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "flex-start",
        //     alignItems: "center",
        //     width: "100%",
        //     // maxWidth: ThemeAbstracts.layout.maxPageWidth,
        // },
        topBox: {
            width: "100%",
            maxWidth: ThemeAbstracts.layout.maxPageWidth,
            display: "flex",
            alignItems: "flex-start",
        },
        middleBox: {
            width: "100%",
            maxWidth: ThemeAbstracts.layout.maxPageWidth,
            display: "flex",
            alignItems: "flex-start",
        },
        midContainer: {
            width: "100%",
            paddingTop: 90,
            paddingBottom: 90,
            // paddingBottom: 30,
            // maxWidth: ThemeAbstracts.layout.maxPageWidth,
        },
        midCenterBlock : {
            width: "100%",
            maxWidth: 265,
            alignItems: "center",
            textAlign: "center",
            "@media only screen and (min-width: 900px)": {
                maxWidth: 830,
            },
            "@media screen and (max-width: 900px) and (min-width: 600px)": {
                maxWidth: 600,
            },

        },
        goalText: {
            marginBottom: 20,
            lineHeight: 1.4,
            fontSize: 18,
            letterSpacing: "-0.003em",
            fontWeight: 450,
            wordBreak: "break-word",
            textAlign: "left",
        },
        bigTitleNew: {
            marginBottom: 30,
            paddingBottom: 0,
            lineHeight: 1,
            fontSize: 24,
            textAlign: "left",
            "@media only screen and (min-width: 768px)": {
                fontSize: 32,
            },
        },
        blogTags: {
            marginTop: 16,
            paddingTop: 0,
            paddingBottom: 0,
            fontSize: 14,
            textTransform: "uppercase",
            fontWeight: "bold",
            letterSpacing: -1,
            lineHeight: 0.9,
            color: "#779ada",
            "@media only screen and (min-width: 768px)": {
                fontSize: 18,
            },
        },
        bigTitleTop: {
            fontSize: 32,
            "@media only screen and (min-width: 768px)": {
                fontSize: 52,
            },
        },
        blogHolder: {
            display: "flex",
            justifyContent: "center",
        },
        blogBox: {
            padding:"50px !important",
            width: "100%",
            display: "flex",
            alignItems: "center",
            maxWidth: 680,
        },
        blogBoxCenter: {
            display: "flex",
            alignItems : "center",
            padding:"50px !important ",
            width: "100%",
            maxWidth: 680,
            "@media only screen and (min-width: 768px)": {
                paddingTop:"70px !important",
                paddingBottom:"70px !important",
            },
        },
        blogBoxEnd: {
            padding:"30px !important",
            width: "100%",
            maxWidth: 680,
            display: "flex",
            justifyContent: "center",
        },
        callLink: {
            // fontSize: 28,
            color: "#161616",
            textDecoration: "none",
            "&:hover": {
                color: "#5b91ff",
            },
        },
        callLinkButton: {
            pointerEvents: "stroke"
        },
        blogBoxEndImage: {
            width: "100%",
            maxWidth: 240,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
        },
        midBoxContainer : {
            marginTop: 30,
            // marginBottom: 30,
        },

        endBoxContainer: {
            width: "100%",
            marginTop: 150,
            // paddingBottom: 90,
        },
        buttonEnd:{
            paddingTop :10,
            paddingBottom: 10,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: "30px",
            border: "1px solid #779ada",
            background: "linear-gradient(90deg,rgb(103, 195, 234,1) 0%,rgba(105, 141, 200, 1) 70%)",
            color: "white",
            fontSize: "18px",

            "&:hover": {
                boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                cursor: "pointer",
                transition: "all 0.5s",
            },
        }

    });

export default styles;
