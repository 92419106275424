import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const useStyles = makeStyles((theme: Theme) => ({
  textBH: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    opacity: 0.3,
    transform: "scale(0.7)",
    zIndex: 1,
    "&:hover": {
      opacity: 1,
      cursor: "crosshair",
      transform: "scale(1)",
      zIndex: 9999,
    },
  },
  textBHFirst: {
    marginTop: 0,
  },
  textBHMobile: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  textBHMid: {
    width: "100%",
    maxWidth: ThemeAbstracts.layout.maxPageWidth - 300,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 6,
    paddingBottom: 6,
  },
  textBHNumber: {
    fontFamily: "Plantin MT Pro, sans serif",
    fontSize: 84,
    marginRight: 40,
    paddingLeft: 10,
  },
  textBHSmallTitle: {
    fontSize: 24,
    color: "#161616",
    fontFamily: "Plantin MT Pro, sans serif",
    fontWeight: "bold",
    marginBottom: 0,
    [theme.breakpoints.down("lg")]: {
      fontSize: 42,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 26,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      lineHeight: 1.1,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      lineHeight: 1.1,
    },
  },
  textBHText: {
    marginTop: 4,
    paddingTop: 0,
    fontSize: 20,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: 1.1,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: 1.1,
    },
  },
}));

interface OwnProps {
  number: string;
  title: string;
  text: string;
  className?: string;
  first?: boolean;
}

type Props = OwnProps;

const HomeSimpleBlock = (props: Props) => {
  const classes = useStyles();
  const { number, title, text, className, first } = props;
  const mobileVersion = window.innerWidth < 1024;
  return (
    <div
      className={classNames(
        mobileVersion ? classes.textBHMobile : classes.textBH,
        className ? className : null,
        first ? classes.textBHFirst : null,
      )}
    >
      <div className={classes.textBHMid}>
        <div className={classes.textBHNumber}>{number}.</div>
        <div>
          <p className={classes.textBHSmallTitle}>{title}</p>
          <p className={classes.textBHText}>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default HomeSimpleBlock;
