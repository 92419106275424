import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  menuHolder: {
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  skillsMenu: {
    display: "table-row",
    listStyleType: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },

    "& li": {
      borderTop: "2px solid black",
      display: "table-cell",
      padding: 0,
      paddingRight: 6,
      verticalAlign: "top",
      [theme.breakpoints.down("lg")]: {
        paddingRight: 4,
      },
      [theme.breakpoints.down("md")]: {
        paddingRight: 2,
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },

    "& li a": {
      fontSize: 12,
      height: "100%",
      width: "100%",
      padding: 15,
      [theme.breakpoints.down("lg")]: {
        padding: 8,
      },
      [theme.breakpoints.down("md")]: {
        padding: 4,
        fontSize: 10,
      },
      display: "block",
      textTransform: "uppercase",
      color: "black",
      textAlign: "center",
      textDecoration: "none",
      fontFamily: "TTCommonsDemiBold, sans serif",
      backgroundSize: "100% 200%",
      backgroundImage:
        "linear-gradient(to bottom, rgba(0,0,0,0) 50%, black 50%)",
      transition: "background-position 0.5s",

      "&:hover": {
        color: "white",
        backgroundPosition: "0 -100%",
      },
    },
  },
}));

const SkillsMenu = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.menuHolder}>
      <ul className={classes.skillsMenu}>
        <li>
          <NavLink to={`/skills/strategy`}>Strategy &amp; Consultancy</NavLink>
        </li>
        <li>
          <NavLink to={`/skills/creative`}>Creative</NavLink>
        </li>
        <li>
          <NavLink to={`/skills/marketing`}>Marketing</NavLink>
        </li>
        <li>
          <NavLink to={`/skills/development`}>Development</NavLink>
        </li>
        <li>
          <NavLink to={`/skills/productie`}>Production</NavLink>
        </li>
        <li>
          <NavLink to={`/skills/rapportering`}>Reporting</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SkillsMenu;
