import { createStyles, Grid, Theme, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../../redux/reducers";
import Header from "../../../partials/Header";
import PageView from "../../../partials/PageView";
import { connect } from "react-redux";
import Footer from "../../../partials/Footer";
import BigText from "../../../text/BigText";
import BigTitle from "../../../text/BigTitle";
import StyledButton from "../../../button/StyledButton";
import { NavLink } from "react-router-dom";
import RadiusImg from "../../../image/RadiusImg";
import TopImg from "../../../../assets/img/google_banner.jpg";
import { ThemeAbstracts } from "../../../../config/MuiTheme";
import SmallTitle from "../../../text/SmallTitle";
import SmallText from "../../../text/SmallText";
import SmallBlogText from "../../../text/SmallBlogText";
import Helmet from "react-helmet";
import ContactForm from "../../contact/ContactForm";
import PieChart from "../../../../assets/img/googlesearch.png";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    blogTags: {
      marginTop: 16,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 18,
      textTransform: "uppercase",
      fontWeight: "bold",
      letterSpacing: -1,
      lineHeight: 0.9,
      color: "#779ada",
    },
    blogItemsHolder: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    blogHolder: {
      display: "flex",
      justifyContent: "center",
    },
    blogBox: {
      width: "100%",
      maxWidth: 680,
    },
  });

const data = {
  author: "Alessandro Bianchi",
  readTime: 3,
  title: "Google Ads breidt phrase match uit voor sterkere customer alignment.",
  subTitle:
    "Het is de missie van Google Ads om bedrijven en relevante klanten, dichter bij elkaar te brengen. Door de jaren heen hebben ze dan ook hun algoritme en producten verbeterd om beter te kunnen beantwoorden aan de zoekintentie van deze klanten. ",
  blogTitleImg: TopImg,
  blocks: [
    {
      items: [
        {
          type: "smalltext",
          content: (
            <>
              Het is de missie van Google Ads om bedrijven en relevante klanten,
              dichter bij elkaar te brengen. Door de jaren heen hebben ze dan
              ook hun algoritme en producten verbeterd om beter te kunnen
              beantwoorden aan de zoekintentie van deze klanten.
              <br />
              <br />
              Ook nu is er een update in zicht die ervoor gaat zorgen dat we
              relevanter kunnen adverteren via Google Ads zoekadvertenties. Is
              die verandering wel optimaal? Wel, dat leggen wij hieronder graag
              even uit.
            </>
          ),
        },
        {
          type: "smalltitle",
          content: "Het vereenvoudigen van match types",
        },
        {
          type: "smalltext",
          content: (
            <>
              Vandaag de dag is het instellen van je zoekwoorden redelijk
              straight-forward. Je hebt drie verschillende match types:
              <br />
              <br />
              <ul>
                <li>Exact match voor precisie</li>
                <li>Broad match voor bereik</li>
                <li>
                  Phrase match en broad match modifier voor een balans tussen
                  precisie en bereik
                </li>
              </ul>
              <br />
              <br />
              Om adverteerders meer controle en bereik te geven, laten ze het
              beste van de twee werelden samenvloeien. Hierdoor zal het bereik
              van de broad match modifier deels toegevoegd worden aan de phrase
              match zoekwoorden. Belangrijk om te weten is dat het algoritme nog
              steeds de woordvolgorde blijft respecteren wanneer het belangrijk
              is voor de betekenis.
              <br />
              <br />
              Maar hoe werkt dat nu exact? Hieronder vind je een voorbeeld in
              het geval je wil adverteren voor een verhuisbedrijf die enkel en
              alleen vanuit Antwerpen naar Gent verhuisd:
            </>
          ),
        },
        {
          type: "img",
          content: PieChart,
        },
        {
          type: "smalltext",
          content: (
            <>
              <ul>
                <li>Keyword= Verhuisdienst Antwerpen naar Gent</li>
                <li>
                  Search queries
                  <ul>
                    <li>Betaalbare verhuisdienst van antwerpen naar Gent</li>
                    <li>Verhuisdienst bedrijf antwerpen naar Gent</li>
                    <li>Verhuisdienst Gent naar Antwerpen</li>
                  </ul>
                </li>
              </ul>
              <br />
              <br />
              Andere voorbeelden van zoektermen die wel of niet meer zullen
              verschijnen na de aanpassing van deze match types zijn:
              <br />
              <br />
              <table>
                <tbody>
                  <tr>
                    <td width="50%">Broad match modifier zoekwoord</td>
                    <td width="50%">Zoekopdrachten die niet meer matchen</td>
                  </tr>
                  <tr>
                    <td width="50%">+beste +sneakers</td>
                    <td width="50%">Best prijs voor kinder sneakers</td>
                  </tr>
                  <tr>
                    <td width="50%">+gebruikte +printers</td>
                    <td width="50%">
                      Bedrijven die industriële printers gebruiken
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <table>
                <tbody>
                  <tr>
                    <td width="50%">Phrase match zoekwoord</td>
                    <td width="50%">Zoekopdrachten die wel zullen matchen</td>
                  </tr>
                  <tr>
                    <td width="50%">“Vakantie in Zambia”</td>
                    <td width="50%">Vakantieplekjes in Zambia</td>
                  </tr>
                  <tr>
                    <td width="50%">“Lang jurkje”</td>
                    <td width="50%">Lang jurkje in kant</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              Op zich komen er geen gigantische aanpassingen. De phrase matches
              worden dus getriggerd voor ruimere zoekopdrachten, maar ze blijven
              nog steeds relevant aan het ingestelde zoekwoord. <br />
              <br />
              De kans dat je rare en irrelevante zoektermen terugvindt in je
              account, wordt een stuk kleiner. Indien je al gebruik maakt van
              een strategie waarbij je de drie verschillende match types door
              elkaar gebruikt om zo je cpc optimaal te houden, ga je ook geen al
              te grote verschillen merken qua performance. Het is en blijft
              natuurlijk wel nuttig om je campagnes op regelmatige basis te
              analyseren en optimaliseren.
            </>
          ),
        },
        {
          type: "smalltitle",
          content: "Wat kan ik doen om de impact hiervan in te perken?",
        },
        {
          type: "smalltext",
          content: (
            <>
              De belangrijkste stap die je hier kan ondernemen is het rapport
              van de getriggerde zoektermen blijven analyseren. Hier kan je dan
              verschillende irrelevante zoektermen uitsluiten zodat je
              zoekwoorden enkel en alleen maar verschijnen voor relevante
              zoekopdrachten.
            </>
          ),
        },
      ],
    },
  ],
  contactText:
    "Klanten winnen met Google Ads? Neem contact op en we plannen een gesprek in.",
};

class SkillLabIOS14 extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | {data.title}</title>
        </Helmet>
        <Header />
        <Grid container direction="column" className={classes.topContainer}>
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 680,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <BigTitle
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        lineHeight: 1,
                        maxWidth: 600,
                        textAlign: "left",
                      }}
                      bigger={true}
                    >
                      {data.title}
                    </BigTitle>
                    <SmallText
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      {data.subTitle}
                    </SmallText>
                    <div>
                      <div className="sharethis-inline-share-buttons"></div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <div
              style={{ width: "100%" }}
              className={"animate__animated animate__flipInX"}
            >
              <RadiusImg
                src={TopImg}
                maxWidth={ThemeAbstracts.layout.maxPageWidth}
                maxHeight={460}
                noEffect={true}
              />
            </div>
            <Grid container style={{ height: 100 }} />
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <p>
                  Door {data.author} - {data.readTime} minuten lezen
                </p>
                {data.blocks.map((block, b) => {
                  return (
                    <div key={b} className={classes.blogItemsHolder}>
                      {block.items.map((item, i) => {
                        if (item.type === "smalltitle") {
                          return (
                            <SmallTitle
                              key={i}
                              style={{
                                marginTop: 20,
                                lineHeight: 1.3,
                                textAlign: "left",
                                marginBottom: 20,
                              }}
                            >
                              {item.content}
                            </SmallTitle>
                          );
                        }
                        if (item.type === "smalltext") {
                          return (
                            <SmallBlogText
                              key={i}
                              style={{
                                maxWidth: 600,
                                marginBottom: 16,
                                lineHeight: 1.4,
                                fontSize: 21,
                                letterSpacing: "-0.003em",
                                fontWeight: 400,
                                wordBreak: "break-word",
                                color: "rgba(41, 41, 41, 1)",
                              }}
                            >
                              {item.content}
                            </SmallBlogText>
                          );
                        }
                        if (item.type === "img") {
                          return (
                            <div
                              key={i}
                              style={{
                                width: "100%",
                                maxWidth: 680,
                                marginTop: 20,
                                marginBottom: 20,
                              }}
                            >
                              <RadiusImg
                                src={item.content}
                                maxWidth={680}
                                noEffect={true}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <NavLink
              to="/skilllab"
              style={{ marginTop: 60, textDecoration: "none" }}
            >
              <StyledButton
                className={"animate__animated animate__bounceInUp"}
                style={{ marginTop: 60 }}
              >
                Meer in Skill Lab
              </StyledButton>
            </NavLink>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <BigText style={{ marginLeft: 10, marginRight: 10 }}>
                  {data.contactText}
                </BigText>
                <ContactForm
                  sendText="Verstuur"
                  title="Contacteer ons"
                  subTitle={data.contactText}
                  minimized={true}
                />
              </Grid>
            </Grid>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(SkillLabIOS14));
