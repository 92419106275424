import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Home.styles";
import { connect } from "react-redux";
import StyledButton from "../../button/StyledButton";
import { NavLink } from "react-router-dom";
import Home1 from "../../../assets/img/homedoel1.jpg";
import Home2 from "../../../assets/img/homedoel2.jpg";
import RadiusImg from "../../image/RadiusImg";
import classNames from "classnames";
import BigTitle from "../../text/BigTitle";
import HomeSimpleBlock from "./HomeSimpleBlock";
import Blog1 from "../../../assets/img/case_sipwell_1.jpg";
import Blog12 from "../../../assets/img/case_sipwell_2.jpg";
import Blog2 from "../../../assets/img/case_ginsonline_1.jpg";
import Blog22 from "../../../assets/img/case_ginsonline_2.jpg";
import Blog3 from "../../../assets/img/case_daf_1.jpg";
import Blog32 from "../../../assets/img/case_daf_2.jpg";
import Blog4 from "../../../assets/img/case_zuiderburen_1.jpg";
import Blog42 from "../../../assets/img/case_zuiderburen_2.jpg";
import BlogItem from "./BlogItem";
import BigCaroussel from "../../caroussel/BigCaroussel";
import { ThemeAbstracts } from "../../../config/MuiTheme";
import Sipwell from "../../../assets/img/sipwelllogo.png";
import SipwellBlack from "../../../assets/img/sipwelllogo_black.png";
import Aceg from "../../../assets/img/aceglogo.png";
import AcegBlack from "../../../assets/img/aceglogo_black.png";
import Easypost from "../../../assets/img/easypostlogo.png";
import EasypostBlack from "../../../assets/img/easypostlogo_black.png";
import Magicfloors from "../../../assets/img/magicfloorslogo.png";
import Ginsonline from "../../../assets/img/ginsonlinelogo.png";
import Casymo from "../../../assets/img/casymologo.png";
import CasymoBlack from "../../../assets/img/casymologo_black.png";
import Tensen from "../../../assets/img/tensenlogo.png";
import SubTitle from "../../text/SubTitle";
import Helmet from "react-helmet";
import Translate from "../../../Translate/Translate";
import Blog5 from "../../../assets/img/case_easypost_1.jpg";
import Blog52 from "../../../assets/img/case_easypost_2.jpg";
import VerImg from "../../../assets/img/verwarmmetpellet_work.png";
import VerImg2 from "../../../assets/img/cases-verwarmmetpellets.png";

interface State {
  scrollPosY: number;
  imagesPosY: number;
  textsPosY: number;
  blogPosY: number;
}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scrollPosY: window.scrollY,
      imagesPosY: 0,
      textsPosY: 0,
      blogPosY: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      if (window.scrollY !== this.state.scrollPosY) {
        this.setState({
          scrollPosY: window.scrollY,
        });
      }
    });

    const fbdiv = document.createElement("div");
    fbdiv.setAttribute("id", "fb-root");
    document.body.appendChild(fbdiv);

    const fbChatDiv = document.createElement("div");
    fbChatDiv.setAttribute("class", "fb-customerchat");
    fbChatDiv.setAttribute("attribution", "install_email");
    fbChatDiv.setAttribute("page_id", "591348974339672");
    fbChatDiv.setAttribute("theme_color", "#69B9E4");
    fbChatDiv.setAttribute(
      "logged_in_greeting",
      "Hallo! Hoe kunnen we jou verder helpen?",
    );
    fbChatDiv.setAttribute(
      "logged_out_greeting",
      "Hallo! Hoe kunnen we jou verder helpen?",
    );
    document.body.appendChild(fbChatDiv);

    const script = document.createElement("script");
    script.innerHTML =
      "window.fbAsyncInit = function(){FB.init({xfbml:true,version:'v10.0'});};(function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s); js.id = id;js.src = 'https://connect.facebook.net/nl_NL/sdk/xfbml.customerchat.js';fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));";
    document.body.appendChild(script);
  }

  imagesRefCB = (el: any) => {
    if (el) {
      this.setState({
        imagesPosY: el ? el.getBoundingClientRect().y + 200 : 0,
      });
    }
  };

  textsRefCB = (el: any) => {
    if (el) {
      this.setState({
        textsPosY: el ? el.getBoundingClientRect().y + 200 : 0,
      });
    }
  };

  blogRefCB = (el: any) => {
    if (el) {
      this.setState({
        blogPosY: el ? el.getBoundingClientRect().y + 200 : 0,
      });
    }
  };

  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const { scrollPosY, imagesPosY, textsPosY, blogPosY } = this.state;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    const visibleField = scrollPosY + theWindowHeight;
    const imagesVisible = mobile
      ? true
      : scrollPosY
      ? imagesPosY
        ? imagesPosY < visibleField
        : false
      : false;
    const textsVisible = mobile
      ? true
      : scrollPosY
      ? textsPosY
        ? textsPosY < visibleField
        : false
      : false;
    const blogVisible = mobile
      ? true
      : scrollPosY
      ? blogPosY
        ? blogPosY < visibleField
        : false
      : false;
    const theWindowWidth = windowWidth || 1000;
    const imageCountBottomCaroussel = theWindowWidth > 700 ? 6 : 2;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia - Digital marketing agency</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <div className="typeWriter">
            <h1 className={classes.title}>
              Your Story. <span className={classes.subTitle}>Our Skills.</span>
            </h1>
          </div>
          <NavLink to="/contact" style={{ textDecoration: "none" }}>
            <StyledButton>{Translate.translate("letsconnect")}</StyledButton>
          </NavLink>
        </Grid>
        <Grid container className={classes.midRoot}>
          <Grid
            container
            direction="row"
            className={classes.midContainer}
            style={{
              minHeight: theWindowHeight,
              marginBottom: 60,
              padding: 30,
              justifyContent: "flex-start",
            }}
          >
            <Grid item md={4} xs={12}>
              <h2 className={classes.goalTitle}>
                {Translate.translate("homeGoals")}
              </h2>
              <p className={classes.goalText}>
                {Translate.translate("homeGoalsSubtitle")}
              </p>
              <ul className={classes.goalList}>
                <li>
                  <NavLink to="/skills/strategy">
                    {Translate.translate("homeGoalsStrategy")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/skills/creative">
                    {Translate.translate("homeGoalsCreative")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/skills/marketing">
                    {Translate.translate("homeGoalsMarketing")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/skills/development">
                    {Translate.translate("homeGoalsDevelopment")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/skills/productie">
                    {Translate.translate("homeGoalsProduction")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/skills/rapportering">
                    {Translate.translate("homeGoalsReporting")}
                  </NavLink>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
              ref={this.imagesRefCB}
              style={{ overflow: "hidden", minHeight: 500, marginTop: -50 }}
            >
              {imagesVisible && (
                <div className={classes.imgHolder}>
                  <div
                    className={classNames(
                      classes.imgRight,
                      "animate__animated animate__fadeInRight",
                    )}
                  >
                    <RadiusImg src={Home2} />
                  </div>
                  <div
                    className={classNames(
                      classes.imgLeft,
                      "animate__animated animate__rotateInDownRight",
                    )}
                  >
                    <RadiusImg src={Home1} right={true} />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.midRoot}
          style={{
            marginBottom: 160,
            minHeight: theWindowHeight - 60,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            backgroundColor: "white",
            paddingTop: 140,
            paddingBottom: 60,
          }}
        >
          <div
            style={{
              width: "100%",
            }}
            ref={this.textsRefCB}
          >
            <BigTitle style={{ width: "100%", marginBottom: 50 }} variant="h2">
              {Translate.translate("homeHowWeWork")}
            </BigTitle>
            {textsVisible && (
              <div
                style={{
                  width: "100%",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HomeSimpleBlock
                  first={true}
                  className="animate__animated animate__backInRight"
                  number="1"
                  title={Translate.translate("homeHowTitle1")}
                  text={Translate.translate("homeHowText1")}
                />
                <HomeSimpleBlock
                  className="animate__animated animate__backInLeft"
                  number="2"
                  title={Translate.translate("homeHowTitle2")}
                  text={Translate.translate("homeHowText2")}
                />
                <HomeSimpleBlock
                  className="animate__animated animate__backInRight"
                  number="3"
                  title={Translate.translate("homeHowTitle3")}
                  text={Translate.translate("homeHowText3")}
                />
                <HomeSimpleBlock
                  className="animate__animated animate__backInLeft"
                  number="4"
                  title={Translate.translate("homeHowTitle4")}
                  text={Translate.translate("homeHowText4")}
                />
                <NavLink to="/contact" style={{ textDecoration: "none" }}>
                  <StyledButton
                    className={
                      "animate__animated animate__bounceInUp animate__delay-1s"
                    }
                    style={{ marginTop: 60 }}
                  >
                    {Translate.translate("homeLetsGrow")}
                  </StyledButton>
                </NavLink>
              </div>
            )}
          </div>
        </Grid>
        <Grid
          container
          className={classNames(classes.midRoot, classes.blogHolder)}
          style={{
            minHeight: theWindowHeight,
            marginBottom: 60,
          }}
          ref={this.blogRefCB}
        >
          {blogVisible && (
            <Grid
              container
              direction="row"
              className={classes.midContainer}
              style={{ alignItems: "flex-start" }}
            >
              <BigTitle
                style={{ width: "100%", marginBottom: 80 }}
                variant="h2"
              >
                {Translate.translate("howWeHelp")}
              </BigTitle>
              <Grid container direction="row" style={{ overflow: "hidden" }}>
                <Grid
                item
                xs={12}
                md={6}
                className={classNames(
                  classes.blogBoxLeft,
                  "animate__animated animate__fadeInRight animate__delay-1s",
              )}
                >
                <BlogItem
                    title="Verwarm met pellets"
                    text={Translate.translate("homeCaseTextVerwarm")}
                    tags="Strategy - digital marketing - development - graphic design - analytics"
                    img={VerImg}
                    img2={VerImg2}
                    link="/case/verwarmmetpellets"
                    right={true}
                />
              </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classNames(
                    classes.blogBoxRight,
                    "animate__animated animate__fadeInLeft animate__delay-1s",
                  )}
                >
                  <BlogItem
                    title="EasyPost Calculator"
                    text={Translate.translate("homeCaseText5")}
                    tags="Strategy - digital marketing - development - analytics"
                    img={Blog5}
                    right={false}
                    img2={Blog52}
                    link="/case/easypost"
                  />
                </Grid>
                <Grid
                    container
                    direction="row"
                    className={classes.boxHolderUnder}
                    style={{ overflow: "hidden" }}
                >
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classNames(
                    classes.blogBoxLeft,
                    "animate__animated animate__fadeInRight animate__delay-1s",
                  )}
                >
                  <BlogItem
                    title="SipWell"
                    text={Translate.translate("homeCaseText1")}
                    tags="Strategy - branding - digital marketing - graphic design - copywriting - development - analytics"
                    img={Blog1}
                    right={true}
                    img2={Blog12}
                    link="/case/sipwell"
                  />
                </Grid>
                  <Grid
                      item
                      xs={12}
                      md={6}
                      className={classNames(
                          classes.blogBoxRight,
                          "animate__animated animate__fadeInLeft animate__delay-1s",
                      )}
                  >
                    <BlogItem
                        title="D'Angello &amp; Francis"
                        text={Translate.translate("homeCaseText3")}
                        tags="branding - graphic design - merchandising - photo and video"
                        img={Blog3}
                        img2={Blog32}
                        right={false}
                        link="/case/dangelloandfrancis"
                    />
                  </Grid>
              </Grid>


              </Grid>
            </Grid>
          )}
          <Grid container style={{ justifyContent: "center" }}>
            <NavLink
              to="/work"
              style={{ marginTop: 60, textDecoration: "none" }}
            >
              <StyledButton
                className={
                  "animate__animated animate__bounceInUp animate__delay-1s"
                }
                style={{ marginTop: 60 }}
              >
                MEER CASES
              </StyledButton>
            </NavLink>
          </Grid>
          <Grid container style={{ height: 120 }} />
          <BigCaroussel
            width={
              theWindowWidth > ThemeAbstracts.layout.maxPageWidth + 100
                ? ThemeAbstracts.layout.maxPageWidth + 100
                : theWindowWidth
            }
            height={80}
            imageCountVisible={imageCountBottomCaroussel}
            links={[
              "https://sipwell.be/",
              "https://www.aceg.be/",
              "https://www.easypost.eu/nl",
              "http://www.magicfloors.com/",
              "https://www.ginsonline.be/",
              "https://www.facebook.com/Casymo/",
              "https://www.tensen.be/nl",
              "https://sipwell.be/",
              "https://www.aceg.be/",
              "https://www.easypost.eu/nl",
              "http://www.magicfloors.com/",
              "https://www.ginsonline.be/",
              "https://www.facebook.com/Casymo/",
              "https://www.tensen.be/nl",
            ]}
            images={[
              { normal: Sipwell, black: SipwellBlack },
              { normal: Aceg, black: AcegBlack },
              { normal: Easypost, black: EasypostBlack },
              { normal: Magicfloors, black: Magicfloors },
              { normal: Ginsonline, black: Ginsonline },
              { normal: Casymo, black: CasymoBlack },
              { normal: Tensen, black: Tensen },
              { normal: Sipwell, black: SipwellBlack },
              { normal: Aceg, black: AcegBlack },
              { normal: Easypost, black: EasypostBlack },
              { normal: Magicfloors, black: Magicfloors },
              { normal: Ginsonline, black: Ginsonline },
              { normal: Casymo, black: CasymoBlack },
              { normal: Tensen, black: Tensen },
            ]}
          />
          <Grid container style={{ height: 200 }} />
          <BigTitle
            style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
            variant="h2"
          >
            {Translate.translate("contactUs")}
          </BigTitle>
          <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
            {Translate.translate("contactGrow")}
          </SubTitle>
          <NavLink to="/contact" style={{ textDecoration: "none" }}>
            <StyledButton inverse={true}>
              {Translate.translate("contactLetsWork")}
            </StyledButton>
          </NavLink>
          <Grid container style={{ height: 120 }} />
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Home));
