import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../../redux/reducers";
import Header from "../../../partials/Header";
import PageView from "../../../partials/PageView";
import styles from "./Daf.styles";
import { connect } from "react-redux";
import Footer from "../../../partials/Footer";
import BigText from "../../../text/BigText";
import BigTitle from "../../../text/BigTitle";
import SubTitle from "../../../text/SubTitle";
import StyledButton from "../../../button/StyledButton";
import { NavLink } from "react-router-dom";
import RadiusImg from "../../../image/RadiusImg";
import TopImg from "../../../../assets/img/case_daf_banner.jpg";
import Content1 from "../../../../assets/img/case_daf_content_1.png";
import Content2 from "../../../../assets/img/case_daf_content_2.png";
import Content3 from "../../../../assets/img/case_daf_content_3.png";
import Content4 from "../../../../assets/img/case_daf_content_4.png";
import Content5 from "../../../../assets/img/case_daf_content_5.png";
import Content6 from "../../../../assets/img/case_daf_content_6.png";
import Content7 from "../../../../assets/img/case_daf_website.png";
import { ThemeAbstracts } from "../../../../config/MuiTheme";
import SmallTitle from "../../../text/SmallTitle";
import SmallText from "../../../text/SmallText";
import Helmet from "react-helmet";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Daf extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>
            Skillmedia | Case D'Angello and Francis - supporting artists
          </title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 680,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <BigTitle
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        lineHeight: 1,
                        maxWidth: 600,
                        textAlign: "left",
                      }}
                      bigger={true}
                    >
                      D'Angello &amp; Francis
                    </BigTitle>
                    <SmallText
                      style={{
                        maxWidth: 500,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Creatively supporting internationally renowned artist on
                      their way to the top
                    </SmallText>
                    <p className={classes.blogTags}>
                      brand development - artwork - merchandising - photo and
                      videophotography
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <div
              style={{ width: "100%" }}
              className={"animate__animated animate__flipInX"}
            >
              <RadiusImg
                src={TopImg}
                maxWidth={ThemeAbstracts.layout.maxPageWidth}
                maxHeight={460}
                noEffect={true}
              />
            </div>
            <Grid container style={{ height: 100 }} />
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  Van mega clubs tot populaire festivals
                </SmallTitle>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  Belgische dj-producers D’Angello & Francis maken
                  internationaal furore. Sinds enkele jaren touren ze over heel
                  de wereld, van mega-clubs in China tot de populairste
                  festivals zoals Tomorrowland. Dit komt uiteraard door hun
                  muzikale successen, waardoor ze een internationale fanbase
                  aanhouden en samenwerkingsvoorstellen krijgen door de beste
                  artiesten in de wereld. Zo brachten ze reeds hitsingles uit
                  met dj collega’s Dimitri Vegas & Like Mike, Armin Van Buuren,
                  de Nederlandse topact Bassjackers en ex-voice van popster
                  David Guetta’s hits: Chris Willis. Ze hebben dan ook gemiddeld
                  1 miljoen maandelijkse luisteraars op het streamingplatforms,
                  hiermee behoren ze tot de top meest gestreamde artiesten van
                  ons land.
                </SmallText>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  In de weg naar internationaal succes staat Skillmedia hen bij
                  in het ontwikkelen van hun brand, gaande van logodesign tot
                  cover artwork en het opzetten van marketingcampagnes.
                  Daarnaast verzorgt Skillmedia ook showvisuals, merchandising,
                  foto- en videoreportages,, landingspages en websites aangaande
                  de artiesten.
                </SmallText>
                <Grid container direction="row" style={{ marginTop: 40 }}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content1} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content2} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content3} width="100%" alt="content1" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#e9e9e9",
              fontSize: 20,
              marginTop: 80,
              paddingTop: 60,
              paddingBottom: 60,
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
            }}
          >
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <Grid container direction="row">
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content4} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content5} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content6} width="100%" alt="content1" />
                  </Grid>
                </Grid>
                <img
                  src={Content7}
                  width="100%"
                  alt="content1"
                  style={{ marginTop: 40, marginBottom: 30 }}
                />
                <div
                  style={{ lineHeight: 1.4, marginBottom: 40, marginTop: 40 }}
                >
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Zelf ook zin om D’Angello &amp; Francis aan het werk te
                    zien? surf naar{" "}
                    <a
                      href="https://www.dangelloandfrancis.be"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.dangelloandfrancis.be
                    </a>{" "}
                    en ontdek alles over hun volgende shows.
                  </p>
                </div>
                <iframe
                  title="dangeloandfrancis"
                  className={classes.videoH}
                  width="100%"
                  height="600"
                  src="https://www.youtube.com/embed/frOM0EXlo20"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <NavLink
              to="/work"
              style={{ marginTop: 60, textDecoration: "none" }}
            >
              <StyledButton
                className={"animate__animated animate__bounceInUp"}
                style={{ marginTop: 60 }}
              >
                MEER CASES
              </StyledButton>
            </NavLink>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Contacteer ons
            </BigTitle>
            <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
              Ontdek hoe Skillmedia je helpt groeien
            </SubTitle>
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <StyledButton inverse={true}>LATEN WE SAMENWERKEN</StyledButton>
            </NavLink>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Daf));
