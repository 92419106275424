import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  bigTitle: {
    fontSize: 30,
    [theme.breakpoints.down("lg")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 26,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      lineHeight: 1.1,
    },
    textAlign: "center",
  },
}));

const SubTitle = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.bigTitle}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default SubTitle;
