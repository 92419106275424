import { Grid, IconButton, withStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import styles from "./NavigationMenu.styles";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../redux/reducers";
import classNames from "classnames";
import logo from "../../assets/img/Skillmedia-white.svg";
import StyledButton from "../button/StyledButton";
import SubTitle from "../text/SubTitle";
import Translate from "../../Translate/Translate";

interface State {
  mobileMenuOpen: boolean;
}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number;
  windowHeight: number;
}

type Props = OwnProps & StateProps;

class NavigationMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
  }

  closeMenu = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };

  openMenu = () => {
    this.setState({
      mobileMenuOpen: true,
    });
  };

  render() {
    const { classes, windowWidth, windowHeight } = this.props;
    const { mobileMenuOpen } = this.state;
    return (
      <div className={classes.fullMenuHolder}>
        <div
          className={classNames(
            classes.fullMenu,
            "animate__animated animate__fadeIn",
          )}
        >
          <nav>
            <ul className={classes.rootMenu}>
              <li>
                <NavLink to={`/skills`}>
                  {Translate.translate("menuSkills")}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/work`}>
                  {Translate.translate("menuWork")}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/skilllab`}>Skill Lab</NavLink>
              </li>
              <li>
                <NavLink to={`/team`}>
                  {Translate.translate("menuTeam")}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/jobs`}>
                  {Translate.translate("menuJobs")}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/contact`} className={classes.lastLink}>
                  {Translate.translate("menuContact")}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <IconButton
          className={classes.mobileMenu}
          color="inherit"
          aria-label="Menu"
          onClick={(e) => this.openMenu()}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        {mobileMenuOpen && (
          <div
            className={classNames(
              classes.mobileNavigation,
              "animate__animated animate__bounceInDown",
            )}
            style={{ width: windowWidth, height: windowHeight }}
          >
            <Grid container className={classes.rootHolder}>
              <Grid
                container
                direction="row"
                style={{ justifyContent: "space-between" }}
              >
                <div className={classes.logoHolder}>
                  <NavLink
                    to={`/`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={logo}
                      className={classNames(
                        classes.actualLogo,
                        "App-logo animate__animated animate__fadeIn",
                      )}
                      alt="logo"
                    />
                  </NavLink>
                </div>
                <IconButton onClick={() => this.closeMenu()}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12} md={6}>
                  <nav className={classes.rootMobileMenu}>
                    <li>
                      <NavLink to="/skills" onClick={() => this.closeMenu()}>
                        {Translate.translate("menuSkills")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`/work`} onClick={() => this.closeMenu()}>
                        {Translate.translate("menuWork")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/skilllab`}
                        onClick={() => this.closeMenu()}
                      >
                        Skill Lab
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`/team`} onClick={() => this.closeMenu()}>
                        {Translate.translate("menuTeam")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`/jobs`} onClick={() => this.closeMenu()}>
                        {Translate.translate("menuJobs")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`/contact`} onClick={() => this.closeMenu()}>
                        {Translate.translate("menuContact")}
                      </NavLink>
                    </li>
                  </nav>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.rootMobileData}>
                    <SubTitle style={{ textAlign: "left" }}>
                      <strong>Skillmedia</strong>
                    </SubTitle>
                    <SubTitle style={{ textAlign: "left" }}>
                      Rijnkaai 37, 2000 Antwerpen
                    </SubTitle>
                    <div>
                      <SubTitle style={{ textAlign: "left" }}>
                        <a
                          href="tel:003249347331"
                          className={classes.smallLink}
                        >
                          +32 (0)493 47 33 31
                        </a>
                      </SubTitle>
                    </div>
                    <div>
                      <SubTitle style={{ textAlign: "left" }}>
                        <a
                          href="mailto:info@skillmedia.be"
                          className={classes.smallLink}
                        >
                          info@skillmedia.be
                        </a>
                      </SubTitle>
                    </div>
                    <NavLink to={`/contact`} onClick={() => this.closeMenu()}>
                      <StyledButton white={true}>
                        {Translate.translate("letsconnect")}
                      </StyledButton>
                    </NavLink>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(NavigationMenu));
