import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./ProjectManager.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import BigTitle from "../../text/BigTitle";
import MarketeerPic from "../../../assets/img/sk_marketeer.jpg";
import StrategyBlock from "../strategy/StrategyBlock";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import Helmet from "react-helmet";
import JobTitle from "../../text/JobTitle";
import SimpleText from "../../text/SimpleText";
interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class DigitalMarketeer extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | Jobs - Project Manager</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <BigTitle
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                      lineHeight: 1,
                      maxWidth: 300,
                      textAlign: "left",
                    }}
                  >
                    Gezocht:
                  </BigTitle>
                  <JobTitle>PROJECT MANAGER</JobTitle>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <StrategyBlock
              right={true}
              src={MarketeerPic}
              title="We want you!"
              text="Wil jij mee instaan voor de verdere ontplooiing van dit one-of-a-kind bedrijf? Dan ben jij misschien wel de dynamische, flexibele en enthousiaste Projectmanager naar wie wij op zoek zijn."
            />
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <Grid container className={classes.topBox}>
              <Grid container className={classes.specsHolder}>
                <Grid item xs={12} md={6}>
                  <JobTitle>PROJECT MANAGER</JobTitle>
                  <SimpleText style={{ textAlign: "left", marginTop: 10 }}>
                    Ben je gepassioneerd door marketing en alles online? Zegt de
                    term ‘One Team’ jou iets? Ben je op zoek naar een
                    gevarieerde job waarbij de boeiende combinatie van
                    organisatie, planning en netwerken plus het contact met onze
                    klanten elke dag weer uniek maken? Wil je deel uitmaken van
                    een jong, positief ingesteld en dynamisch team in een
                    snelgroeiende omgeving? Als Project Manager neem je alvast
                    een heel breed en gevarieerd takenpakket op en krijg je de
                    mogelijkheid om je commerciële, creatieve, organisatorische
                    en communicatieve vaardigheden volledig tot uiting te laten
                    komen.
                  </SimpleText>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 100 }}
                className={classes.specsHolder}
              >
                <Grid item xs={12} md={6}>
                  <SimpleText style={{ textAlign: "left", marginRight: 40 }}>
                    <strong>Profiel</strong>
                    <ul>
                      <li>
                        Je bent organisatorisch, commercieel en sterk
                        projectmatig, je kan zowel zelfstandig werken en
                        initiatief nemen als in een team functioneren om zaken
                        op te leveren.
                      </li>
                      <li>
                        Je bent proactief en flexibel, je hebt geen 9-to-5
                        mentaliteit, je bent positief ingesteld en punctueel, je
                        werkt efficiënt en je kan prioriteiten stellen.
                      </li>
                      <li>
                        Je hebt reeds een eerste ervaring binnen project
                        management. Een bachelor diploma hoger onderwijs is
                        alvast een plus.
                      </li>
                      <li>
                        Je bent creatief en gepassioneerd door alles wat met
                        (online) marketing te maken heeft.
                      </li>
                      <li>
                        Je ziet commerciële opportuniteiten en je bent niet bang
                        om de handen uit te mouwen te steken. Je staat stevig in
                        je schoenen en je zorgt voor het aansturen,
                        ondersteunen, motiveren en coachen van je medewerkers.
                        Je beschikt over een sterk menselijk en zakelijk inzicht
                        en operationele beschikbaarheid.
                      </li>
                      <li>
                        Je hebt de nodige drive, verantwoordelijkheid en een
                        berg goede ideeën en je gaat steeds voor de top. Een
                        stevige brainstormsessie is er nooit te veel aan.
                      </li>
                      <li>
                        Je volgt projecten op van A tot Z en bent een krak in
                        het opmaken en ontvangen van briefings.
                      </li>
                      <li>
                        Je kan omgaan met een gezonde dosis stress en deadlines,
                        je bent een geboren planner.
                      </li>
                      <li>
                        Je hebt oog voor budgetten, je bent nauwkeurig en je
                        bent steeds ‘on top’ van je administratie.
                      </li>
                      <li>
                        Je bent communicatief sterk, haalt veel energie uit
                        menselijk contact en je hebt commerciële flair.
                        Contacten met onze klanten, leveranciers en medewerkers
                        geven je energie. Zowel telefonisch als digitaal en in
                        real life. Je bent de hands-on schakel tussen je
                        collega’s en onze klanten.{" "}
                      </li>
                      <li>
                        Je hebt time management en het beheren van agenda’s als
                        tweede natuur. Je kan orde scheppen in de chaos.
                      </li>
                      <li>
                        Je bent een multitasker in hart en nieren en je kan vlot
                        overweg met verschillende mensen en situaties.{" "}
                      </li>
                      <li>
                        Je hebt een perfecte kennis van het Nederlands en een
                        voldoende kennis van Frans & Engels.{" "}
                      </li>
                      <li>
                        Je kan vlot overweg met alle Office-toepassingen & en je
                        bent bereid om je te verdiepen in ons CRM-systeem en een
                        aantal andere online toepassingen.
                      </li>
                      <li>
                        Je bent creatief en vertrouwd met diverse Social Media
                        zoals Facebook, Instagram, LinkedIn, ...
                      </li>
                    </ul>
                    <br />
                    <br />
                    <strong>Aanbod</strong>
                    <ul>
                      <li>
                        Skillmedia biedt jou een uitdagende en gevarieerde
                        functie aan, waarbij je veel verantwoordelijkheid draagt
                        binnen een jonge, positieve en dynamische omgeving.
                      </li>
                      <li>
                        In de online marketing sector - die elk jaar groter
                        wordt - voorzien wij als dynamisch bedrijf een uniek
                        project voor zowel onze klanten als onze medewerkers.
                      </li>
                      <li>
                        Een mogelijkheid om je talenten maximaal te ontplooien
                        en je professionele netwerk te vergroten.
                      </li>
                      <li>
                        Je werkt samen met gedreven en sympathieke collega’s in
                        een informele en open werksfeer waarin groei,
                        vooruitgang en innovatie centraal staan en op een
                        professionele manier resultaten neergezet worden.
                      </li>
                      <li>
                        Een salarispakket met extralegale voordelen, in
                        verhouding met jouw kennis en ervaring.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <strong>
                      De uitdagingen die we voor jou bij Skillmedia hebben
                      klaarstaan:
                    </strong>
                    <br />
                    Als Projectmanager pak je een aantal van de
                    (multidisciplinaire) projecten zelfstandig op en stuur je
                    deze op voortgang, kwaliteit en het eindresultaat. Je
                    coördineert de uitvoering, waarbij je de efficiëntie en
                    kwaliteit bewaakt. Dit doe je zodanig dat het begrote
                    financiële resultaat wordt gerealiseerd. Specifiek
                    aandachtsgebied hierbij is het opbouwen en behouden van een
                    goede relatie met de opdrachtgever teneinde eventuele
                    vervolgopdrachten te kunnen verwerven. Daarnaast stuur je
                    het projectteam aan binnen de projecten en ben je betrokken
                    bij de dagdagelijkse ontwikkeling van de medewerkers.
                  </SimpleText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Heb jij de skills die wij zoeken?
            </BigTitle>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SubTitle
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  maxWidth: 800,
                  fontSize: 20,
                  lineHeight: 1,
                }}
              >
                Stuur je motivatie en sollicitatie in en wie weet word je
                binnenkort onderdeel van het team.
              </SubTitle>
            </div>
            <div>
              <a
                href="mailto:hr@skillmedia.be?subject=Sollicitatie Project Manager&body=Hierbij stuur ik mijn sollicitatie met motivatie en CV."
                style={{ textDecoration: "none" }}
              >
                <StyledButton inverse={true}>
                  STUUR JE SOLLICITATIE IN
                </StyledButton>
              </a>
            </div>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(DigitalMarketeer));
