import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: 88,
      [theme.breakpoints.down("lg")]: {
        fontSize: 76,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 68,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 50,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 30,
      },
    },
    blogHolder: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    midRoot: {
      justifyContent: "center",
      alignItems: "center",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    goalTitle: {
      fontFamily: "Plantin MT Pro, sans serif",
      fontWeight: "bold",
      fontSize: 55,
      letterSpacing: -2,
      lineHeight: 0.9,
      marginBottom: 0,
      [theme.breakpoints.down("lg")]: {
        fontSize: 52,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 48,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 44,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 38,
      },
    },
    goalText: {
      marginTop: 6,
      fontSize: 30,
      letterSpacing: -1,
      lineHeight: 0.9,
      [theme.breakpoints.down("lg")]: {
        fontSize: 28,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 26,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 22,
      },
    },
    goalList: {
      listStyleType: "none",
      marginLeft: 0,
      paddingLeft: 0,
      "& li": {
        fontSize: 44,
        fontWeight: "bold",
        letterSpacing: -1,
        borderBottom: "2px solid black",
        marginBottom: 26,
        [theme.breakpoints.down("lg")]: {
          fontSize: 42,
        },
        [theme.breakpoints.down("md")]: {
          fontSize: 38,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 30,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 24,
        },

        "& a": {
          position: "relative",
          display: "inline-block",
          textDecoration: "none",
          color: "inherit",
          background:
            "linear-gradient(to right, rgb(103, 195, 234,1), rgba(105, 141, 200, 1) 50%, black 50%);",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundSize: "200% 100%",
          backgroundPosition: "100%",
          transition: "background-position 275ms ease",
          "&:hover": {
            "background-position": "0 100%",
          },
        },
      },
    },
    imgHolder: {
      position: "relative",
      maxWidth: 900,
      marginTop: 200,
      width: "100%",
      height: "100%",
      minHeight: 480,
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        marginTop: 60,
      },
    },
    imgRight: {
      position: "absolute",
      right: 0,
      top: 0,
      width: 420,
      height: 620,
      [theme.breakpoints.down("lg")]: {
        width: 320,
        height: 500,
        right: 80,
        top: 50,
      },
      [theme.breakpoints.down("md")]: {
        width: 320,
        height: 500,
        right: 80,
        top: 140,
      },
      [theme.breakpoints.down("sm")]: {
        width: 320,
        height: 480,
        right: 100,
        top: 0,
      },
      [theme.breakpoints.down("xs")]: {
        width: 240,
        height: 300,
        right: 10,
        top: 0,
      },
    },
    imgLeft: {
      position: "absolute",
      left: 160,
      top: 140,
      width: 420,
      height: 380,
      [theme.breakpoints.down("lg")]: {
        width: 350,
        height: 300,
        right: 200,
        top: 180,
      },
      [theme.breakpoints.down("md")]: {
        width: 310,
        height: 270,
        right: 140,
        top: 280,
      },
      [theme.breakpoints.down("sm")]: {
        width: 320,
        height: 300,
        right: 70,
        top: 140,
      },
      [theme.breakpoints.down("xs")]: {
        width: 200,
        height: 210,
        left: 0,
        bottom: 0,
      },
    },
    blogBoxLeft: {
      display: "flex",
      justifyContent: "flex-start",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        marginBottom: 60,
      },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    blogBoxRight: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    boxHolderUnder: {
      marginTop: 100,
    },
  });

export default styles;
