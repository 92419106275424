import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    videoHolder: {
      borderRadius: "100px 0 100px 0",
      [theme.breakpoints.down("md")]: {
        borderRadius: "60px 0 60px 0",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "35px 0 35px 0",
      },
      width: "100%",
      minHeight: 600,
    },
    videoH: {
      borderRadius: "100px 0 100px 0",
      [theme.breakpoints.down("md")]: {
        borderRadius: "60px 0 60px 0",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "35px 0 35px 0",
      },
    },
  });

export default styles;
