import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import BigTitle from "../../text/BigTitle";
import DistortedImg from "../../image/DistortedImg";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  blogItemHolder: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 580,
    [theme.breakpoints.down("md")]: {
      maxWidth: 420,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 380,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  blogText: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 6,
    fontSize: 24,
    letterSpacing: -1,
    lineHeight: 0.9,
    marginBottom: 0,
  },
  blogTags: {
    marginTop: 16,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
    letterSpacing: -1,
    lineHeight: 0.9,
    color: "#779ada",
  },
}));

interface OwnProps {
  img: any;
  img2: any;
  title: string;
  text: string;
  tags: string;
  className?: string;
  right?: boolean;
  style?: any;
  link?: string;
}

type Props = OwnProps;

const BlogItem = (props: Props) => {
  const classes = useStyles();
  const { img, img2, title, text, tags, right, className, link } = props;
  const maxHeight = window.innerWidth < 500 ? 240 : 400;
  const maxWidth = window.innerWidth < 500 ? 240 : 0;
  return (
    <NavLink
      to={link || "/"}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <div
        className={classNames(classes.blogItemHolder, className || null)}
        style={props.style || {}}
      >
        <DistortedImg
          src1={img}
          src2={img2}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          right={right || false}
        />
        <BigTitle style={{ textAlign: "left", marginTop: 20 }}>
          {title}
        </BigTitle>
        <p className={classes.blogText}>{text}</p>
        <p className={classes.blogTags}>{tags}</p>
      </div>
    </NavLink>
  );
};

export default BlogItem;
