import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  smallText: {
    fontSize: 19,
    lineHeight: 1.1,
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    textAlign: "left",

    "& ul li": {
      marginBottom: 20,
    },

    "& ol li": {
      marginBottom: 20,
    },
  },
}));

const SmallText = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.smallText}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default SmallText;
