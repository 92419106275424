import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/views/home/Home";
import { setAppDimensions } from "./redux/actions/app";
import { connect } from "react-redux";
import Skills from "./components/views/skills/Skills";
import Work from "./components/views/work/Work";
import About from "./components/views/about/About";
import Strategy from "./components/views/strategy/Strategy";
import Marketing from "./components/views/marketing/Marketing";
import Development from "./components/views/development/Development";
import Creative from "./components/views/creative/Creative";
import Productie from "./components/views/productie/Productie";
import Rapportering from "./components/views/rapportering/Rapportering";
import Contact from "./components/views/contact/Contact";
import SkillLab from "./components/views/skilllab/SkillLab";
import Video from "./components/views/videotestimonial/Video";
import Terms from "./components/views/terms/Terms";
import Sipwell from "./components/views/cases/sipwell/Sipwell";
import Ginsonline from "./components/views/cases/ginsonline/Ginsonline";
import Daf from "./components/views/cases/daf/Daf";
import Zuiderburen from "./components/views/cases/zuiderburen/Zuiderburen";
import Jobs from "./components/views/jobs/Jobs";
import DigitalMarketeer from "./components/views/jobs/DigitalMarketeer";
import SkillLabIOS14 from "./components/views/skilllab/blogitems/SkillLabIOS14";
import SkillLabGoogle from "./components/views/skilllab/blogitems/SkillLabGoogle";
import GraphicDesigner from "./components/views/jobs/GraphicDesigner";
import FullStackDeveloper from "./components/views/jobs/FullStackDeveloper";
import EasyPost from "./components/views/cases/easypost/EasyPost";
import ProjectManager from "./components/views/jobs/ProjectManager";
import Verwarmmetpellets from "./components/views/cases/verwarmmetpellets/Verwarmmetpellets";
interface State {}

interface DispatchProps {
  doSetAppDimensions: (width: number, height: number) => void;
}

type Props = DispatchProps;

class App extends Component<Props, State> {
  updateDimensions = () => {
    this.props.doSetAppDimensions(window.innerWidth, window.innerHeight);
  };

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route
              path="/case/easypost"
              exact
              render={(props) => <EasyPost {...props} />}
            />
            <Route
                path="/case/verwarmmetpellets"
                exact
                render={(props) => <Verwarmmetpellets {...props} />}
            />
            <Route
              path="/case/zuiderburen"
              exact
              render={(props) => <Zuiderburen {...props} />}
            />
            <Route
              path="/case/dangelloandfrancis"
              exact
              render={(props) => <Daf {...props} />}
            />
            <Route
              path="/case/ginsonline"
              exact
              render={(props) => <Ginsonline {...props} />}
            />
            <Route
              path="/case/sipwell"
              exact
              render={(props) => <Sipwell {...props} />}
            />
            <Route
              path="/skills/rapportering"
              exact
              render={(props) => <Rapportering {...props} />}
            />
            <Route
              path="/skills/productie/video-testimonials"
              exact
              render={(props) => <Video {...props} />}
            />
            <Route
              path="/skills/productie"
              exact
              render={(props) => <Productie {...props} />}
            />
            <Route
              path="/skills/creative"
              exact
              render={(props) => <Creative {...props} />}
            />
            <Route
              path="/skills/development"
              exact
              render={(props) => <Development {...props} />}
            />
            <Route
              path="/skills/marketing"
              exact
              render={(props) => <Marketing {...props} />}
            />
            <Route
              path="/skills/strategy"
              exact
              render={(props) => <Strategy {...props} />}
            />
            <Route path="/jobs" exact render={(props) => <Jobs {...props} />} />
            <Route
              path="/jobs/digital-marketeer"
              exact
              render={(props) => <DigitalMarketeer {...props} />}
            />
            <Route
              path="/jobs/graphic-designer"
              exact
              render={(props) => <GraphicDesigner {...props} />}
            />
            <Route
              path="/jobs/project-manager"
              exact
              render={(props) => <ProjectManager {...props} />}
            />
            <Route
              path="/jobs/fullstack-developer"
              exact
              render={(props) => <FullStackDeveloper {...props} />}
            />
            <Route
              path="/skilllab/hoe-ios14-een-impact-heeft-op-je-facebook-advertenties"
              exact
              render={(props) => <SkillLabIOS14 {...props} />}
            />
            <Route
              path="/skilllab/google-ads-breidt-phrase-match-uit-voor-sterkere-customer-alignment"
              exact
              render={(props) => <SkillLabGoogle {...props} />}
            />
            <Route
              path="/skilllab"
              exact
              render={(props) => <SkillLab {...props} />}
            />
            <Route
              path="/contact"
              exact
              render={(props) => <Contact {...props} />}
            />
            <Route
              path="/team"
              exact
              render={(props) => <About {...props} />}
            />
            <Route
              path="/algemene-voorwaarden"
              exact
              render={(props) => <Terms {...props} />}
            />
            <Route path="/work" exact render={(props) => <Work {...props} />} />
            <Route
              path="/skills"
              exact
              render={(props) => <Skills {...props} />}
            />
            <Route path="/" render={(props) => <Home {...props} />} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    doSetAppDimensions: (width: number, height: number) => {
      dispatch(setAppDimensions(width, height));
    },
  };
}

export default connect(null, mapDispatchToProps)(App);
