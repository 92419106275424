import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./About.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import BigText from "../../text/BigText";
import classNames from "classnames";
import BigTitle from "../../text/BigTitle";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import Jolien from "../../../assets/img/jolien.jpg";
import Jolien2 from "../../../assets/img/jolien2.jpg";
import Jonas from "../../../assets/img/jonas1.jpg";
import Jonas2 from "../../../assets/img/jonas2.jpg";
import Robbe from "../../../assets/img/robbe1.jpg";
import Robbe2 from "../../../assets/img/robbe2.jpg";
import Yago from "../../../assets/img/yago1.jpg";
import Yago2 from "../../../assets/img/yago2.jpg";
import Thierry from "../../../assets/img/tibo1.jpg";
import Thierry2 from "../../../assets/img/tibo2.jpg";
import Robin from "../../../assets/img/robin1.jpg";
import Robin2 from "../../../assets/img/robin2.jpg";
import Ken from "../../../assets/img/ken1.jpg";
import Ken2 from "../../../assets/img/ken2.jpg";
import Bianci from "../../../assets/img/bianci1.jpg";
import Bianci2 from "../../../assets/img/bianci2.jpg";
import Joe from "../../../assets/img/joe1.jpg";
import Joe2 from "../../../assets/img/joe2.jpg";
import Yannick from "../../../assets/img/yannick1.jpg";
import Yannick2 from "../../../assets/img/yannick2.jpg";
import Fytch1 from "../../../assets/img/fytch1.jpg";
import Fytch2 from "../../../assets/img/fytch2.jpg";
import Angelo from "../../../assets/img/angelo1.jpg";
import Angelo2 from "../../../assets/img/angelo2.jpg";
import Jonathan from "../../../assets/img/jonathan1.jpg";
import Jonathan2 from "../../../assets/img/jonathan2.jpg";
import SmallTitle from "../../text/SmallTitle";
import SmallText from "../../text/SmallText";
import HomeSimpleBlock from "../home/HomeSimpleBlock";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import HugeTitle from "../../text/HugeTitle";
import DistortedImg from "../../image/DistortedImg";
import Translate from "../../../Translate/Translate";
interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class About extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;

    let picWidth = windowWidth
      ? windowWidth > 1360
        ? 280
        : windowWidth > 768
        ? windowWidth / 4 - 80
        : windowWidth / 2 - 80
      : 280;

    picWidth = picWidth > 280 ? 280 : picWidth;

    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | {Translate.translate("teamTitle")}</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{ paddingTop: 80, paddingLeft: 10, paddingRight: 10 }}
          >
            <div
              className="animate__animated animate__flipInX"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <HugeTitle
                style={{ marginBottom: 0, paddingBottom: 0, lineHeight: 0.8 }}
              >
                Team.
              </HugeTitle>
              <BigText style={{ maxWidth: 460, marginTop: 0, paddingTop: 0 }}>
                {Translate.translate("teamSubtitle")}
              </BigText>
            </div>

            <Grid
              container
              direction="row"
              className={classes.midContainer}
              style={{
                alignItems: "flex-start",
                marginTop: 80,
                marginBottom: 100,
              }}
            >
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH, classes.staffPicHL)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-1s",
                  )}
                >
                  <DistortedImg
                    src1={Jonathan}
                    src2={Jonathan2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Jonathan
                  </SmallTitle>
                  <SmallText>Managing Partner</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-1s",
                  )}
                >
                  <DistortedImg
                    src1={Angelo}
                    src2={Angelo2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Angelo
                  </SmallTitle>
                  <SmallText>Managing Partner</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-1s",
                  )}
                >
                  <DistortedImg
                    src1={Jonas}
                    src2={Jonas2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Jonas
                  </SmallTitle>
                  <SmallText>Account Manager</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH, classes.staffPicHR)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-1s",
                  )}
                >
                  <DistortedImg
                    src1={Thierry}
                    src2={Thierry2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Tibo
                  </SmallTitle>
                  <SmallText>Customer Success Manager</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH, classes.staffPicHL)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-2s",
                  )}
                >
                  <DistortedImg
                    src1={Robin}
                    src2={Robin2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Robin
                  </SmallTitle>
                  <SmallText>Art Director</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-2s",
                  )}
                >
                  <DistortedImg
                    src1={Ken}
                    src2={Ken2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Ken
                  </SmallTitle>
                  <SmallText>Senior Developer</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-2s",
                  )}
                >
                  <DistortedImg
                    src1={Bianci}
                    src2={Bianci2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Alessandro
                  </SmallTitle>
                  <SmallText>Marketing Strategist</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH, classes.staffPicHR)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-2s",
                  )}
                >
                  <DistortedImg
                    src1={Joe}
                    src2={Joe2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Joe
                  </SmallTitle>
                  <SmallText>Videographer</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH, classes.staffPicHL)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-3s",
                  )}
                >
                  <DistortedImg
                    src1={Fytch1}
                    src2={Fytch2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Fytch
                  </SmallTitle>
                  <SmallText>Photographer</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH)}
              >
                <div
                  className={classNames(
                    classes.staffPic,
                    "animate__animated animate__flipInY animate__delay-3s",
                  )}
                >
                  <DistortedImg
                    src1={Yago}
                    src2={Yago2}
                    maxWidth={picWidth}
                    maxHeight={picWidth + 20}
                  />
                  <SmallTitle style={{ marginTop: 10, lineHeight: 0.8 }}>
                    Yago
                  </SmallTitle>
                  <SmallText>Sales Developer &amp; Copywriter</SmallText>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH)}
              ></Grid>
              <Grid
                item
                md={3}
                xs={6}
                className={classNames(classes.staffPicH, classes.staffPicHR)}
              ></Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "white",
              paddingBottom: 100,
              paddingTop: 100,
            }}
          >
            <div
              className="animate__animated animate__flipInX"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <BigTitle
                style={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  lineHeight: 1,
                  maxWidth: 360,
                  textAlign: "left",
                }}
              >
                {Translate.translate("homeHowWeWork")}
              </BigTitle>
              <BigText
                style={{
                  maxWidth: 600,
                  marginTop: 0,
                  paddingTop: 0,
                  textAlign: "center",
                }}
              >
                {Translate.translate("homeHowWeWorkSubtitle")}
              </BigText>
            </div>
          </Grid>
          <Grid
            container
            className={classes.midRoot}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              backgroundColor: "white",
              paddingBottom: 100,
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HomeSimpleBlock
                  first={true}
                  className="animate__animated animate__backInRight"
                  number="1"
                  title={Translate.translate("homeHowTitle1")}
                  text={Translate.translate("homeHowText1")}
                />
                <HomeSimpleBlock
                  className="animate__animated animate__backInLeft"
                  number="2"
                  title={Translate.translate("homeHowTitle2")}
                  text={Translate.translate("homeHowText2")}
                />
                <HomeSimpleBlock
                  className="animate__animated animate__backInRight"
                  number="3"
                  title={Translate.translate("homeHowTitle3")}
                  text={Translate.translate("homeHowText3")}
                />
                <HomeSimpleBlock
                  className="animate__animated animate__backInLeft"
                  number="4"
                  title={Translate.translate("homeHowTitle4")}
                  text={Translate.translate("homeHowText4")}
                />
                <NavLink to="/contact" style={{ textDecoration: "none" }}>
                  <StyledButton
                    className={
                      "animate__animated animate__bounceInUp animate__delay-1s"
                    }
                    style={{ marginTop: 60 }}
                  >
                    {Translate.translate("homeLetsGrow")}
                  </StyledButton>
                </NavLink>
              </div>
            </div>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              {Translate.translate("contactUs")}
            </BigTitle>
            <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
              {Translate.translate("contactGrow")}
            </SubTitle>
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <StyledButton inverse={true}>
                {Translate.translate("contactLetsWork")}
              </StyledButton>
            </NavLink>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(About));
