const primaryColor = "#F6A800";
const secondaryColor = "#1565c0";
const fontFamily = ["TTCommons", "sans-serif"];
const fontSize = "1rem";
const lineHeight = 1.7;
const fontWeight = 400;
const margin = 10;

export const ThemeAbstracts = {
  colors: {
    primary: primaryColor,
    secondary: secondaryColor,
  },
  font: {
    fontFamily: fontFamily.join(","),
    fontSize: fontSize,
    lineHeight: lineHeight,
    fontWeight: fontWeight,
  },
  layout: {
    defaultMargin: margin,
    drawerWidth: 240,
    maxPageWidth: 1360,
  },
};

/**
 * Font
 */

const MuiTheme = {
  typography: {
    useNextVariants: true,
    fontFamily: fontFamily.join(","),
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
};

export default MuiTheme;
