import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Jobs.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import BigTitle from "../../text/BigTitle";
import JobsPic from "../../../assets/img/sk_jobs.jpg";
import StrategyBlock from "../strategy/StrategyBlock";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import JobTitle from "../../text/JobTitle";
import SimpleText from "../../text/SimpleText";
import SmallLink from "../../text/SmallLink";
import Translate from "../../../Translate/Translate";
import HugeTitle from "../../text/HugeTitle";
import BigText from "../../text/BigText";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Jobs extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | Jobs</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <HugeTitle
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                      lineHeight: 0.8,
                    }}
                  >
                    Jobs.
                  </HugeTitle>
                  <BigText
                    style={{ maxWidth: 460, marginTop: 0, paddingTop: 0 }}
                  >
                    {Translate.translate("jobsSubtitle")}
                  </BigText>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <StrategyBlock
              right={true}
              src={JobsPic}
              title={Translate.translate("jobsJoinTitle")}
              text={Translate.translate("jobsJoinText")}
            />
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <Grid container className={classes.topBox}>
              <Grid container className={classes.specsHolder}>
                <Grid item xs={12} md={6} className={classes.jobHolder}>
                  <NavLink
                    to="/jobs/project-manager"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <JobTitle>{Translate.translate("jobsJob4Title")}</JobTitle>
                    <SimpleText style={{ textAlign: "left", marginTop: 10 }}>
                      {Translate.translate("jobsJob4Text")}
                    </SimpleText>
                  </NavLink>
                  <NavLink to="/jobs/project-manager">
                    <SmallLink>{Translate.translate("jobsMoreInfo")}</SmallLink>
                  </NavLink>
                </Grid>
                <Grid item xs={12} md={6} className={classes.jobHolder}>
                  <NavLink
                    to="/jobs/digital-marketeer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <JobTitle>{Translate.translate("jobsJob1Title")}</JobTitle>
                    <SimpleText style={{ textAlign: "left", marginTop: 10 }}>
                      {Translate.translate("jobsJob1Text")}
                    </SimpleText>
                  </NavLink>
                  <NavLink to="/jobs/digital-marketeer">
                    <SmallLink>{Translate.translate("jobsMoreInfo")}</SmallLink>
                  </NavLink>
                </Grid>
              </Grid>
              <Grid container className={classes.specsHolder}>
                <Grid item xs={12} md={6} className={classes.jobHolder}>
                  <NavLink
                    to="/jobs/fullstack-developer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <JobTitle>{Translate.translate("jobsJob3Title")}</JobTitle>
                    <SimpleText style={{ textAlign: "left", marginTop: 10 }}>
                      {Translate.translate("jobsJob3Text")}
                    </SimpleText>
                  </NavLink>
                  <NavLink to="/jobs/fullstack-developer">
                    <SmallLink>{Translate.translate("jobsMoreInfo")}</SmallLink>
                  </NavLink>
                </Grid>
                <Grid item xs={12} md={6} className={classes.jobHolder}>
                  <NavLink
                    to="/jobs/graphic-designer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <JobTitle>{Translate.translate("jobsJob2Title")}</JobTitle>
                    <SimpleText style={{ textAlign: "left", marginTop: 10 }}>
                      {Translate.translate("jobsJob2Text")}
                    </SimpleText>
                  </NavLink>
                  <NavLink to="/jobs/graphic-designer">
                    <SmallLink>{Translate.translate("jobsMoreInfo")}</SmallLink>
                  </NavLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              {Translate.translate("jobsFeeling")}
            </BigTitle>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SubTitle
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  maxWidth: 800,
                  fontSize: 20,
                  lineHeight: 1,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                {Translate.translate("jobsFeelingText")}
              </SubTitle>
            </div>
            <div>
              <a
                href="mailto:hr@skillmedia.be?subject=Spontane sollicitatie&body=Hierbij stuur ik mijn spontane sollicitatie met motivatie en CV."
                style={{ textDecoration: "none" }}
              >
                <StyledButton inverse={true}>
                  {Translate.translate("jobsFeelingButton")}
                </StyledButton>
              </a>
            </div>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Jobs));
