import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  midTitle: {
    textAlign: "center",
    marginTop: 60,
    marginBottom: 60,
    fontSize: 46,
    fontWeight: "bold",
    [theme.breakpoints.down("lg")]: {
      marginTop: 80,
      fontSize: 30,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 60,
      fontSize: 26,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
      fontSize: 22,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 60,
      fontSize: 18,
      lineHeight: 1.1,
    },
  },
  midTitleBig: {
    fontSize: 60,
    [theme.breakpoints.down("lg")]: {
      fontSize: 44,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
}));

const MidTitle = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      variant="h3"
      className={classNames(
        classes.midTitle,
        props.enlarge ? classes.midTitleBig : null,
      )}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default MidTitle;
