import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    blogBoxLeft: {
      display: "flex",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: 60,
      },
    },
    blogBoxCenter: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: 60,
      },
    },
    blogBoxRight: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    boxHolderUnder: {
      marginTop: 100,
    },
  });

export default styles;
