import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  theHover: {
    "& div": {
      position: "relative",
      overflow: "hidden",
    },

    "& div::before": {
      position: "absolute",
      top: 0,
      left: -500,
      display: "block",
      content: "''",
      width: "50%",
      height: "100%",
      background:
        "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)",
      transform: "skewX(-25deg)",
    },

    "& div:hover::before": {
      animation: "shine .75s",
    },

    "&.disableEffect div::before": {
      background: "none",
    },
  },
  img: {
    width: "100%",
    height: "100%",
  },
  imgRadiusRight: {
    borderRadius: "0 100px 0 100px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "0 60px 0 60px",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0 35px 0 35px",
    },
  },
  imgRadiusLeft: {
    borderRadius: "100px 0 100px 0",
    [theme.breakpoints.down("md")]: {
      borderRadius: "60px 0 60px 0",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: "35px 0 35px 0",
    },
  },
}));

interface OwnProps {
  src: any;
  right?: boolean;
  maxHeight?: number;
  maxWidth?: number;
  disable?: boolean;
  noEffect?: boolean;
}

type Props = OwnProps;

const RadiusImg = (props: Props) => {
  const { src, right, maxHeight, maxWidth, disable, noEffect } = props;
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.theHover,
        noEffect ? "disableEffect" : null,
      )}
    >
      <div>
        <img
          src={src}
          alt="homedoel2"
          className={classNames(
            classes.img,
            disable
              ? null
              : right
              ? classes.imgRadiusRight
              : classes.imgRadiusLeft,
          )}
          style={{
            backgroundImage: `url(${src}`,
            maxHeight: maxHeight || "auto",
            maxWidth: maxWidth || "auto",
          }}
        />
      </div>
    </div>
  );
};

export default RadiusImg;
