import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../../redux/reducers";
import Header from "../../../partials/Header";
import PageView from "../../../partials/PageView";
import styles from "./EasyPost.styles";
import { connect } from "react-redux";
import Footer from "../../../partials/Footer";
import BigText from "../../../text/BigText";
import BigTitle from "../../../text/BigTitle";
import SubTitle from "../../../text/SubTitle";
import StyledButton from "../../../button/StyledButton";
import { NavLink } from "react-router-dom";
import RadiusImg from "../../../image/RadiusImg";
import TopImg from "../../../../assets/img/case_easypost_banner.jpg";
import Content1 from "../../../../assets/img/calculatorfunnel.png";
import { ThemeAbstracts } from "../../../../config/MuiTheme";
import SmallTitle from "../../../text/SmallTitle";
import SmallText from "../../../text/SmallText";
import Helmet from "react-helmet";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

const data = {
  challengeTitle: "Uitdaging",
  challenge: (
    <>
      Bedrijven ontzorgen op vlak van bedrijfspost en -communicatie. Dat is de
      missie van EasyPost. Door fikse besparingen aan te bieden in combinatie
      met een efficiënter postverwerkingsproces, merk je als klant al snel de
      vele voordelen die EasyPost te bieden heeft.
      <br />
      <br />
      Hoe ze die fikse besparingen kunnen aanbieden? Dat valt onder het motto:
      samen staan we sterk. Laten we dit even duidelijk maken in een korte
      rekensom:
      <br />
      <br />
      <span
        style={{
          display: "block",
          width: "100%",
          textAlign: "center",
          fontStyle: "italic",
        }}
      >
        Meer klanten = meer post
        <br />
        Meer post = grotere volumes
        <br />
        Grotere volumes = volumekorting
        <br />
        Volumekorting = meer besparing voor alle klanten
      </span>
      <br />
      <br />
      De doelstelling was snel afgestemd: meer contactaanvragen genereren zodat
      hun klantenbestand blijft groeien.
      <br />
      <br />
      Na het analyseren van hun huidige marketingaanpak viel het snel op dat er
      een bottleneck zat bij de conversies. Dit wil zeggen dat potentiële
      klanten vaak de website bezochten, maar nog niet overtuigd waren om
      contact op te nemen, of een offerte aan te vragen. De drempel was met
      andere woorden te hoog en het absolute voordeel voor prospecten was nog
      niet honderd procent duidelijk.
      <br />
      <br />
      We gingen op zoek naar een manier om deze ‘research-fase’ te verwerken in
      de website van EasyPost. Een manier waarbij klanten zelf de proef op de
      som nemen en het absolute voordeel voor hun bedrijf in real-time te zien
      krijgen.
    </>
  ),
  solutionTitle: "Oplossing",
  solution: (
    <>
      Eureka! Na een enkele meetings en brainstormsessies kwam er bij EasyPost
      een prachtig idee boven water: een calculator. Een interactieve
      “berekeningsmachine” die de gebruiker vlot en eenvoudig kan invullen.
      <br />
      <br />
      Aan de hand van enkele korte, maar doelgerichte, vragen berekent het
      algoritme hoeveel die gebruiker op jaarbasis kan besparen door een
      samenwerking met EasyPost aan te gaan.
      <br />
      <br />
      Door deze ‘tool’ te implementeren, weten gebruikers hun besparing op
      voorhand, wat de bottleneck bij de conversie weghoudt en waardoor het voor
      het sales-team van EasyPost eenvoudiger wordt. Conclusie: minder
      tijdverspilling voor prospecten, meer sales voor EasyPost! Het is dus een
      win-win! We tekenen het hieronder even uit:
    </>
  ),
  calculatorImg: Content1,
  resultTitle: "Resultaat",
  resultText: (
    <>
      Door deze handige ‘tool’ te integreren op de website van EasyPost merkte
      we een aanzienlijke stijging van het aantal leads van maar liefst 291% en
      een stijging van het conversieratio van 0,23% naar 1,73% wat dus wil
      zeggen dat er voor hetzelfde aantal gebruikers, veel meer kwalitatieve
      aanvragen binnenkomen.
      <br />
      <br />
      Een hele handige leadgenerator-tool, die zijn investering snel
      terugverdiende dus!
    </>
  ),
};

class EasyPost extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>
            Skillmedia | Case EasyPost Calculator - Meer contactaanvragen
            genereren zodat het klantenbestand blijft groeien
          </title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 680,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <BigTitle
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        lineHeight: 1,
                        maxWidth: 600,
                        textAlign: "left",
                      }}
                      bigger={true}
                    >
                      EasyPost Calculator
                    </BigTitle>
                    <SmallText
                      style={{
                        maxWidth: 500,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Een interactieve “berekeningsmachine” die de gebruiker
                      vlot en eenvoudig kan invullen. Doel: meer
                      contactaanvragen genereren zodat het klantenbestand blijft
                      groeien.
                    </SmallText>
                    <p className={classes.blogTags}>
                      Strategy - digital marketing - development - analytics
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <div
              style={{ width: "100%" }}
              className={"animate__animated animate__flipInX"}
            >
              <RadiusImg
                src={TopImg}
                maxWidth={ThemeAbstracts.layout.maxPageWidth}
                maxHeight={460}
                noEffect={true}
              />
            </div>
            <Grid container style={{ height: 100 }} />
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  {data.challengeTitle}
                </SmallTitle>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  {data.challenge}
                </SmallText>
              </Grid>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  {data.solutionTitle}
                </SmallTitle>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  {data.solution}
                </SmallText>
                <div
                  style={{
                    width: "100%",
                    maxWidth: 680,
                    marginBottom: 20,
                    marginTop: 40,
                  }}
                >
                  <RadiusImg
                    src={data.calculatorImg}
                    maxWidth={680}
                    noEffect={true}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#e9e9e9",
              fontSize: 20,
              marginTop: 80,
              paddingTop: 60,
              paddingBottom: 60,
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
            }}
          >
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  {data.resultTitle}
                </SmallTitle>
                <div style={{ lineHeight: 1.4 }}>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    {data.resultText}
                  </p>
                </div>
                <Grid container style={{ justifyContent: "center" }}>
                  <a
                    rel="noreferrer"
                    href="https://calculator.easypost.eu"
                    target="_blank"
                    style={{ marginTop: 0, textDecoration: "none" }}
                  >
                    <StyledButton
                      inverse={true}
                      className={"animate__animated animate__bounceInUp"}
                      style={{ marginTop: 60 }}
                    >
                      BEKIJK HEM HIER ZELF
                    </StyledButton>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ justifyContent: "center" }}>
            <NavLink
              to="/work"
              style={{ marginTop: 60, textDecoration: "none" }}
            >
              <StyledButton
                className={"animate__animated animate__bounceInUp"}
                style={{ marginTop: 60 }}
              >
                MEER CASES
              </StyledButton>
            </NavLink>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Contacteer ons
            </BigTitle>
            <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
              Ontdek hoe Skillmedia je helpt groeien
            </SubTitle>
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <StyledButton inverse={true}>LATEN WE SAMENWERKEN</StyledButton>
            </NavLink>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(EasyPost));
