import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  smallBlogText: {
    fontSize: 19,
    lineHeight: 1.1,
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    textAlign: "left",

    "& ul li": {
      marginBottom: 20,
    },

    "& ol li": {
      marginBottom: 20,
    },

    "& table": {
      border: "1px solid black",
      borderCollapse: "collapse",

      "& td": {
        "&:first-child": {
          borderRight: "1px solid grey",
        },
        verticalAlign: "top",
        borderBottom: "1px solid grey",
        padding: 6,
      },
    },
  },
}));

const SmallBlogText = (props: any) => {
  const classes = useStyles();
  return (
    <div
      className={classes.smallBlogText}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </div>
  );
};

export default SmallBlogText;
