import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "black",
    },
    footerHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      padding: "40px 10px",
      color: "white",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    footerMenu: {
      display: "table-row",
      listStyleType: "none",
      margin: 0,
      padding: 0,
      overflow: "hidden",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },

      "& li": {
        display: "table-cell",
        padding: 0,
        paddingRight: 24,
        verticalAlign: "middle",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },

      "& li a": {
        display: "inline-block",
        textTransform: "uppercase",
        color: "white",
        textAlign: "center",
        textDecoration: "none",
        fontFamily: "TTCommonsDemiBold, sans serif",

        "&::after": {
          display: "block",
          content: "''",
          borderBottom: "solid 3px #019fb6",
          transform: "scaleX(0)",
          transition: "transform 250ms ease-in-out",
        },

        "&:hover": {
          color: "#1887bc",
        },

        "&:hover::after": {
          transform: "scaleX(1)",
        },
      },
    },
    link: {
      color: "white",
      textDecoration: "none",
      "&:hover": {
        color: "black",
      },
    },
    linkBlue: {
      color: "#0084ba",
      textDecoration: "none",
      "&:hover": {
        color: "white",
      },
    },
    logoHolder: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingBottom: 20,
    },
    bottomLink: {
      color: "#0084ba",
      textDecoration: "none",
      "&:hover": {
        color: "white",
      },
    },
    termsLink: {
      color: "#6e6e6e",
      textDecoration: "none",
      fontWeight: "bold",
      marginTop: 8,
      fontSize: 14,
      fontFamily: "Plantin MT Pro, sans serif",
      marginRight: 30,
      "&:hover": {
        borderBottom: "2px solid white",
      },
    },
    socialLinks: {
      [theme.breakpoints.down("md")]: {
        marginTop: 40,
        marginBottom: 40,
      },
    },
    bottomLinks: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  });

export default styles;
