import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    title: {
      background:
        "linear-gradient(to bottom, rgb(103, 195, 234,1), rgba(105, 141, 200, 1) 50%);",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: 88,
      lineHeight: 0.9,
      [theme.breakpoints.down("lg")]: {
        fontSize: 76,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 68,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 50,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 30,
      },
      "& span": {
        background:
          "linear-gradient(to bottom, rgb(103, 195, 234,1), rgba(105, 141, 200, 1) 50%);",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
      padding: 0,
      margin: 0,
    },
    subTitle: {
      marginTop: 10,
      padding: 0,
      fontSize: 28,
      color: "#161616",
      fontFamily: "Plantin MT Pro, sans serif",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
      },
    },
    rightSide: {
      textAlign: "right",
      paddingRight: 10,
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        paddingBottom: 30,
      },
    },
    call: {
      fontSize: 28,
      color: "#161616",
      fontFamily: "Plantin MT Pro, sans serif",
      margin: 0,
    },
    callLink: {
      fontSize: 28,
      color: "#161616",
      textDecoration: "none",
      "&:hover": {
        color: "#5b91ff",
      },
    },
    bottomHQ: {
      margin: 0,
      padding: 0,
      fontSize: 24,
      color: "#161616",
      fontFamily: "Plantin MT Pro, sans serif",
      fontWeight: "bold",
    },
    bottomText: {
      margin: 0,
      padding: 0,
      fontSize: 20,
    },
    bottomSmallLink: {
      color: "black",
      textDecoration: "underline",
      "&:hover": {
        color: "#5b91ff",
      },
    },
    bottomLink: {
      color: "black",
      textDecoration: "none",
      fontSize: 20,
      "&:hover": {
        color: "#5b91ff",
      },
    },
    bottomLink2: {
      color: "black",
      textDecoration: "underline",
      fontSize: 20,
      "&:hover": {
        color: "#5b91ff",
      },
    },
    middleGrid: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 20,
        alignItems: "flex-start",
      },
    },
    rightGrid: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 20,
        alignItems: "flex-start",
      },
    },
  });

export default styles;
