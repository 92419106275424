import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  bigTitle: {
    fontSize: 38,
    color: "#161616",
    fontFamily: "Plantin MT Pro, sans serif",
    fontWeight: "bold",
    [theme.breakpoints.down("lg")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 34,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      lineHeight: 1.1,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
      lineHeight: 1.1,
    },
    textAlign: "center",
  },
  bigger: {
    fontSize: 52,
    color: "#161616",
    fontFamily: "Plantin MT Pro, sans serif",
    fontWeight: "bold",
    [theme.breakpoints.down("lg")]: {
      fontSize: 48,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 44,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
      lineHeight: 1.1,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
      lineHeight: 1.1,
    },
  },
}));

const BigTitle = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      variant={props.variant || "h1"}
      className={`${classes.bigTitle} ${props.bigger ? classes.bigger : null}`}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default BigTitle;
