import React from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import RadiusImg from "../../image/RadiusImg";
import SmallTitle from "../../text/SmallTitle";
import { NavLink } from "react-router-dom";
import Love from "../../../assets/img/love.png";
import Forward from "../../../assets/img/forward.png";
const useStyles = makeStyles((theme: Theme) => ({
  blogItemHolder: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 420,
    [theme.breakpoints.down("md")]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 400,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  blogText: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 6,
    fontSize: 24,
    letterSpacing: -1,
    lineHeight: 0.9,
    marginBottom: 0,
  },
  blogTags: {
    marginTop: 16,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
    letterSpacing: -1,
    lineHeight: 0.9,
    color: "#779ada",
  },
  rdmore: {
    textDecoration: "none",
    fontWeight: "bold",
    background:
      "linear-gradient(to right, rgb(103, 195, 234,1), rgba(105, 141, 200, 1));",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "200% 100%",
    backgroundPosition: "0 100%",
  },
}));

interface OwnProps {
  img: any;
  title: string;
  text: string;
  link: string;
  className?: string;
  right?: boolean;
  style?: any;
  date?: string;
}

type Props = OwnProps;

const SkillBlogItem = (props: Props) => {
  const classes = useStyles();
  const { img, title, text, link, right, className, date } = props;
  return (
    <div
      className={classNames(classes.blogItemHolder, className || null)}
      style={props.style || {}}
    >
      <NavLink to={link}>
        <RadiusImg
          src={img}
          maxHeight={400}
          right={right || false}
          disable={true}
        />
      </NavLink>
      <NavLink to={link} style={{ textDecoration: "none", color: "inherit" }}>
        <p style={{ fontSize: 14, color: "grey" }}>{date}</p>
        <SmallTitle style={{ textAlign: "left", marginTop: 20 }}>
          {title}
        </SmallTitle>
        <p className={classes.blogText}>{text}</p>
      </NavLink>
      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={8}>
          <NavLink to={link} className={classes.rdmore}>
            <span>read more</span>
          </NavLink>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="sharethis-inline-share-buttons"></div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SkillBlogItem;
