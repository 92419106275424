import { createStyles } from "@material-ui/core";

const styles = () =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
      scrollSnapType: "y mandatory",
      backgroundColor: "#f5f5f5",

      "& .childScroll": {
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        width: "100%",
        minWidth: "100%",
      },
      "& .childScrollNormal": {
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        width: "100%",
        minWidth: "100%",
      },
    },
    pageHolder: {
      justifyContent: "center",
      alignItems: "center",
      zIndex: 5,
    },
  });

export default styles;
