import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import Backvideo from "../../assets/video/skillback.mp4";
import styles from "./PageView.styles";
import poster from "../../assets/img/wobbleback.jpg";

interface State {}

interface OwnProps {
  classes?: any;
}

type Props = OwnProps;

class PageView extends Component<Props, State> {
  componentDidMount = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="column" className={classes.root}>
        <video
          autoPlay
          muted
          loop
          id="backvideo"
          style={{
            objectFit: "cover",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 0,
          }}
          playsInline
          poster={poster}
        >
          <source src={Backvideo} type="video/mp4" />
        </video>
        <Grid container direction="column" className={classes.pageHolder}>
          {this.props.children}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PageView);
