import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    hoverNew: {
      "& div": {
        position: "relative",
        overflow: "hidden",
      },
    },
    img: {
      width: "100%",
      height: "100%",
    },
    imgRadiusLeft: {},
    theDiv: {
      position: "relative",
      background: "rgba(0,0,0,0)",
      display: "flex",
      alignItems: "flex-end",
      overflow: "hidden",

      "&.right canvas": {
        borderRadius: "0 100px 0 100px",
        [theme.breakpoints.down("md")]: {
          borderRadius: "0 60px 0 60px",
        },
        [theme.breakpoints.down("xs")]: {
          borderRadius: "0 35px 0 35px",
        },
      },

      "&.left canvas": {
        borderRadius: "100px 0 100px 0",
        [theme.breakpoints.down("md")]: {
          borderRadius: "60px 0 60px 0",
        },
        [theme.breakpoints.down("xs")]: {
          borderRadius: "35px 0 35px 0",
        },
      },

      "& canvas": {
        position: "absolute",
        zIndex: 0,
      },

      "& img": {
        opacity: 0,
      },
    },
  });

export default styles;
