import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import hoverEffect from "hover-effect";
import Displacement from "../../assets/img/displacement.png";
import styles from "./DistortedImg.styles";

interface State {
  divClass: string;
}

interface OwnProps {
  classes?: any;
  src1: any;
  src2: any;
  right?: boolean;
  maxHeight?: number;
  maxWidth?: number;
  disable?: boolean;
}

type Props = OwnProps;

class DistortedImg extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      divClass: "animation" + String(Math.floor(Math.random() * 9999) + 1000),
    };
  }

  componentDidMount = () => {
    const classNamez = "." + this.state.divClass;
    console.log(classNamez);
    setTimeout(() => {
      Array.from(document.querySelectorAll(classNamez)).forEach((el) => {
        const imgs = Array.from(el.querySelectorAll("img"));
        new hoverEffect({
          parent: el,
          intensity: 0.3,
          image1: imgs[0].getAttribute("src"),
          image2: imgs[1].getAttribute("src"),
          displacementImage: Displacement,
        });
      });
    }, 500);
  };

  render() {
    const {
      disable,
      right,
      classes,
      src1,
      src2,
      maxHeight,
      maxWidth,
    } = this.props;
    const { divClass } = this.state;
    return (
      <div
        className={classNames(
          classes.theDiv,
          divClass,
          disable ? null : right ? "right" : "left",
        )}
        style={{ height: maxHeight, width: maxWidth || "auto" }}
      >
        <img src={src1} alt="starting point" />
        <img src={src2} alt="fading point" />
      </div>
    );
  }
}

export default withStyles(styles)(DistortedImg);
