import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../../redux/reducers";
import Header from "../../../partials/Header";
import PageView from "../../../partials/PageView";
import styles from "./Zuiderburen.styles";
import { connect } from "react-redux";
import Footer from "../../../partials/Footer";
import BigText from "../../../text/BigText";
import BigTitle from "../../../text/BigTitle";
import SubTitle from "../../../text/SubTitle";
import StyledButton from "../../../button/StyledButton";
import { NavLink } from "react-router-dom";
import RadiusImg from "../../../image/RadiusImg";
import TopImg from "../../../../assets/img/case_zuiderburen_banner.jpg";
import Content1 from "../../../../assets/img/case_zuiderburen_content_1.png";
import Content2 from "../../../../assets/img/case_zuiderburen_content_2.png";
import Content3 from "../../../../assets/img/case_zuiderburen_content_3.png";
import Content7 from "../../../../assets/img/case_zuiderburen_website.png";
import { ThemeAbstracts } from "../../../../config/MuiTheme";
import SmallTitle from "../../../text/SmallTitle";
import SmallText from "../../../text/SmallText";
import Helmet from "react-helmet";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Zuiderburen extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>
            Skillmedia | Case Zuiderburen - re-branding van een festival
          </title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 680,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <BigTitle
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        lineHeight: 1,
                        maxWidth: 600,
                        textAlign: "left",
                      }}
                      bigger={true}
                    >
                      Zuiderburen.
                    </BigTitle>
                    <SmallText
                      style={{
                        maxWidth: 500,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Re-branding the one &amp; only city festival of 't Zuid
                    </SmallText>
                    <p className={classes.blogTags}>
                      grapic design - re-branding - print
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <div
              style={{ width: "100%" }}
              className={"animate__animated animate__flipInX"}
            >
              <RadiusImg
                src={TopImg}
                maxWidth={ThemeAbstracts.layout.maxPageWidth}
                maxHeight={460}
                noEffect={true}
              />
            </div>
            <Grid container style={{ height: 100 }} />
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  Een boodschap voor jong en oud
                </SmallTitle>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  The one and only city festival of "'t Zuid", Zuiderburen,
                  zocht voor zijn 4e editie een re-branding bij Skillmedia. Als
                  gratis stadsfestival wil men in zijn communicatie een zo breed
                  mogelijk publiek aanspreken. De boodschap moet overkomen bij
                  jong en oud van food- tot muziekliefhebbers. Door te werken
                  met herkenbare basiselementen en kleuren konden er
                  probleemloos promoties opgezet worden op verschillende media
                  en gecommuniceerd worden in samenhang met partners zoals het
                  Museum van Schone Kunsten, projectontwikkelaar Triple Living
                  en gym The Brick.
                </SmallText>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  Tot slot werd voor de losstaande avondeditie in Plein Publiek
                  de stijl doorgetrokken weliswaar met andere kleuren en andere
                  elementen.
                </SmallText>
                <Grid container direction="row" style={{ marginTop: 40 }}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content1} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content2} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content3} width="100%" alt="content1" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#e9e9e9",
              fontSize: 20,
              marginTop: 80,
              paddingTop: 60,
              paddingBottom: 60,
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
            }}
          >
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <img
                  src={Content7}
                  width="100%"
                  alt="content1"
                  style={{ marginTop: 40, marginBottom: 30 }}
                />
                <div
                  style={{ lineHeight: 1.4, marginBottom: 40, marginTop: 40 }}
                >
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Zelf ook zin om de unieke zuiderburen sfeer op te snuiven?
                    Surf naar{" "}
                    <a
                      href="https://www.zuiderburen.be"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.zuiderburen.be
                    </a>{" "}
                    en ontdek alles over de volgende editie.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <NavLink
              to="/work"
              style={{ marginTop: 60, textDecoration: "none" }}
            >
              <StyledButton
                className={"animate__animated animate__bounceInUp"}
                style={{ marginTop: 60 }}
              >
                MEER CASES
              </StyledButton>
            </NavLink>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Contacteer ons
            </BigTitle>
            <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
              Ontdek hoe Skillmedia je helpt groeien
            </SubTitle>
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <StyledButton inverse={true}>LATEN WE SAMENWERKEN</StyledButton>
            </NavLink>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Zuiderburen));
