import React from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import RadiusImg from "../../image/RadiusImg";
import BigTitle from "../../text/BigTitle";
import SmallText from "../../text/SmallText";
import { NavLink } from "react-router-dom";
import StyledButton from "../../button/StyledButton";

const useStyles = makeStyles((theme: Theme) => ({
  strategyBox: {
    position: "relative",
    width: "100%",
    minHeight: 700,
    [theme.breakpoints.down("xs")]: {
      minHeight: 700,
    },
  },
  strategyImg: {
    maxWidth: 600,
    maxHeight: 600,
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 400,
      maxHeight: 400,
    },
  },
  strategyImgRight: {
    right: 0,
    top: 0,
    [theme.breakpoints.down("xs")]: {
      right: 60,
    },
  },
  strategyImgLeft: {
    left: 0,
    top: 0,
    [theme.breakpoints.down("xs")]: {
      left: 60,
    },
  },
  strategyTopTextSmall: {
    display: "none",
    maxWidth: 600,
    marginLeft: 60,
    marginRight: 60,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  strategyTopText: {
    position: "absolute",
    display: "block",
    top: 100,
    maxWidth: 480,
    left: 0,
    marginRight: 640,
    [theme.breakpoints.down("sm")]: {
      display: "none",
      marginRight: 0,
      top: 40,
      right: 60,
      padding: 54,
      backgroundColor: "white",
      borderRadius: "0 60px 0 60px",
    },
  },
  strategyText: {
    padding: 54,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "0 60px 0 60px",
    position: "absolute",
    top: 320,
    [theme.breakpoints.down("sm")]: {
      top: 360,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 400,
      top: 240,
    },
  },
  strategyTextInner: {
    position: "relative",
    width: "100%",
    height: "auto",
  },
  theButtonRight: {
    position: "absolute",
    bottom: -90,
    left: 0,
  },
  theButton: {
    position: "absolute",
    bottom: -90,
    right: 0,
  },
  strategyTextRight: {
    left: 0,
    marginRight: 400,
    [theme.breakpoints.down("sm")]: {
      right: 60,
      marginRight: 100,
    },
    [theme.breakpoints.down("xs")]: {
      right: 20,
      marginRight: 0,
    },
  },
  strategyTextLeft: {
    right: 0,
    marginLeft: 400,
    [theme.breakpoints.down("sm")]: {
      left: 60,
      marginLeft: 100,
    },
    [theme.breakpoints.down("xs")]: {
      left: 20,
      marginLeft: 0,
    },
  },
}));
interface OwnProps {
  src: any;
  right?: boolean;
  title: string;
  text: string;
  topText?: string;
  button?: string;
  link?: string;
}

type Props = OwnProps;

const StrategyBlock = (props: Props) => {
  const { src, title, text, topText, right, button, link } = props;
  const classes = useStyles();
  return (
    <Grid container>
      <p
        className={classNames(
          classes.strategyTopTextSmall,
          "animate__animated animate__fadeIn",
        )}
      >
        {topText}
      </p>
      <div className={classes.strategyBox}>
        <div
          className={classNames(
            classes.strategyImg,
            right ? classes.strategyImgRight : classes.strategyImgLeft,
            right
              ? "animate__animated animate__fadeInRight"
              : "animate__animated animate__fadeInLeft",
          )}
        >
          <RadiusImg src={src} />
        </div>
        {topText && (
          <div
            className={classNames(
              classes.strategyTopText,
              "animate__animated animate__fadeIn animate__delay-2s",
            )}
          >
            {topText}
          </div>
        )}
        <div
          className={classNames(
            classes.strategyText,
            right ? classes.strategyTextRight : classes.strategyTextLeft,
            right
              ? "animate__animated animate__rotateInDownRight animate__delay-1s"
              : "animate__animated animate__rotateInDownLeft animate__delay-1s",
          )}
        >
          <div className={classes.strategyTextInner}>
            <BigTitle style={{ textAlign: "left" }} variant="h2">
              {title}
            </BigTitle>
            <SmallText>{text}</SmallText>
            {button && (
              <NavLink
                to={link || "/contact"}
                style={{ textDecoration: "none" }}
                className={right ? classes.theButtonRight : classes.theButton}
              >
                <StyledButton inverse>{button}</StyledButton>
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default StrategyBlock;
