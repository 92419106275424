import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Skills.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import HugeTitle from "../../text/HugeTitle";
import BigText from "../../text/BigText";
import { NavLink } from "react-router-dom";
import RadiusImg from "../../image/RadiusImg";
import classNames from "classnames";
import Skills1 from "../../../assets/img/skills1.jpg";
import Skills2 from "../../../assets/img/skills2.jpg";
import SmallText from "../../text/SmallText";
import BigTitle from "../../text/BigTitle";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import Helmet from "react-helmet";
import Translate from "../../../Translate/Translate";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Skills extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia - {Translate.translate("skillsSubtitle")}</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{ paddingTop: 80, paddingLeft: 10 }}
          >
            <div
              className="animate__animated animate__flipInX"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <HugeTitle
                style={{ marginBottom: 0, paddingBottom: 0, lineHeight: 0.8 }}
              >
                Skills.
              </HugeTitle>
              <BigText style={{ maxWidth: 460, marginTop: 0, paddingTop: 0 }}>
                {Translate.translate("skillsSubtitle")}
              </BigText>
            </div>
            <Grid container direction="row" style={{ marginTop: 80 }}>
              <Grid item xs={12} md={6}>
                <div className={classes.imgHolder}>
                  <div
                    className={classNames(
                      classes.imgLeft,
                      "animate__animated animate__fadeInLeft",
                    )}
                  >
                    <RadiusImg src={Skills1} />
                  </div>
                  <div
                    className={classNames(
                      classes.imgRight,
                      "animate__animated animate__rotateInDownLeft",
                    )}
                  >
                    <RadiusImg src={Skills2} right={true} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} className={classes.goalHolder}>
                <ul
                  className={classNames(
                    classes.goalList,
                    "animate__animated animate__fadeInRight",
                  )}
                >
                  <li>
                    <NavLink to="/skills/strategy">
                      {Translate.translate("homeGoalsStrategy")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/skills/creative">
                      {Translate.translate("homeGoalsCreative")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/skills/marketing">
                      {Translate.translate("homeGoalsMarketing")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/skills/development">
                      {Translate.translate("homeGoalsDevelopment")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/skills/productie">
                      {Translate.translate("homeGoalsProduction")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/skills/rapportering">
                      {Translate.translate("homeGoalsReporting")}
                    </NavLink>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <SmallText style={{ maxWidth: 600, marginTop: 80 }}>
              {Translate.translate("skillsNextLevel")}
            </SmallText>
            <SmallText style={{ marginTop: 4, fontWeight: "bold" }}>
              {Translate.translate("skillsCovered")}
            </SmallText>
            <Grid
              container
              style={{ justifyContent: "center", marginTop: 100 }}
            >
              <BigTitle
                style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
                variant="h2"
              >
                {Translate.translate("contactUs")}
              </BigTitle>
              <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
                {Translate.translate("contactGrow")}
              </SubTitle>
              <NavLink to="/contact" style={{ textDecoration: "none" }}>
                <StyledButton inverse={true}>
                  {Translate.translate("contactLetsWork")}
                </StyledButton>
              </NavLink>
              <Grid container style={{ height: 120 }} />
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Skills));
