import { createStyles } from "@material-ui/core";

const styles = () =>
  createStyles({
    carousselHolder: {
      width: "100%",
      overflow: "hidden",
      position: "relative",
    },
    carousselImgHolder: {
      overflow: "hidden",
      position: "absolute",
      top: 0,
      transition: "1s",
    },
    carousselImg: {
      opacity: 1,
      position: "relative",
      width: "100%",
      maxWidth: "100%",
      transition: "all .2s ease-in-out",
      backgroundSize: "contain",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      "&:hover": {
        transform: "scale(1.2)",
      },
      "&:hover > div": {
        opacity: 1,
      },
    },
    carousselImgBlack: {
      opacity: 0,
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      maxWidth: "100%",
      backgroundSize: "contain",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      transition: "all .2s ease-in-out",
    },
  });

export default styles;
