import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import styles from "./Terms.styles";
import Helmet from "react-helmet";
import Header from "../../partials/Header";
import Footer from "../../partials/Footer";
import PageView from "../../partials/PageView";

interface State {}

interface OwnProps {
  classes?: any;
}

type Props = OwnProps;

class Terms extends Component<Props, State> {
  render() {
    const { classes } = this.props;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia - Algemene voorwaarden</title>
        </Helmet>
        <Grid container direction="column" className={classes.root}>
          <Header />
          <Grid container direction="column" className={classes.topH}>
            <Grid container direction="column" className={classes.topBox}>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <h2>Algemene voorwaarden</h2>
                <p>
                  SKILLMEDIA BV &ndash; RIJNKAAI 37, BUS 16 &ndash; 2000
                  ANTWERPEN, BELGI&Euml;
                  <br /> BTW BE 0652.708.941
                  <br /> IBAN BE35 7350 4289 8337
                </p>
                <h2>Artikel 1. Toepassing van de algemene voorwaarden.</h2>
                <p>
                  1.1. De algemene voorwaarden gelden op alle aanbiedingen van
                  en op alle overeenkomsten met Skillmedia, ook al staan er
                  strijdige bepalingen vermeld op documenten van de klant. Door
                  het plaatsen van een bestelling aanvaardt de klant de algemene
                  voorwaarden van Skillmedia.
                </p>
                <h2>Artikel 2. Offertes &ndash; Overeenkomst</h2>
                <p>
                  2.1. Alle offertes en overeenkomsten van Skillmedia zijn
                  vrijblijvend tot op het ogenblik van de aanvaarding door de
                  klant. De overeenkomst komt tot stand wanneer de klant de
                  offerte ongewijzigd binnen de acht dagen voor akkoord
                  ondertekent en terugzendt naar Skillmedia. Elke bestelling of
                  orderbevestiging door de klant, verbindt de klant aan de
                  overeenkomst. De overeenkomst vervangt alle eerder afgesloten
                  mondelinge en/of schriftelijke akkoorden. De uitvoering van de
                  bestelling start vanaf ontvangst van het voorschot.
                </p>
                <p>
                  2.2 Betalingen aan derden, zoals domeinnamen, hosting, email
                  accounts, worden altijd naar de opdrachtgever door
                  gefactureerd tenzij overeengekomen is in het
                  samenwerkingsvoorstel dat deze kosten ten rekening zijn van de
                  opdrachtnemer.
                </p>
                <h2>Artikel 3. Annulatie van de overeenkomst</h2>
                <p>
                  3.1. De annulatie van een overeenkomst door de klant is
                  mogelijk zolang Skillmedia haar werkzaamheden nog niet heeft
                  aangevat en mits betaling van een schadevergoeding van 30% van
                  de overeengekomen prijs.
                </p>
                <p>
                  3.2 Een tijdelijke onderbreking van de samenwerking is niet
                  mogelijk, behoudens met instemming van de tijdelijke
                  onderbreking van de overeenkomst door Skillmedia via
                  aangetekend schrijven. Ingeval van tijdelijke onderbreking is
                  de opdrachtgever aan Skillmedia maandelijks een forfaitaire
                  vergoeding van 10% verschuldigd van het totale maandelijkse te
                  factureren bedrag, inclusief B.T.W. De periode dat de
                  overeenkomst werd onderbroken zal bij de duur van de
                  overeenkomst worden bijgeteld.
                </p>
                <p>
                  3.3 Openstaande vergoedingen en eventuele schadevergoedingen
                  aan derden door de verbreking van de overeenkomst zijn
                  uitsluitend ten laste van de opdrachtgever.
                </p>
                <h2>
                  Artikel 4. Annulering / verbreking van de overeenkomst door
                  Skillmedia.
                </h2>
                <p>
                  4.1 Via aangetekend schrijven kan Skillmedia zonder enige
                  verdere verbintenis de overeenkomst op ieder moment
                  stopzetten, zonder dat daarvoor een compensatie moet worden
                  vergoed aan de opdrachtgever of aan derden. Skillmedia
                  verbindt er zich er toe de reeds betaalde diensten correct uit
                  voeren.
                </p>
                <h2>Artikel 5. Levering</h2>
                <p>
                  5.1. De datum van levering wordt slechts als aanwijzing
                  opgegeven op de overeenkomst en bindt Skillmedia niet.
                  Vertraging in de levering geeft de klant geen recht op
                  schadevergoeding of prijsvermindering, noch op ontbinding van
                  de overeenkomst.
                </p>
                <h2>Artikel 6. Risico</h2>
                <p>
                  6.1. Alle goederen die toebehoren aan de klant en zich bij
                  Skillmedia bevinden, worden er bewaard op risico van de klant.
                </p>
                <h2>Artikel 7. Betalingsmodaliteiten</h2>
                <p>
                  7.1. Tenzij anders overeengekomen, zijn de facturen van
                  Skillmedia contant betaalbaar. Betwistingen dienen binnen de
                  zeven werkdagen na verzending van de factuur per aangetekend
                  schrijven aan Skillmedia kenbaar gemaakt te worden. Een
                  betwisting kan in geen geval een uitstel of schorsing van
                  betaling verantwoorden. Bij niet stipte betaling is de klant
                  eveneens een forfaitaire vergoeding verschuldigd ten belope
                  van 10%.
                </p>
                <p>
                  7.2. Alle facturen zijn betaalbaar uiterlijk 30 kalenderdagen
                  na de factuurdatum. Toegestane kortingen vervallen bij het
                  niet respecteren van de algemene verkoopsvoorwaarden.
                </p>
                <p>
                  7.3. Indien de klant niet overgaat tot betaling binnen 8 dagen
                  na ontvangst van een aanmaning hiertoe door Skillmedia, is de
                  klant aan Skillmedia een nalatigheids-intrest verschuldigd aan
                  de intrestvoet bepaald in artikel 5 van de Wet van 02/08/2002
                  ter Bestrijding van de Betalingsachterstand bij
                  Handelstransacties. Bovendien behoudt Skillmedia zich het
                  recht om de verdere uitvoering van haar verbintenissen op te
                  schorten totdat de klant de vervallen facturen heeft betaald.
                  Elke vertraging in de betaling door de klant maakt alle
                  verschuldigde sommen ineens opeisbaar.
                </p>
                <p>
                  7.4 Webprojecten die on hold zijn gezet door de klant geven
                  geen aanleiding tot schorsing van betaling. Online
                  marketing-trajecten kunnen om planningsredenen niet geschorst
                  worden.
                </p>
                <p>
                  7.5 Klikbudgetten dienen steeds op voorhand betaald te worden.
                  Bij niet stipte betaling&nbsp;zal Skillmedia alle campagnes on
                  hold zetten tot de betaling is doorgevoerd. De klant is
                  ingelicht over zijn betalingsmodaliteiten en kan gebruik maken
                  van een Europese domicili&euml;ring of een rechtstreekse
                  koppeling van hun VISA kaart aan Google of Facebook.
                </p>
                <h2>Artikel 8. Aansprakelijkheid</h2>
                <p>
                  8.1. Skillmedia verbindt zich ertoe alle te verstrekken
                  diensten met zorg uit te voeren. Alle prestaties van
                  Skillmedia zijn middelenverbintenissen. Skillmedia is niet
                  aansprakelijk voor fouten in de uitvoering te wijten aan
                  onvoldoende of verkeerde input door de klant. Na ontwikkeling
                  van een website voorzien wij in een transparante overdracht en
                  installatie.
                </p>
                <h2>Artikel 9. Overmacht</h2>
                <p>
                  9.1 Skillmedia zal niet aansprakelijk zijn in geval zij door
                  overmacht wordt verhinderd om haar verbintenissen uit te
                  voeren. Dit omvat alle niet te voorziene gebeurtenissen
                  (stakingen, publieke onrust, administratieve maatregelen en
                  andere gebeurtenissen buiten de wil van Skillmedia, waardoor
                  de overeenkomst geheel, gedeeltelijk of tijdelijk niet kan
                  worden uitgevoerd. Hieronder valt ook het slecht of niet
                  naleven van de overeenkomst door derden of de opdrachtgever
                  zelf.
                </p>
                <p>
                  9.2 Alle rechtsvorderingen van de klant tegenover Skillmedia,
                  aanspraken op schadevergoedingen daaronder begrepen, vervallen
                  &eacute;&eacute;n jaar nadat de betreffende vordering is
                  ontstaan.
                </p>
                <h2>Artikel 10. Eigendomsrechten</h2>
                <p>
                  10.1. De dienstverlening en creaties blijven eigendom van
                  Skillmedia tot de volledige betaling van de hoofdsom, kosten,
                  interesten en forfaitaire vergoeding door de opdrachtgever is
                  betaald. De dienstverlening gebeurt op risico van de klant.
                </p>
                <h2>Artikel 11. Domeinnamen, Hosting &amp; E-mail accounts.</h2>
                <p>
                  11.1. Indien de klant domeinnamen, hosting of e-mail accounts
                  bestelt via Skillmedia, zal Skillmedia deze bestelling
                  doorgeven aan de gekozen externe partner. De klant zal
                  rechtstreeks een login verkrijgen met toegang tot de bestelde
                  diensten. Skillmedia staat volledig los van deze samenwerking.
                  De klant zal rechtstreeks door de gekozen partner gefactureerd
                  worden.
                </p>
                <h2>Artikel 12. Be&euml;indiging van de overeenkomst</h2>
                <p>
                  12.1. Indien de klant zich schuldig maakt aan een zware
                  contractuele wanprestatie die de klant niet herstelt binnen de
                  8 dagen na ontvangst van een ter post aangetekende
                  ingebrekestelling, heeft Skillmedia het recht om ofwel (i) de
                  overeenkomst te schorsen totdat de klant zijn verbintenissen
                  is nagekomen, ofwel (ii) de overeenkomst met onmiddellijke
                  ingang te be&euml;indigen. De niet-betaling van een of
                  meerdere facturen op hun vervaldag, zal altijd worden
                  beschouwd als een zware contractuele wanprestatie.
                </p>
                <p>
                  12.2. Bij be&euml;indiging van de overeenkomst zal de klant
                  alle door Skillmedia verleende diensten betalen, alsook de
                  kosten die Skillmedia moet maken als gevolg van deze
                  be&euml;indiging, vermeerderd met een forfaitaire
                  schadevergoeding van 30% van het bedrag dat Skillmedia nog had
                  kunnen factureren aan de klant indien de overeenkomst volledig
                  zou zijn uitgevoerd. Het eventueel betaald voorschot blijft
                  hoe dan ook verworven voor Skillmedia. Bovendien behoudt
                  Skillmedia het recht om een hogere schadevergoeding te
                  vorderen indien zij bewijst dat haar werkelijk geleden schade
                  groter is dan de forfaitaire schade zoals hierboven bepaald.
                </p>
                <p>
                  12.3. Niettemin aanvaardt elke partij om aan de andere partij
                  een redelijke termijn toe te kennen om haar eventuele
                  tekortkomingen te verhelpen, en om steeds eerst te zoeken naar
                  een minnelijke regeling.
                </p>
                <h2>Artikel 13. Referentie</h2>
                <p>
                  13.1. De klant gaat ermee akkoord dat het door Skillmedia voor
                  de klant uitgevoerde werk wordt opgenomen in het
                  referentieportfolio.
                </p>
                <h2>Artikel 14. Cases</h2>
                <p>
                  14.1. De klant gaat ermee akkoord dat resultaten die door
                  Skillmedia tot stand zijn gebracht mogen worden gebruikt voor
                  commerci&euml;le doeleinden zoals o.a. (cases,
                  presentaties,&hellip;)
                </p>
                <h2>Artikel 15. Geschillenregeling</h2>
                <p>
                  15.1 Voor alle geschillen zal het Belgische recht op de
                  Overeenkomst van toepassing zijn. Alle geschillen tussen
                  partijen met betrekking tot de totstandkoming, de uitvoering
                  en/of de interpretatie van de Overeenkomst, vallen onder de
                  exclusieve bevoegdheid van de rechtbanken van Dendermonde.
                </p>
                <p>
                  15.2 Alle kosten in verband met de invordering van facturen
                  langs gerechtelijke weg of incasso bureau&rsquo;s, worden op
                  de opdrachtgever verhaald. Het inschakelen van een
                  incassobureau kan na niet-betaling van de factuur vanaf de
                  vervaldatum.
                </p>
                <h2>Artikel 16. Afwerving</h2>
                <p>
                  16.1 De opdrachtgever zal er zich van onthouden om de
                  medewerkers/personeel van de Leverancier een samenwerking aan
                  te bieden of in dienst te nemen of te doen nemen onder eender
                  welk statuut, rechtstreeks of onrechtstreeks via een
                  tussenpersoon of verbonden onderneming, door het actief
                  stellen van daden die die medewerkers/personeel van de
                  Leverancier er zouden kunnen toe aanzetten bij de
                  Opdrachtgever in dienst te gaan. Deze verzaking blijft geldig
                  zowel gedurende de duurtijd van de Samenwerkingsovereenkomst
                  als twaalf maanden na de beëindiging hiervan, behoudens
                  voorafgaand schriftelijk akkoord tussen de Leverancier en de
                  Opdrachtgever. Indien de opdrachtgever tijdens de hierboven
                  vermelde periode toch tot aanwerving van of samenwerking met
                  één van deze medewerkers/personeel van de Leverancier, dan zal
                  hij aan de Leverancier, op diens uitdrukkelijk verzoek, een
                  vergoeding betalen gelijk aan het brutoloon dat de medewerker
                  heeft gedurende de zes (6) maanden die aan zijn vertrek
                  voorafgaan.
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

export default withStyles(styles)(Terms);
