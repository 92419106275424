import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    specsHolder: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    jobHolder: {
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 40,

      "@media only screen and (min-width: 768px)": {
        paddingLeft: 40,
        paddingRight: 40,
      },
    },
  });

export default styles;
