import nl_NL from "../i18n/nl.json";
//import en from "../i18n/en.json";
//import fr from "../i18n/fr.json";
import { Locale } from "../Translate/Translate";

const nlLocale = new Locale("nl", "Nederlands", "NL", nl_NL);
//const enLocale = new Locale("en", "English", "EN", en);
//const frLocale = new Locale("fr", "Français", "FR", fr);

const createLocales = (): Locale[] => {
  return [nlLocale];
};

const i18n = {
  createLocales: () => createLocales(),
};

export default i18n;
