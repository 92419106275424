import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "animate.css/animate.css";
import MuiTheme from "./config/MuiTheme";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducers from "./redux/reducers";
import Translate from "./Translate/Translate";
import i18n from "./config/i18n";

Translate.setLocales(i18n.createLocales());
Translate.setLocale("nl");

const theme = createMuiTheme(MuiTheme);

const store = createStore(reducers);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
