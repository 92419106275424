import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./DigitalMarketeer.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import BigTitle from "../../text/BigTitle";
import MarketeerPic from "../../../assets/img/sk_marketeer.jpg";
import StrategyBlock from "../strategy/StrategyBlock";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import Helmet from "react-helmet";
import JobTitle from "../../text/JobTitle";
import SimpleText from "../../text/SimpleText";
interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class DigitalMarketeer extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | Jobs - Performance Marketeer</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <BigTitle
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                      lineHeight: 1,
                      maxWidth: 300,
                      textAlign: "left",
                    }}
                  >
                    Gezocht:
                  </BigTitle>
                  <JobTitle>PERFORMANCE MARKETEER</JobTitle>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <StrategyBlock
              right={true}
              src={MarketeerPic}
              title="We want you!"
              text="Voor de groei van Skillmedia zijn we op zoek naar iemand om ons team van marketeers te versterken. Reward: een job waar je graag bent."
            />
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <Grid container className={classes.topBox}>
              <Grid container className={classes.specsHolder}>
                <Grid item xs={12} md={6}>
                  <JobTitle>PERFORMANCE MARKETEER</JobTitle>
                  <SimpleText style={{ textAlign: "left", marginTop: 10 }}>
                    Om ons team uit te breiden is Skillmedia op zoek naar een
                    ambitieuze ervaren Performance Marketeer.
                    <br />
                    <br />
                    Als Performance Marketeer ben je samen met je team
                    verantwoordelijk voor de resultaten van de online campagnes
                    van de klant. Je helpt de klant zijn doelstellingen te
                    behalen en te overstijgen. Dit doe je natuurlijk niet
                    alleen, je krijgt alle hulp van je team als je die nodig
                    hebt.
                    <br />
                    <br />
                    Je hebt een zeer proactieve houding gecombineerd met een
                    aansturende rol en dat dringt door in je campagnes. Je zet
                    alles op alles om het maximale te bereiken voor je klanten
                    en zoekt steeds naar nieuwe mogelijkheden om de performance
                    van o.a. jouw Google en Facebook Ads campagnes te
                    verbeteren.
                    <br />
                    <br />
                    Je bent, samen met een account manager, de trusted advisor
                    van je klant en hét aanspreekpunt voor alle (online)
                    marketing. Je beheert voor je klant écht alles wat met hun
                    project te maken heeft; communicatie, uitvoering, opvolging,
                    rapportages, budgetbeheer, strategie, … het ligt allemaal
                    bij jou als performance marketeer.
                  </SimpleText>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 100 }}
                className={classes.specsHolder}
              >
                <Grid item xs={12} md={6}>
                  <SimpleText style={{ textAlign: "left", marginRight: 40 }}>
                    <strong>Profiel</strong>
                    <ul>
                      <li>
                        Je hebt minimaal 1 jaar Digital Marketing-ervaring of
                        vergelijkbaar en werkt graag in een ambitieus team.
                      </li>
                      <li>
                        Je bent ambitieus ingesteld en zorgt er zelfstandig voor
                        dat je altijd op de hoogte bent van de laatste
                        ontwikkelingen binnen de sector.
                      </li>
                      <li>
                        Je hebt een proactieve houding in het beheer van je
                        projecten, campagnes en klanten.
                      </li>
                      <li>
                        Je bent stressbestendig, zeer communicatief en
                        klantvriendelijk ingesteld.
                      </li>
                      <li>
                        Je bent analytisch, gedreven en gebruikt je analyses
                        voor verbetering van jouw campagnes.
                      </li>
                      <li>
                        Je aarzelt niet om met creatieve ideeën of oplossingen
                        voor de boeg te komen en schrijft vlot jouw eigen
                        short-copy voor advertenties.
                      </li>
                      <li>
                        Je hebt een basiskennis van de technische kant van SEO,
                        kent jouw weg in Google Tag Manager en kent de diepste
                        geheimen van Google Analytics.
                      </li>
                      <li>Je hebt oog voor detail en bent leergierig.</li>
                    </ul>
                    <br />
                    <br />
                    <strong>Aanbod</strong>
                    <ul>
                      <li>
                        Als ambitieuze marketeer kom je in een jonge
                        werkomgeving terecht van een groeiend, dynamisch en
                        sfeervol agency
                      </li>
                      <li>Een aantrekkelijk en competitief loon</li>
                      <li>Opleidingsbudget voor evenementen en beurzen</li>
                      <li>Legale voordelen (maaltijdcheques, ..)</li>
                      <li>Aantrekkelijk bonussysteem</li>
                      <li>
                        Constante mogelijkheid om jouw kennisniveau te
                        vergroten, ook onder werktijd
                      </li>
                    </ul>
                    <br />
                    <br />
                    <strong>
                      De uitdagingen die we voor jou bij Skillmedia hebben
                      klaarstaan:
                    </strong>
                    <br />
                    Search Advertising (Google Ads)
                    <br />
                    Social Advertising (Facebook/Linkedin/Instagram)
                    <br />
                    Google Tag manager implementaties
                    <br />
                    Google Analytics (360) implementaties
                    <br />
                    Conversie optimalisatie trajecten
                    <br />
                    Marketing automation Display/Bannering/Retargeting
                    <br />
                    Display/Bannering/Retargeting
                    <br />
                    Dashboarding (360 Data Studio, Supermetrics, Databox)
                  </SimpleText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Heb jij de skills die wij zoeken?
            </BigTitle>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SubTitle
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  maxWidth: 800,
                  fontSize: 20,
                  lineHeight: 1,
                }}
              >
                Stuur je motivatie en sollicitatie in en wie weet word je
                binnenkort onderdeel van het team.
              </SubTitle>
            </div>
            <div>
              <a
                href="mailto:hr@skillmedia.be?subject=Sollicitatie Digital Marketeer&body=Hierbij stuur ik mijn sollicitatie met motivatie en CV."
                style={{ textDecoration: "none" }}
              >
                <StyledButton inverse={true}>
                  STUUR JE SOLLICITATIE IN
                </StyledButton>
              </a>
            </div>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(DigitalMarketeer));
