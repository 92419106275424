import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    staffPic: {
      overflow: "hidden",
      maxWidth: 280,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      [theme.breakpoints.down("md")]: {
        maxWidth: 280,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: 140,
      },
    },
    staffPicH: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 60,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        marginBottom: 20,
        alignItems: "flex-start !important",
      },
    },
    staffPicHL: {
      alignItems: "flex-start !important",
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start !important",
      },
    },
    staffPicHR: {
      alignItems: "flex-end !important",
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start !important",
      },
    },
  });

export default styles;
