import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import styles from "./Footer.styles";
import Logo from "../../assets/img/Skillmedia-white.svg";
import { NavLink } from "react-router-dom";
import Translate from "../../Translate/Translate";

interface State {}

interface OwnProps {
  classes?: any;
}

type Props = OwnProps;

class Footer extends Component<Props, State> {
  render() {
    const { classes } = this.props;
    return (
      <footer style={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          style={{ justifyContent: "flex-end", padding: 40 }}
        >
          <a
            href="https://www.google.com/partners/agency?id=7926495838"
            target="_blank"
          >
            <img src="https://www.gstatic.com/partners/badge/images/2021/PartnerBadgeClickable.svg" />
          </a>
        </Grid>
        <Grid container className={classes.root}>
          <Grid container direction="row" className={classes.footerHolder}>
            <Grid item xs={12} md={12}>
              <img src={Logo} alt="logo" style={{ width: 201, height: 43 }} />
            </Grid>
            <Grid item xs={12}>
              <nav>
                <ul className={classes.footerMenu}>
                  <li>
                    <NavLink to={`/skills`}>
                      {Translate.translate("menuSkills")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/work`}>
                      {Translate.translate("menuWork")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/skilllab`}>Skill Lab</NavLink>
                  </li>
                  <li>
                    <NavLink to={`/about`}>
                      {Translate.translate("menuTeam")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/jobs`}>
                      {Translate.translate("menuJobs")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/contact`} className={classes.lastLink}>
                      {Translate.translate("menuContact")}
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <div className={classes.bottomLinks} style={{ paddingTop: 6 }}>
                <NavLink
                  className={classes.termsLink}
                  to="/algemene-voorwaarden"
                >
                  {Translate.translate("termsConditions")}
                </NavLink>
                <a
                  className={classes.termsLink}
                  href="https://www.instagram.com/skillmedia/"
                >
                  Instagram
                </a>
                <a
                  className={classes.termsLink}
                  href="https://www.facebook.com/skillmedia/"
                >
                  Facebook
                </a>
                <a
                  className={classes.termsLink}
                  href="https://www.linkedin.com/company/skillmedia"
                >
                  LinkedIn
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
