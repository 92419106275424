import {Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {RootState} from "../../../../redux/reducers";
import Header from "../../../partials/Header";
import PageView from "../../../partials/PageView";
import styles from "./Verwarmmetpellets.styles";
import {connect} from "react-redux";
import Footer from "../../../partials/Footer";
import BigText from "../../../text/BigText";
import BigTitle from "../../../text/BigTitle";
import SubTitle from "../../../text/SubTitle";
import StyledButton from "../../../button/StyledButton";
import {NavLink} from "react-router-dom";
import RadiusImg from "../../../image/RadiusImg";
import TopImg from "../../../../assets/img/verwarmmetpellet_banner.png";
import RectangeImg2 from "../../../../assets/img/Rectangle9.png";
import Content1 from "../../../../assets/img/calculatorfunnel.png";
import {ThemeAbstracts} from "../../../../config/MuiTheme";
import SmallTitle from "../../../text/SmallTitle";
import SmallText from "../../../text/SmallText";
import Helmet from "react-helmet";
import Translate from "../../../../Translate/Translate";
import mocupImg from "../../../../assets/img/mockup.png";
import icon from "../../../../assets/img/iconnn.png";
import RectangeImg from "../../../../assets/img/cases-verwarmmetpellets-origin.png";
import mocupImg2 from "../../../../assets/img/mockup.png";
import RectangeImg3 from "../../../../assets/img/Rectangle11.png";

interface State {
}

interface OwnProps {
    classes?: any;
}

interface StateProps {
    windowWidth: number | undefined;
    windowHeight: number | undefined;
    currentLocale: string;
}

type Props = OwnProps & StateProps;

const data = {
    challengeTitle: "Uitdaging",
    challenge: (
        <>
            Bedrijven ontzorgen op vlak van bedrijfspost en -communicatie. Dat is de
            missie van EasyPost. Door fikse besparingen aan te bieden in combinatie
            met een efficiënter postverwerkingsproces, merk je als klant al snel de
            vele voordelen die EasyPost te bieden heeft.
            <br/>
            <br/>
            Hoe ze die fikse besparingen kunnen aanbieden? Dat valt onder het motto:
            samen staan we sterk. Laten we dit even duidelijk maken in een korte
            rekensom:
            <br/>
            <br/>
            <span
                style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                    fontStyle: "italic",
                }}
            >
        Meer klanten = meer post
        <br/>
        Meer post = grotere volumes
        <br/>
        Grotere volumes = volumekorting
        <br/>
        Volumekorting = meer besparing voor alle klanten
      </span>
            <br/>
            <br/>
            De doelstelling was snel afgestemd: meer contactaanvragen genereren zodat
            hun klantenbestand blijft groeien.
            <br/>
            <br/>
            Na het analyseren van hun huidige marketingaanpak viel het snel op dat er
            een bottleneck zat bij de conversies. Dit wil zeggen dat potentiële
            klanten vaak de website bezochten, maar nog niet overtuigd waren om
            contact op te nemen, of een offerte aan te vragen. De drempel was met
            andere woorden te hoog en het absolute voordeel voor prospecten was nog
            niet honderd procent duidelijk.
            <br/>
            <br/>
            We gingen op zoek naar een manier om deze ‘research-fase’ te verwerken in
            de website van EasyPost. Een manier waarbij klanten zelf de proef op de
            som nemen en het absolute voordeel voor hun bedrijf in real-time te zien
            krijgen.
        </>
    ),
    solutionTitle: "Oplossing",
    solution: (
        <>
            Eureka! Na een enkele meetings en brainstormsessies kwam er bij EasyPost
            een prachtig idee boven water: een calculator. Een interactieve
            “berekeningsmachine” die de gebruiker vlot en eenvoudig kan invullen.
            <br/>
            <br/>
            Aan de hand van enkele korte, maar doelgerichte, vragen berekent het
            algoritme hoeveel die gebruiker op jaarbasis kan besparen door een
            samenwerking met EasyPost aan te gaan.
            <br/>
            <br/>
            Door deze ‘tool’ te implementeren, weten gebruikers hun besparing op
            voorhand, wat de bottleneck bij de conversie weghoudt en waardoor het voor
            het sales-team van EasyPost eenvoudiger wordt. Conclusie: minder
            tijdverspilling voor prospecten, meer sales voor EasyPost! Het is dus een
            win-win! We tekenen het hieronder even uit:
        </>
    ),
    calculatorImg: Content1,
    resultTitle: "Resultaat",
    resultText: (
        <>
            Door deze handige ‘tool’ te integreren op de website van EasyPost merkte
            we een aanzienlijke stijging van het aantal leads van maar liefst 291% en
            een stijging van het conversieratio van 0,23% naar 1,73% wat dus wil
            zeggen dat er voor hetzelfde aantal gebruikers, veel meer kwalitatieve
            aanvragen binnenkomen.
            <br/>
            <br/>
            Een hele handige leadgenerator-tool, die zijn investering snel
            terugverdiende dus!
        </>
    ),
};

class Verwarmmetpellets extends Component<Props, State> {
    render() {
        const {classes, windowHeight, windowWidth} = this.props;
        const mobile = !!(windowWidth && windowWidth < 700);
        const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
        const linkContact = "/contact"
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return (
            <PageView>
                <Helmet>
                    <title>
                        Skillmedia | Case EasyPost Calculator - Meer contactaanvragen
                        genereren zodat het klantenbestand blijft groeien
                    </title>
                </Helmet>
                <Header/>
                <Grid
                    container
                    direction="column"
                    className={classes.topContainer}
                    style={{minHeight: theWindowHeight - 70}}
                >
                    <Grid
                        container
                        direction="column"
                        className={classes.topBox}
                        style={{
                            paddingTop: 80,
                            paddingLeft: 10,
                            overflow: "hidden",
                            paddingRight: 10,
                        }}
                    >
                        <Grid container direction="row">
                            <Grid item xs={12} md={12}>
                                <div
                                    className="animate__animated animate__flipInX"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        // alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            maxWidth: 680,
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        <BigTitle
                                            style={{
                                                marginBottom: 0,
                                                paddingBottom: 0,
                                                lineHeight: 1,
                                                maxWidth: 600,
                                                textAlign: "left",
                                            }} className={`animate__animated animate__fadeInUp ${classes.bigTitleTop}`}
                                            bigger={true}
                                        >
                                            Verwarm met pellets
                                        </BigTitle>
                                        <SmallText
                                            style={{
                                                marginBottom: 16,
                                                lineHeight: 1.4,
                                                fontSize: 28,
                                                letterSpacing: "-0.003em",
                                                fontWeight: 450,
                                                wordBreak: "break-word",
                                                color: "rgba(41, 41, 41, 1)",
                                                // "@media only screen and (min-width: 768px)": {
                                                //     fontSize: 24,
                                                // },
                                            }}
                                        >
                                            Een online besparingstool die mensen helpt zoeken naar alternatieve energiebronnen en leads genereert.
                                        </SmallText>
                                        <p className={classes.blogTags}>
                                            strategy - digital marketing - development - graphic design - analytics
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container style={{height: 80}}/>
                        <div
                            style={{width: "100%"}}
                            className={"animate__animated animate__flipInX"}
                        >
                            <RadiusImg
                                src={TopImg}
                                maxWidth={ThemeAbstracts.layout.maxPageWidth}
                                maxHeight={460}
                                noEffect={true}
                                right={true}
                            />
                        </div>
                        <Grid container className={classes.midBoxContainer}
                              style={{
                                  marginTop: 100,
                                  marginBottom: 50,
                                  display: "flex",
                                  paddingBottom: 30,
                                  paddingLeft: 20,
                                  paddingRight: 10,
                                  justifyContent: "center",
                              }}
                              spacing={10}>
                            <Grid item md={6} xs={12} className={classes.blogBox}>
                                <div>
                                    <BigTitle className={classes.goalTitle} style={{
                                        fontSize: "24px",
                                        textAlign: "left",
                                        marginBottom: 40,
                                        // width: 425,
                                        // "@media only screen and (min-width: 768px)": {
                                        //
                                        //     fontSize: "32px",
                                        // },
                                    }}>
                                        {Translate.translate("VewarmSubTitle")}
                                    </BigTitle>
                                    <img src={RectangeImg} style={{width: '100%'}} alt=""/>
                                </div>

                            </Grid>
                            <Grid item md={6} xs={12} className={`${classes.blogBox} "animate__animated animate__flipInX"`}>
                                <div>
                                    <p className={classes.goalText}>
                                        In een periode waarin het thema energieprijzen meer dan ooit brandend actueel is,
                                        zijn besparingen voor de gemiddelde burger meer dan welkom.
                                        Anno 2022 zijn er dan ook interessante alternatieven op de markt voor klassieke
                                        energiebronnen.
                                    </p>
                                    <p className={classes.goalText}>
                                        Een voorbeeld hiervan is de pelletkachel of een pelletketel. Aangezien pellets niet
                                        afhankelijk zijn van
                                        geopolitieke spanningen is de prijs niet zo erg gestegen als die van fossiele
                                        brandstoffen. Daarnaast zijn ze CO2-neutraal, is het een regionale brandstof en is
                                        het comfortabel in gebruik. Ondanks de vele voordelen die het met zich meebrengt,
                                        is verwarmen met pellets nog steeds geen evidentie in de huidige energiemix.
                                    </p>
                                    <p className={classes.goalText}>
                                        <strong>Daarom besloten Stroomop en ÖkoFEN Belgium, twee bedrijven onder 1 dak
                                            gespecialiseerd in pellets, de krachten te bundelen met Skillmedia </strong>en
                                        een interactieve online tool in elkaar te boksen:
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a className={classes.callLink} href="https://verwarmmetpellets.be" target="_blank" style={{ color : "#779ada" , textDecoration: 'none'}}> verwarmmetpellets.be.</a>
                                    </p>
                                    <p className={classes.goalText}>
                                        <strong>Doel</strong>: op een aantrekkelijke en interactieve manier mensen ervan
                                        bewust maken dat ze konden besparen op hun energiefactuur.
                                    </p>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        className={classes.midContainer}
                        style={{
                            maxHeight: 820,
                            backgroundColor: 'black',
                        }}

                    >
                        <Grid item xs={12} md={12}>
                            <div
                                className="animate__animated animate__flipInX"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // alignItems: "center",
                                }}
                            >
                                <div className={classes.midCenterBlock}
                                >
                                    <BigTitle
                                        style={{
                                            marginBottom: 20,
                                            paddingBottom: 0,
                                            lineHeight: 1,
                                            fontSize: 28,
                                            textAlign: "center",
                                            color: "white",
                                            // "@media only screen and (min-width: 768px)": {
                                            //     fontSize: 28,
                                            // },
                                        }}
                                        bigger={true}
                                    >
                                        Hoe Verwarmmetpellets.be over de samenwerking denkt
                                    </BigTitle>
                                    <SmallText
                                        style={{
                                            marginBottom: 20,
                                            lineHeight: 1.4,
                                            fontSize: 18,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 450,
                                            wordBreak: "break-word",
                                            color: "white",
                                            textAlign: "center",
                                        }}
                                    >
                                        “We zijn zeer tevreden over de website en de workflow ervan. Het is een tool
                                        die we graag willen behouden en eventueel uitbreiden in de toekomst.”
                                    </SmallText>
                                    <BigTitle style={{
                                        marginBottom: -15,
                                        paddingBottom: 0,
                                        lineHeight: 1,
                                        fontSize: 24,
                                        textAlign: "center",
                                        color: "white",
                                    }}>
                                        Eva Decorte
                                    </BigTitle>
                                    <p style={{
                                        marginBottom: 10,
                                        paddingBottom: 0,
                                        lineHeight: 1,
                                        fontSize: 14,
                                        textAlign: "center",
                                        color: "white",
                                    }}>
                                        verantwoordelijke marketing</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {/*add 1 container 1 row and 2 cols with md = 6 xs = 12*/}

                    <Grid
                        container
                        direction="column"
                        className={classes.middleBox}
                        style={{minHeight: theWindowHeight - 70}}
                    >
                        <Grid container className={ classes.midBoxContainer}
                              style={{
                                  marginTop: 150,
                                  paddingTop: 30,
                                  display: "flex",
                                  paddingBottom: 30,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  justifyContent: "center",
                              }}
                              spacing={4}>
                            <Grid item xs={12} md={6} className={classes.blogBoxCenter}>
                                <div
                                    className={"animate__animated animate__bounceInUp"}
                                >
                                    <BigTitle style={{
                                        marginBottom: 30,
                                        paddingBottom: 0,
                                        lineHeight: 1,
                                        fontSize: 32,
                                        textAlign: "left",
                                        // "@media (min-width: 768px)": {
                                        //     fontSize: 32,
                                        // },
                                    }}
                                        bigger={true}>
                                        Bereken zelf hoeveel je bespaart
                                    </BigTitle>
                                    <SmallText
                                        style={{
                                            marginBottom: 20,
                                            lineHeight: 1.4,
                                            fontSize: 18,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 450,
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                        }}
                                    >
                                        <strong>Gezien de hoge energieprijzen zijn mensen massaal op zoek naar
                                            alternatieven. </strong>
                                        Om alvast wat eerste info te verschaffen kunnen mensen in de tool hun huidig
                                        verbruik, aantal gezinsleden,
                                        oppervlakte, etc. doorgeven. Daarna geven ze aan waarin ze interesse hebben en
                                        op
                                        welke manier ze gecontacteerd willen worden.
                                    </SmallText>
                                    <SmallText
                                        style={{
                                            marginBottom: 20,
                                            lineHeight: 1.4,
                                            fontSize: 18,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 450,
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                        }}
                                    >
                                        <strong>Het grote voordeel van deze tool is dat deze stapsgewijs werd
                                            ontwikkeld. </strong>
                                        Hierdoor laat de gebruiker beetje bij beetje informatie achter zonder dat het
                                        als
                                        een grote opgave voelt.
                                    </SmallText>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.blogBox}>
                                <div
                                >
                                    <img src={mocupImg} style={{width: "100%"}} alt=""/>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.midBoxContainer} style={{
                            fontSize: 20,
                            paddingTop: 30,
                            display: "flex",
                            paddingBottom: 30,
                            paddingLeft: 10,
                            paddingRight: 10,
                            justifyContent: "center",
                        }} spacing={4}>
                            <Grid item xs={12} className={classes.blogBox} md={6}>
                                <div
                                    style={{
                                        width: "100%",
                                        // maxWidth: 968,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        color: "white",
                                    }}
                                >
                                    <img src={icon} style={{width: "75%"}} alt=""/>
                                </div>
                            </Grid>
                            <Grid item xs={12} className={classes.blogBox} md={6}>
                                <div
                                    className={"animate__animated animate__bounceInUp"}
                                    style={{
                                        width: "100%",
                                        maxWidth: 968,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <BigTitle
                                        style={{
                                            marginBottom: 20,
                                            paddingBottom: 0,
                                            lineHeight: 1,
                                            maxWidth: 600,
                                            fontSize: 32,
                                            textAlign: "left",
                                        }}
                                        bigger={true}
                                    >
                                        Interactief en laagdrempelig
                                    </BigTitle>
                                    <SmallText
                                        style={{
                                            marginBottom: 33,
                                            lineHeight: 1.4,
                                            fontSize: 18,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 450,
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                        }}
                                    >
                                        Bij het ontwerpen werd er door ons creative team uiteraard rekening gehouden met
                                        een
                                        mix van de huisstijlen van de twee bedrijven.
                                        Daarnaast komt ook het gebruik van gerichte iconen terug, een speelse visuele
                                        weergave van de behandelde topics. Door met verschillende stappen te werken,
                                        waarbij
                                        er per stap niet al te veel informatie moet worden ingevuld, is de tool
                                        bovendien
                                        zeer gebruiksvriendelijk.
                                    </SmallText>
                                    <BigTitle
                                        style={{
                                            marginBottom: 20,
                                            paddingBottom: 0,
                                            lineHeight: 1,
                                            maxWidth: 600,
                                            fontSize: 32,
                                            textAlign: "left",
                                        }}
                                        bigger={true}
                                    >
                                        De juiste personen bereiken
                                    </BigTitle>
                                    <SmallText
                                        style={{
                                            marginBottom: 16,
                                            lineHeight: 1.4,
                                            fontSize: 18,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 450,
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                        }}
                                    >
                                        Naast de webtool is er ook een <strong>radiocampagne </strong> en <strong>digitale
                                        campagne</strong> opgezet om mensen bewust te maken van dit ecologisch
                                        alternatief
                                        voor de warmtepomp. De tool verwarmmetpellets.be was de
                                        landingspagina voor beide campagnes.
                                    </SmallText>
                                </div>
                            </Grid>

                        </Grid>
                        <Grid container className={classes.midBoxContainer}
                              style={{
                                  paddingTop: 30,
                                  display: "flex",
                                  paddingBottom: 30,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  justifyContent: "center",
                              }}
                              spacing={4}>
                            <Grid item className={classes.blogBox} xs={12} md={6}>
                                <div
                                    style={{
                                        width: "100%",
                                        maxWidth: 968,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <BigTitle
                                        style={{
                                            marginBottom: 30,
                                            paddingBottom: 0,
                                            lineHeight: 1,
                                            fontSize: 32,
                                            textAlign: "left",
                                            // "@media only screen and (min-width: 768px)": {
                                            //     fontSize: 24,
                                            // },
                                        }}
                                        bigger={true}
                                    >
                                        Hartverwarmende resultaten
                                    </BigTitle>
                                    <SmallText
                                        style={{
                                            marginBottom: 20,
                                            lineHeight: 1.4,
                                            fontSize: 18,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 450,
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                        }}
                                    >
                                        In de periode van <strong>kwartaal 1 - 2022, hebben er al 17.285 mensen de website
                                        bezocht.</strong> Door de tool aandachtig te monitoren via Hotjar, hebben we de mogelijkheid om
                                        gemakkelijk en efficiënt de tool en website bij te sturen via optimalisaties en A/B tests
                                    </SmallText>
                                    <SmallText
                                        style={{
                                            marginBottom: 20,
                                            lineHeight: 1.4,
                                            fontSize: 18,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 450,
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        Via Hotjar genereer je heatmaps van bepaalde pagina’s op je website.
                                        Deze heatmaps geven inzicht in waar bezoekers scrollen, bewegen met hun cursor
                                        en
                                        uiteindelijk ook klikken.
                                        Het geeft duidelijk inzicht in het bezoekersgedrag. Hierdoor hebben we de
                                        mogelijkheid om gerichte aanpassingen door
                                        te voeren op de website op basis van data.
                                    </SmallText>
                                    <SmallText
                                        style={{
                                            marginBottom: 20,
                                            lineHeight: 1.4,
                                            fontSize: 18,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 450,
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                        }}
                                    >
                                        We zijn blij ons steentje te hebben bijgedragen in de zoektocht naar
                                        milieuvriendelijke brandstoffen. Zelf ook bekijken of verwarmen via pellets
                                        financieel interessant is?
                                        Bekijk alvast even de tool, we bevelen dit warm aan! ;)
                                    </SmallText>
                                </div>
                            </Grid>
                            <Grid item className={classes.blogBox} xs={12} md={6}>
                                <div
                                    style={{
                                        width: "100%",
                                        maxWidth: 968,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        color: "white",
                                    }}
                                >
                                    <img src={RectangeImg2} style={{width: "100%"}} alt=""/>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.endBoxContainer}
                              style={{
                                  marginTop: 100,
                                  marginBottom: 50,
                                  display: "flex",
                                  paddingBottom: 30,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  justifyContent: "center",
                              }}>
                            <Grid item xs={12} md={4} className={classes.blogBoxEnd}>
                                <div
                                    className={classes.blogBoxEndImage}
                                >
                                    <img src={RectangeImg3} style={{
                                        width: "100%",

                                    }} alt="RectangeImg3"/>

                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.blogBoxEnd}>
                                <div
                                    style={{ textAlign: "center"}}
                                >
                                    <BigTitle
                                        style={{
                                            marginBottom: 30,
                                            paddingBottom: 0,
                                            lineHeight: 1,
                                            fontSize: 48,
                                            textAlign: "center",
                                            // "@media only screen and (min-width: 768px)": {
                                            //     fontSize: 24,
                                            // },
                                        }}
                                        bigger={true}
                                    >
                                        Nieuwsgierig wat we
                                        voor jou kunnen betekenen?
                                    </BigTitle>
                                    <SmallText
                                        style={{
                                            marginBottom: 20,
                                            lineHeight: 1.4,
                                            fontSize: 28,
                                            letterSpacing: "-0.003em",
                                            fontWeight: 500,
                                            wordBreak: "break-word",
                                            textAlign: "center",
                                        }}
                                    >
                                        Plan vrijblijvend een afspraak in met Jonas
                                    </SmallText>
                                    <a className={classes.callLinkButton} href={linkContact}>
                                        <button className={classes.buttonEnd}>
                                            Plan een afspraak in
                                        </button>
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*container contain 1 row and contain 2 columns 1 image and 1 text with md=6 and md=6 and ms=12*/}

                    {/*container with 2 columns image right and text image = 4 text = 8*/}
                </Grid>
                <Footer/>
            </PageView>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        windowWidth: state.app.windowWidth,
        windowHeight: state.app.windowHeight,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Verwarmmetpellets));
