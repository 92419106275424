import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../../redux/reducers";
import Header from "../../../partials/Header";
import PageView from "../../../partials/PageView";
import styles from "./Ginsonline.styles";
import { connect } from "react-redux";
import Footer from "../../../partials/Footer";
import BigText from "../../../text/BigText";
import BigTitle from "../../../text/BigTitle";
import SubTitle from "../../../text/SubTitle";
import StyledButton from "../../../button/StyledButton";
import { NavLink } from "react-router-dom";
import RadiusImg from "../../../image/RadiusImg";
import TopImg from "../../../../assets/img/case_ginsonline_banner.png";
import Content1 from "../../../../assets/img/case_ginsonline_content_1.png";
import Content2 from "../../../../assets/img/case_ginsonline_content_2.png";
import Content3 from "../../../../assets/img/case_ginsonline_content_3.png";
import { ThemeAbstracts } from "../../../../config/MuiTheme";
import SmallTitle from "../../../text/SmallTitle";
import SmallText from "../../../text/SmallText";
import Helmet from "react-helmet";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Ginsonline extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>
            Skillmedia | Case ginsonline - Kostenefficiënt adverteren voor
            webshops
          </title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 680,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <BigTitle
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        lineHeight: 1,
                        maxWidth: 600,
                        textAlign: "left",
                      }}
                      bigger={true}
                    >
                      Ginsonline.be.
                    </BigTitle>
                    <SmallText
                      style={{
                        maxWidth: 500,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      Kostenefficiënt adverteren voor ginsonline.be
                    </SmallText>
                    <p className={classes.blogTags}>
                      strategy - digital marketing - analytics
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <div
              style={{ width: "100%" }}
              className={"animate__animated animate__flipInX"}
            >
              <RadiusImg
                src={TopImg}
                maxWidth={ThemeAbstracts.layout.maxPageWidth}
                maxHeight={460}
                noEffect={true}
              />
            </div>
            <Grid container style={{ height: 100 }} />
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  Een cocktail van Google Ads
                </SmallTitle>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  Beeld je even in: het is een zomerse dag, je zit buiten op je
                  terras, de vogeltjes fluiten, het is heerlijk genieten, maar
                  er ontbreekt nog iets… correct! Een heerlijk glas gin-tonic.
                  Dat is waar wij aan dachten toen ‘Gins Online’ bij ons kwam
                  aankloppen.
                </SmallText>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  "Gins Online" is een webshop met een enorm aanbod van, ja
                  hoor, gins! Het maakt niet uit welke gin je zoekt, de kans is
                  groot dat zij hem voor jou hebben klaarstaan. Natuurlijk was
                  dit geen meeting om gins te laten proeven. Ze hadden een
                  uitdaging: meer online sales genereren.
                </SmallText>
                <SmallText
                  style={{
                    maxWidth: 600,
                    marginBottom: 16,
                    lineHeight: 1.4,
                    fontSize: 21,
                    letterSpacing: "-0.003em",
                    fontWeight: 400,
                    wordBreak: "break-word",
                    color: "rgba(41, 41, 41, 1)",
                  }}
                >
                  Sinds een tijdje waren ze zelf met Google Ads begonnen, maar
                  toch benieuwd of dit nog beter kon. Ze waren dus op zoek naar
                  een partij die hen meer inzicht kon geven in hun Google Ads
                  account en hen daarnaast kon helpen met het verhogen van de
                  online sales via dit kanaal.
                </SmallText>
                <Grid container direction="row">
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content1} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content2} width="100%" alt="content1" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <img src={Content3} width="100%" alt="content1" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#e9e9e9",
              fontSize: 20,
              marginTop: 80,
              paddingTop: 60,
              paddingBottom: 60,
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
            }}
          >
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  Een goede mix tussen kosten en opbrengsten?
                </SmallTitle>
                <div style={{ lineHeight: 1.4 }}>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Om te starten met deze uitdaging hadden we een concreet plan
                    van aanpak nodig. We gingen van start met het analyseren van
                    het huidige account. Al snel vielen ons enkele
                    verbeterpunten op: weinig efficiënte zoektermen. Hierdoor
                    betaalden ze te veel per klik op hun advertenties.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Het grootste probleem werd meteen gesignaleerd: er werd te
                    veel geld uitgegeven aan zaken die efficiënter konden. Onze
                    oplossing? Actief de kost per conversie gaan verlagen door
                    het optimaliseren van de kwaliteitsscores van de
                    zoekwoorden. Klinkt dit Chinees? Geen zorgen. Dit wil zeggen
                    dat we de relevantie van de Google Ads campagnes hebben
                    verhoogd. We tonen de mensen wat ze willen zien. Hierdoor
                    worden we beloond door Google en wordt het goedkoper om te
                    adverteren.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Als kers op de taart, of als garnituur in de gin, hebben we
                    de volledige website uitgepluisd via Google Analytics om
                    nieuwe opportuniteiten voor de Google Ads campagnes bloot te
                    leggen.{" "}
                  </p>
                </div>
                <SmallTitle
                  style={{
                    marginTop: 20,
                    lineHeight: 1.3,
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  Tijd voor een aperitief
                </SmallTitle>
                <div style={{ lineHeight: 1.4, marginBottom: 40 }}>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Het harde werk was geklaard, nu was het tijd om even
                    achterover te leunen, een gin in te schenken en de
                    resultaten te analyseren.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Door het optimaliseren van de kost per conversie en het
                    verhogen van de kwaliteitsscores van de zoekwoorden, hebben
                    we er voor gezorgd dat de omzet van Gins Online in het
                    eerste jaar met wel +125% was gestegen. Daarnaast door
                    continue optimalisaties uit te voeren, stegen we in het
                    volgende jaar met nog eens 30%.
                  </p>
                  <p
                    style={{
                      maxWidth: 600,
                      marginBottom: 16,
                      lineHeight: 1.4,
                      fontSize: 21,
                      letterSpacing: "-0.003em",
                      fontWeight: 400,
                      wordBreak: "break-word",
                      color: "rgba(41, 41, 41, 1)",
                    }}
                  >
                    Met trots kunnen we zeggen dat de uitdaging succesvol werd
                    voltooid!
                  </p>
                </div>
                <iframe
                  title="ginsonline"
                  className={classes.videoH}
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/yeu7fRjhhBQ"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <NavLink
              to="/work"
              style={{ marginTop: 60, textDecoration: "none" }}
            >
              <StyledButton
                className={"animate__animated animate__bounceInUp"}
                style={{ marginTop: 60 }}
              >
                MEER CASES
              </StyledButton>
            </NavLink>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              Contacteer ons
            </BigTitle>
            <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
              Ontdek hoe Skillmedia je helpt groeien
            </SubTitle>
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <StyledButton inverse={true}>LATEN WE SAMENWERKEN</StyledButton>
            </NavLink>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Ginsonline));
