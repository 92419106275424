import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    goalHolder: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    goalList: {
      listStyleType: "none",
      marginLeft: 0,
      paddingLeft: 0,
      maxWidth: 430,
      "& li": {
        fontSize: 44,
        fontWeight: "bold",
        letterSpacing: -1,
        borderBottom: "2px solid black",
        marginBottom: 26,
        [theme.breakpoints.down("lg")]: {
          fontSize: 42,
        },
        [theme.breakpoints.down("md")]: {
          fontSize: 38,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 30,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 24,
        },

        "& a": {
          position: "relative",
          display: "inline-block",
          textDecoration: "none",
          color: "inherit",
          background:
            "linear-gradient(to right, rgb(103, 195, 234,1), rgba(105, 141, 200, 1) 50%, black 50%);",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundSize: "200% 100%",
          backgroundPosition: "100%",
          transition: "background-position 275ms ease",
          "&:hover": {
            "background-position": "0 100%",
          },
        },
      },
    },
    imgHolder: {
      position: "relative",
      maxWidth: 900,
      width: "100%",
      height: "100%",
      minHeight: 480,
      [theme.breakpoints.down("sm")]: {
        minHeight: 360,
      },
      overflow: "hidden",
    },
    imgRight: {
      position: "absolute",
      right: 140,
      top: 100,
      width: 320,
      height: 320,
      [theme.breakpoints.down("lg")]: {
        width: 320,
        height: 320,
        right: 80,
        top: 140,
      },
      [theme.breakpoints.down("md")]: {
        width: 320,
        height: 320,
        right: 50,
        top: 80,
      },
      [theme.breakpoints.down("sm")]: {
        width: 240,
        height: 240,
        left: 100,
        top: 80,
      },
      [theme.breakpoints.down("xs")]: {
        width: 240,
        height: 240,
        left: 60,
        top: 80,
      },
    },
    imgLeft: {
      position: "absolute",
      left: 0,
      top: 0,
      width: 350,
      height: 500,
      [theme.breakpoints.down("lg")]: {
        width: 350,
        height: 500,
        left: 0,
        top: 0,
      },
      [theme.breakpoints.down("md")]: {
        width: 310,
        height: 450,
        left: 0,
        top: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: 240,
        height: 400,
        left: 0,
        top: 0,
      },
      [theme.breakpoints.down("xs")]: {
        width: 240,
        height: 400,
        left: 0,
        top: 0,
      },
    },
  });

export default styles;
