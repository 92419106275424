import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./SkillLab.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import HugeTitle from "../../text/HugeTitle";
import BigText from "../../text/BigText";
import classNames from "classnames";
import Blog1 from "../../../assets/img/blogitem5.jpg";
import Blog2 from "../../../assets/img/blogitem6.jpg";
import StyledSimpleButton from "../../button/StyledSimpleButton";
import BigTitle from "../../text/BigTitle";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import { NavLink } from "react-router-dom";
import SkillBlogItem from "./SkillBlogItem";
import Helmet from "react-helmet";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class SkillLab extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    const blogCount = 4;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | Skill Lab en agency nieuws</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{ paddingTop: 80, paddingLeft: 10, paddingRight: 10 }}
          >
            <div
              className="animate__animated animate__flipInX"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <HugeTitle
                style={{ marginBottom: 0, paddingBottom: 0, lineHeight: 0.8 }}
              >
                Skill Lab.
              </HugeTitle>
              <BigText style={{ maxWidth: 460, marginTop: 0, paddingTop: 0 }}>
                Boost your knowledge and discover the latest agency news.
              </BigText>
            </div>

            <Grid
              container
              direction="row"
              className={classes.midContainer}
              style={{
                alignItems: "flex-start",
                marginTop: 80,
                marginBottom: 100,
              }}
            >
              <Grid container direction="row" style={{ overflow: "hidden" }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={classNames(
                    classes.blogBoxLeft,
                    "animate__animated animate__fadeInUp",
                  )}
                >
                  <SkillBlogItem
                    title="Google Ads breidt phrase match uit"
                    text="Hoe Google het algoritme en de producten verbeterd om beter te kunnen beantwoorden aan de zoekintentie van deze klanten. "
                    link="/skilllab/google-ads-breidt-phrase-match-uit-voor-sterkere-customer-alignment"
                    img={Blog2}
                    right={true}
                    date="12/02/2021"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  className={classNames(
                    classes.blogBoxCenter,
                    "animate__animated animate__fadeInUp",
                  )}
                >
                  <SkillBlogItem
                    title="iOS 14 update en Facebook ads"
                    text="Hoe iOS 14 een impact heeft op je Facebook advertenties en wat je er aan kunt doen."
                    link="/skilllab/hoe-ios14-een-impact-heeft-op-je-facebook-advertenties"
                    img={Blog1}
                    right={true}
                    date="05/02/2021"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={classNames(
                    classes.blogBoxRight,
                    "animate__animated animate__fadeInUp",
                  )}
                ></Grid>
              </Grid>
              {blogCount > 6 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <StyledSimpleButton style={{ marginTop: 60 }}>
                    Bekijk meer
                  </StyledSimpleButton>
                </Grid>
              )}
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 100 }}
              >
                <BigTitle
                  style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
                  variant="h2"
                >
                  Contacteer ons
                </BigTitle>
                <SubTitle
                  style={{ marginTop: 0, paddingTop: 0, width: "100%" }}
                >
                  Ontdek hoe Skillmedia je helpt groeien
                </SubTitle>
                <NavLink to="/contact" style={{ textDecoration: "none" }}>
                  <StyledButton inverse={true}>
                    LATEN WE SAMENWERKEN
                  </StyledButton>
                </NavLink>
                <Grid container style={{ height: 120 }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(SkillLab));
