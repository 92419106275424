import { Button, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  styledBtn: {
    fontSize: 18,
    fontFamily: "TTCommonsExtraBold, sans serif",
    color: "black",
    fontWeight: "bold",
    textTransform: "none",
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: 25,
    marginTop: 20,
    marginBottom: 20,
    minWidth: 220,
    boxShadow: "none",
    border: "2px solid black",

    "&::before": {
      position: "absolute",
      display: "block",
      width: "100%",
      height: "100%",
      borderRadius: 25,
      content: "''",
      border: "solid 3px #6898cf",
      transform: "scaleX(0)",
      transition: "transform 250ms ease-in-out",
    },

    "&:hover": {
      border: "2px solid rgba(0,0,0,0)",
      boxShadow: "none",
      backgroundColor: "rgba(0,0,0,0)",
      color: "#6898cf",
    },
    "&:hover::before": {
      transform: "scaleX(1)",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  inverseBtn: {
    color: "white !important",
    background:
      "linear-gradient(90deg,rgb(103, 195, 234,1) 0%,rgba(105, 141, 200, 1) 70%)",
    border: "none",
    fontSize: 18,
    fontFamily: "TTCommonsExtraBold, sans serif",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: 25,
    marginTop: 20,
    marginBottom: 20,
    minWidth: 220,
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  whiteBtn: {
    fontSize: 18,
    fontFamily: "TTCommonsExtraBold, sans serif",
    color: "white",
    fontWeight: "bold",
    textTransform: "none",
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: 25,
    marginTop: 20,
    marginBottom: 20,
    minWidth: 220,
    boxShadow: "none",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "rgba(105, 141, 200, 1)",

    "&:hover": {
      background:
        "linear-gradient(90deg,rgba(103, 195, 234,1) 0%,rgba(105, 141, 200, 1) 70%)",
    },
  },
}));

interface OwnProps {
  className?: any;
  inverse?: boolean;
  style?: any;
  children?: any;
  white?: boolean;
  onClick?: any;
}

type Props = OwnProps;

const StyledButton = (props: Props) => {
  const classes = useStyles();
  const { inverse, className, style, white, ...restOfProps } = props;
  return (
    <Button
      variant="contained"
      className={classNames(
        props.inverse
          ? classes.inverseBtn
          : white
          ? classes.whiteBtn
          : classes.styledBtn,
        props.className ? props.className : null,
      )}
      style={style ? style : null}
      {...restOfProps}
    >
      {props.children}
    </Button>
  );
};

export default StyledButton;
