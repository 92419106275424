import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Strategy.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import BigText from "../../text/BigText";
import BigTitle from "../../text/BigTitle";
import Strategy1 from "../../../assets/img/sk_brandstrategy.jpg";
import Strategy2 from "../../../assets/img/sk_mediaplanning.jpg";
import Strategy3 from "../../../assets/img/sk_marketingstrategy.jpg";
import Strategy4 from "../../../assets/img/sk_contentstrategy.jpg";
import Strategy5 from "../../../assets/img/sk_marketingmonthly.jpg";
import StrategyBlock from "./StrategyBlock";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import { NavLink } from "react-router-dom";
import SkillsMenu from "./SkillsMenu";
import Helmet from "react-helmet";
import Translate from "../../../Translate/Translate";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Strategy extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | Stragegy and consultancy</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <BigTitle
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                      lineHeight: 1,
                      maxWidth: 400,
                      textAlign: "left",
                    }}
                  >
                    Strategy &amp; consultancy.
                  </BigTitle>
                  <BigText
                    style={{ maxWidth: 460, marginTop: 0, paddingTop: 0 }}
                  >
                    {Translate.translate("strategySubtitle")}
                  </BigText>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <SkillsMenu />
              </Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <StrategyBlock
              right={true}
              src={Strategy1}
              title={Translate.translate("strategyBlockTitle1")}
              text={Translate.translate("strategyBlockText1")}
              topText={Translate.translate("strategyBlockTopText1")}
            />
            <StrategyBlock
              src={Strategy2}
              title={Translate.translate("strategyBlockTitle2")}
              text={Translate.translate("strategyBlockText2")}
            />
            <StrategyBlock
              right={true}
              src={Strategy3}
              title={Translate.translate("strategyBlockTitle3")}
              text={Translate.translate("strategyBlockText3")}
            />
            <StrategyBlock
              src={Strategy4}
              title={Translate.translate("strategyBlockTitle4")}
              text={Translate.translate("strategyBlockText4")}
            />
            <StrategyBlock
              right={true}
              src={Strategy5}
              title={Translate.translate("strategyBlockTitle5")}
              text={Translate.translate("strategyBlockText5")}
            />
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <BigTitle
              style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              variant="h2"
            >
              {Translate.translate("contactUs")}
            </BigTitle>
            <SubTitle style={{ marginTop: 0, paddingTop: 0, width: "100%" }}>
              {Translate.translate("contactGrow")}
            </SubTitle>
            <NavLink to="/contact" style={{ textDecoration: "none" }}>
              <StyledButton inverse={true}>
                {Translate.translate("contactLetsWork")}
              </StyledButton>
            </NavLink>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Strategy));
