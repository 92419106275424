import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  bigText: {
    fontSize: 26,
    lineHeight: 1.1,
    [theme.breakpoints.down("lg")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      lineHeight: 1.1,
    },
    textAlign: "left",
  },
}));

const BigText = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.bigText}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default BigText;
