import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    bottomFormHolder: {
      justifyContent: "center",
      alignItems: "center",
    },
    bottomFormInverse: {
      backgroundImage: "none",
      backgroundColor: "white",
    },
    bottomFormSection: {
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      justifyContent: "flex-start",
      alignItems: "center",
      color: "white",
    },
    bottomFormSectionInverse: {
      color: "black",
    },
    leftField: {
      paddingRight: 16,
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
      },
    },
    rightField: {
      paddingLeft: 16,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
    rightBut: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
  });

export default styles;
