import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import CustomTextField from "../../input/CustomTextField";
import MidTitle from "../../text/MidTitle";
import StyledButton from "../../button/StyledButton";
import styles from "./ContactForm.styles";
import SimpleText from "../../text/SimpleText";
import classNames from "classnames";
import CustomTextFieldBlack from "../../input/CustomTextFieldBlack";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import Loader from "../../loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import Translate from "../../../Translate/Translate";

interface State {
  lastName: string | undefined;
  firstName: string | undefined;
  email: string | undefined;
  tel: string | undefined;
  question: string | undefined;
  emailSuccess: boolean;
  sendingMail: boolean;
  captcha: string | undefined;
}

interface OwnProps {
  classes?: any;
  title: string;
  subTitle?: string;
  sendText: string;
  inverse?: boolean;
  id?: string;
  style?: any;
  minimized?: boolean;
}

type Props = OwnProps;

class ContactForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      lastName: undefined,
      firstName: undefined,
      email: undefined,
      tel: undefined,
      question: undefined,
      emailSuccess: false,
      sendingMail: false,
      captcha: undefined,
    };
  }

  recaptchaRef: any = React.createRef();

  onChange = (value: any) => {
    this.setState({
      captcha: value,
    });
  };

  sendForm = () => {
    const { minimized } = this.props;
    const { lastName, firstName, email, tel, question, captcha } = this.state;
    if (minimized && (!firstName || !firstName.length || !tel || !tel.length)) {
      return swal(
        "Probleem",
        "Niet alle velden zijn correct ingevuld",
        "error",
      );
    }
    if (
      !minimized &&
      (!lastName ||
        !lastName.length ||
        !firstName ||
        !firstName.length ||
        !email ||
        !email.length ||
        !tel ||
        !tel.length ||
        !question ||
        !question.length)
    ) {
      return swal(
        "Probleem",
        "Niet alle velden zijn correct ingevuld",
        "error",
      );
    }

    var templateParams = {
      from_name: firstName,
      first_name: firstName,
      last_name: lastName || "",
      email: email || "",
      tel: tel,
      question: question || "",
      reply_to: "accounts@skillmedia.be",
      "g-recaptcha-response": captcha,
    };
    this.setState(
      {
        sendingMail: true,
      },
      () => {
        emailjs
          .send(
            "service_6wiupy1",
            "template_6xdrr9g",
            templateParams,
            "user_Sq4BqO28pvNWoIv8S1j6i",
          )
          .then(
            () => {
              this.setState({
                sendingMail: false,
                emailSuccess: true,
              });
            },
            (error) => {
              this.setState(
                {
                  sendingMail: false,
                },
                () => {
                  swal(
                    "Probleem",
                    "Er is een probleem opgetreden tijdens het verzenden",
                    "error",
                  );
                },
              );
            },
          );
      },
    );
  };

  onInputChange = (field: string, e: any) => {
    this.setState({
      ...this.state,
      [field]: e.target.value,
    });
  };

  render() {
    const { classes, sendText, inverse, id, style, minimized } = this.props;
    const {
      lastName,
      firstName,
      email,
      tel,
      question,
      emailSuccess,
      sendingMail,
      captcha,
    } = this.state;
    const TextComponent = inverse ? CustomTextFieldBlack : CustomTextField;
    return (
      <Grid
        id={id || "contact"}
        container
        direction="column"
        className={classNames(
          classes.bottomFormHolder,
          inverse ? classes.bottomFormInverse : null,
        )}
        style={style || {}}
      >
        {emailSuccess && (
          <Grid
            container
            className={classNames(
              classes.bottomFormSection,
              inverse ? classes.bottomFormSectionInverse : null,
            )}
            direction="column"
          >
            <MidTitle
              style={{ marginTop: 120, marginBottom: 0, color: "black" }}
            >
              <span className="bedankt">Bedankt</span>
            </MidTitle>
            <SimpleText style={{ marginBottom: 100, color: "black" }}>
              Bedankt om dit formulier te gebruiken. We beantwoorden uw vraag zo
              snel mogelijk!
            </SimpleText>
          </Grid>
        )}
        {!emailSuccess && (
          <Grid
            container
            className={classNames(
              classes.bottomFormSection,
              inverse ? classes.bottomFormSectionInverse : null,
            )}
            direction="column"
          >
            <Grid container direction="column">
              <TextComponent
                key="firstname"
                required={true}
                id="firstName"
                name="firstName"
                fullWidth
                label={
                  minimized
                    ? Translate.translate("contactLastname")
                    : Translate.translate("contactFirstname")
                }
                autoComplete="given-name"
                placeholder={Translate.translate("contactFirstname")}
                value={firstName || ""}
                margin="normal"
                onChange={(e) => this.onInputChange("firstName", e)}
                variant="outlined"
              />
              {!minimized && (
                <TextComponent
                  key="lastname"
                  required={true}
                  id="name"
                  name="name"
                  fullWidth
                  label={Translate.translate("contactLastname")}
                  autoComplete="family-name"
                  placeholder={Translate.translate("contactLastname")}
                  value={lastName || ""}
                  margin="normal"
                  onChange={(e) => this.onInputChange("lastName", e)}
                  variant="outlined"
                />
              )}
              <TextComponent
                required={true}
                id="tel"
                name="tel"
                fullWidth
                label={
                  minimized
                    ? Translate.translate("contactTelEmail")
                    : Translate.translate("contactTel")
                }
                autoComplete="none"
                placeholder={Translate.translate("contactTel")}
                value={tel || ""}
                margin="normal"
                onChange={(e) => this.onInputChange("tel", e)}
                variant="outlined"
              />
              {!minimized && (
                <TextComponent
                  type="email"
                  required={true}
                  id="email"
                  name="email"
                  fullWidth
                  label={Translate.translate("contactEmail")}
                  autoComplete="email"
                  placeholder={Translate.translate("contactEmail")}
                  value={email || ""}
                  margin="normal"
                  onChange={(e) => this.onInputChange("email", e)}
                  variant="outlined"
                />
              )}
              {!minimized && (
                <TextComponent
                  required={true}
                  id="question"
                  name="question"
                  fullWidth
                  label={Translate.translate("contactMessage")}
                  autoComplete="none"
                  placeholder={Translate.translate("contactMessage")}
                  value={question || ""}
                  margin="normal"
                  onChange={(e) => this.onInputChange("question", e)}
                  variant="outlined"
                  multiline
                  rows={3}
                />
              )}
            </Grid>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey="6LdnsvwZAAAAAMO0ly5Vh6N_BVTjjfuRQ1ZgxE8Y"
                onChange={this.onChange}
              />
            </div>
            <div className={classes.rightBut}>
              {captcha && (
                <StyledButton
                  onClick={() => this.sendForm()}
                  inverse={inverse || false}
                >
                  {sendText}
                </StyledButton>
              )}
            </div>
            <Grid container style={{ height: 120 }}></Grid>
          </Grid>
        )}
        {(sendingMail && <Loader />) || null}
      </Grid>
    );
  }
}

export default withStyles(styles)(ContactForm);
