import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  simpleText: {
    fontSize: 22,
    [theme.breakpoints.down("lg")]: {
      fontSize: 19,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    marginBottom: 16,
    textAlign: "center",
  },
}));

const SimpleText = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.simpleText}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default SimpleText;
