import { withStyles } from "@material-ui/core";
import React, { Component } from "react";
import styles from "./BigCaroussel.styles";

interface State {
  sliding: boolean;
  slideCount: number;
}

interface OwnProps {
  classes?: any;
  images: any[];
  width: number;
  height: number;
  imageCountVisible: number;
  intervalSeconds?: number;
  links?: string[];
}

type Props = OwnProps;

class BigCaroussel extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sliding: false,
      slideCount: 0,
    };
  }

  slideInterval: any = undefined;

  slideItems = () => {
    const { images, imageCountVisible } = this.props;
    const { slideCount } = this.state;
    const maxCount = images.length - imageCountVisible;
    this.setState({
      slideCount: slideCount < maxCount ? slideCount + 1 : 0,
    });
  };

  startCarousselSlide = () => {
    const { intervalSeconds } = this.props;
    this.setState(
      {
        sliding: true,
      },
      () => {
        this.slideInterval = setInterval(
          () => {
            this.slideItems();
          },
          intervalSeconds ? intervalSeconds * 1000 : 4000,
        );
      },
    );
  };

  componentDidMount = () => {
    const { images, imageCountVisible } = this.props;
    if (images.length > imageCountVisible) {
      this.startCarousselSlide();
    }
  };

  componentWillUnmount = () => {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
      this.slideInterval = undefined;
    }
  };

  render() {
    const { sliding, slideCount } = this.state;
    const {
      classes,
      images,
      width,
      imageCountVisible,
      height,
      links,
    } = this.props;
    const imageWidth = width / imageCountVisible;

    return (
      <div
        className={classes.carousselHolder}
        style={{ height: height, width: width }}
      >
        {images.map((image, key) => {
          const left = !sliding
            ? key * imageWidth
            : key * imageWidth - slideCount * imageWidth;
          return (
            <div
              className={classes.carousselImgHolder}
              key={key}
              style={{
                width: imageWidth,
                maxWidth: imageWidth,
                height: height,
                left: left,
              }}
            >
              <a
                href={links && links.length ? links[key] : "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className={classes.carousselImg}
                  style={{
                    backgroundImage: `url(${image.black})`,
                    minHeight: height,
                  }}
                >
                  <div
                    className={classes.carousselImgBlack}
                    style={{
                      backgroundImage: `url(${image.normal})`,
                      minHeight: height,
                    }}
                  />
                </div>
              </a>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withStyles(styles)(BigCaroussel);
