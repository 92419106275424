import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Work.styles";
import { connect } from "react-redux";
import Footer from "../../partials/Footer";
import HugeTitle from "../../text/HugeTitle";
import BigText from "../../text/BigText";
import classNames from "classnames";
import BlogItem from "../home/BlogItem";
import Blog1 from "../../../assets/img/case_sipwell_1.jpg";
import Blog12 from "../../../assets/img/case_sipwell_2.jpg";
import Blog2 from "../../../assets/img/case_ginsonline_1.jpg";
import Blog22 from "../../../assets/img/case_ginsonline_2.jpg";
import Blog3 from "../../../assets/img/case_daf_1.jpg";
import Blog32 from "../../../assets/img/case_daf_2.jpg";
import Blog4 from "../../../assets/img/case_zuiderburen_1.jpg";
import Blog42 from "../../../assets/img/case_zuiderburen_2.jpg";
import Blog5 from "../../../assets/img/case_easypost_1.jpg";
import Blog52 from "../../../assets/img/case_easypost_2.jpg";
import StyledSimpleButton from "../../button/StyledSimpleButton";
import BigTitle from "../../text/BigTitle";
import SubTitle from "../../text/SubTitle";
import StyledButton from "../../button/StyledButton";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import Translate from "../../../Translate/Translate";
import RadiusImg from "../../image/RadiusImg";
import VerImg from "../../../assets/img/verwarmmetpellet_work.png";
import {ThemeAbstracts} from "../../../config/MuiTheme";
import VerImg2 from "../../../assets/img/cases-verwarmmetpellets.png";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Work extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? window.outerHeight : windowHeight || 700;
    const blogCount = 4;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia - {Translate.translate("workTitle")}</title>
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          className={classes.topContainer}
          style={{ minHeight: theWindowHeight - 70 }}
        >
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{ paddingTop: 80, paddingLeft: 10, paddingRight: 10 }}
          >
            <div
              className="animate__animated animate__flipInX"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <HugeTitle
                style={{ marginBottom: 0, paddingBottom: 0, lineHeight: 0.8 }}
              >
                Work.
              </HugeTitle>
              <BigText style={{ maxWidth: 460, marginTop: 0, paddingTop: 0 }}>
                {Translate.translate("workSubTitle")}
              </BigText>
            </div>
            <Grid
              container
              direction="row"
              className={classes.midContainer}
              style={{
                alignItems: "flex-start",
                marginTop: 80,
                marginBottom: 100,
              }}
            >
              <Grid container direction="row" className={classes.boxHolderUnder} style={{ overflow: "hidden" }}>
                <Grid
                    item
                    xs={12}
                    md={6}
                    className={classNames(
                        classes.blogBoxLeft,
                        "animate__animated animate__fadeInRight animate__delay-1s",
                    )}
                >
                  <BlogItem
                      title="Verwarm met pellets"
                      text={Translate.translate("homeCaseTextVerwarm")}
                      tags="Strategy - digital marketing - development - graphic design - analytics"
                      img={VerImg}
                      img2={VerImg2}
                      link="/case/verwarmmetpellets"
                      right={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classNames(
                    classes.blogBoxRight,
                    "animate__animated animate__fadeInLeft animate__delay-1s",
                  )}
                >
                  <BlogItem
                    title="EasyPost Calculator"
                    text={Translate.translate("homeCaseText5")}
                    tags="Strategy - digital marketing - development - analytics"
                    img={Blog5}
                    right={false}
                    img2={Blog52}
                    link="/case/easypost"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" className={classes.boxHolderUnder} style={{ overflow: "hidden" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classNames(
                    classes.blogBoxLeft,
                    "animate__animated animate__fadeInRight animate__delay-1s",
                  )}
                >
                  <BlogItem
                    title="SipWell"
                    text={Translate.translate("homeCaseText1")}
                    tags="Strategy - branding - digital marketing - graphic design - copywriting - development - analytics"
                    img={Blog1}
                    right={true}
                    img2={Blog12}
                    link="/case/sipwell"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classNames(
                    classes.blogBoxRight,
                    "animate__animated animate__fadeInLeft animate__delay-1s",
                  )}
                >
                  <BlogItem
                    title="D'Angello &amp; Francis"
                    text={Translate.translate("homeCaseText3")}
                    tags="branding - graphic design - merchandising - photo and video"
                    img={Blog3}
                    img2={Blog32}
                    right={false}
                    link="/case/dangelloandfrancis"
                  />
                </Grid>
              </Grid>
              <Grid
                  container
                  direction="row"
                  className={classes.boxHolderUnder}
                  style={{ overflow: "hidden" }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classNames(
                    classes.blogBoxLeft,
                    "animate__animated animate__fadeInRight animate__delay-1s",
                  )}
                >
                  <BlogItem
                    img2={Blog42}
                    title="Zuiderburen"
                    text={Translate.translate("homeCaseText4")}
                    tags="graphic design - re-branding - print"
                    img={Blog4}
                    right={true}
                    link="/case/zuiderburen"
                  />
                </Grid>


                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classNames(
                    classes.blogBoxRight,
                    "animate__animated animate__fadeInLeft animate__delay-1s",
                  )}
                >
                  <BlogItem
                    img2={Blog22}
                    title="Ginsonline.be"
                    text={Translate.translate("homeCaseText2")}
                    tags="Strategy - digital marketing - analytics"
                    img={Blog2}
                    right={false}
                    link="/case/ginsonline"
                  />
                </Grid>
              </Grid>
              <Grid
                  container
                  direction="row"
                  className={classes.boxHolderUnder}
                  style={{ overflow: "hidden" }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classNames(
                    classes.blogBoxRight,
                    "animate__animated animate__fadeInRight animate__delay-1s",
                  )}
                ></Grid>

              </Grid>
              {blogCount > 4 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <StyledSimpleButton style={{ marginTop: 60 }}>
                    {Translate.translate("seeMore")}
                  </StyledSimpleButton>
                </Grid>
              )}
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 100 }}
              >
                <BigTitle
                  style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
                  variant="h2"
                >
                  {Translate.translate("contactUs")}
                </BigTitle>
                <SubTitle
                  style={{ marginTop: 0, paddingTop: 0, width: "100%" }}
                >
                  {Translate.translate("contactGrow")}
                </SubTitle>
                <NavLink to="/contact" style={{ textDecoration: "none" }}>
                  <StyledButton inverse={true}>
                    {Translate.translate("contactLetsWork")}
                  </StyledButton>
                </NavLink>
                <Grid container style={{ height: 120 }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Work));
