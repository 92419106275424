import { createStyles, Grid, Theme, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../../redux/reducers";
import Header from "../../../partials/Header";
import PageView from "../../../partials/PageView";
import { connect } from "react-redux";
import Footer from "../../../partials/Footer";
import BigText from "../../../text/BigText";
import BigTitle from "../../../text/BigTitle";
import StyledButton from "../../../button/StyledButton";
import { NavLink } from "react-router-dom";
import RadiusImg from "../../../image/RadiusImg";
import TopImg from "../../../../assets/img/ios14_banner.jpg";
import { ThemeAbstracts } from "../../../../config/MuiTheme";
import SmallTitle from "../../../text/SmallTitle";
import SmallText from "../../../text/SmallText";
import SmallBlogText from "../../../text/SmallBlogText";
import Helmet from "react-helmet";
import ContactForm from "../../contact/ContactForm";
import PieChart from "../../../../assets/img/ios14piechart.png";

interface State {}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

const styles = (theme: Theme) =>
  createStyles({
    topContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topBox: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      display: "flex",
      alignItems: "flex-start",
    },
    midContainer: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    blogTags: {
      marginTop: 16,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 18,
      textTransform: "uppercase",
      fontWeight: "bold",
      letterSpacing: -1,
      lineHeight: 0.9,
      color: "#779ada",
    },
    blogItemsHolder: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    blogHolder: {
      display: "flex",
      justifyContent: "center",
    },
    blogBox: {
      width: "100%",
      maxWidth: 680,
    },
  });

const data = {
  author: "Alessandro Bianchi",
  readTime: 6,
  title: "iOS 14 update en Facebook advertenties",
  subTitle:
    "Hoe iOS 14 een impact heeft op je Facebook advertenties en wat je er aan kunt doen.",
  blogTitleImg: TopImg,
  blocks: [
    {
      items: [
        {
          type: "smalltext",
          content: (
            <>
              Het is je vast niet voorbijgegaan, maar sinds enkele weken is er
              veel onrust in het marketinglandschap. Dit vooral binnen de
              advertising-community. Er staat een impactvol keerpunt op de
              agenda voor Facebook-adverteerders, je leest er hier meer over.
            </>
          ),
        },
        {
          type: "smalltitle",
          content: "Waarom heeft iOS 14 gevolgen voor Facebook advertenties?",
        },
        {
          type: "smalltext",
          content: (
            <>
              iOS 14 is de nieuwste versie van Apple’s besturingssysteem. Bijna
              alle apparaten, nieuwer dan de 6e generatie, zoals de iPhone 6,
              zijn geschikt voor een update naar iOS 14.
              <br />
              <br />
              Met deze laatste update zijn er heel wat aanpassingen gebeurd met
              betrekking tot de privacy van Apple-gebruikers. Apple verplicht
              onder andere ontwikkelaars van apps om toestemming te vragen aan
              gebruikers voordat belangrijke gegevens gedeeld mogen worden met
              derden zoals bedrijven, marketingbureaus, … .
              <br />
              <br />
              Maar hoe groot is de impact van deze update nu voor adverteerders?
              Begin 2020 heeft Tim Cook (CEO van Apple) een update gedeeld
              waarin hij vermeldde dat er in totaal 1,4 miljard actieve Apple
              devices in omloop zijn. Voor België vertaalt dit zich naar een
              verbluffende 38,45% marktaandeel.
            </>
          ),
        },
        {
          type: "img",
          content: PieChart,
        },
        {
          type: "smalltitle",
          content:
            "Wat zijn de gevolgen van iOS 14 voor Facebook adverteerders?",
        },
        {
          type: "smalltext",
          content: (
            <>
              Om het te bundelen in enkele keywords: personalisatie, data en
              rapportage. Het grootste gevolg van deze update is dat
              adverteerders minder gericht kunnen adverteren en optimaliseren.
              <br />
              <br />
              Concreet zou de update van iOS 14 een impact hebben op volgende
              zaken:
              <br />
              <ul>
                <li>
                  Het attributiemodel wordt gewijzigd van 28-daags naar 7-daags:
                  wat wil zeggen dat leads enkel nog maar toegewezen worden
                  binnen de afgelopen 7 dagen. Gelukkig kunnen we dit opvangen
                  door alles in Google Analytics te analyseren.
                </li>
                <li>
                  Optimaliseren voor maximaal 8 conversie-events per domein.
                </li>
                <li>
                  Aangepaste en vergelijkbare doelgroepen worden minder
                  nauwkeurig.
                </li>
                <li>Remarketing campagnes worden een stuk minder effectief.</li>
                <li>
                  Campagnes met de doelstelling ‘conversion’, worden beperkt.
                </li>
              </ul>
            </>
          ),
        },
        {
          type: "smalltitle",
          content:
            "Hoe beperk je de gevolgen van iOS 14 voor Facebook advertenties?",
        },
        {
          type: "smalltext",
          content: (
            <>
              Op het eerste zicht lijkt het alsof dit de apocalyps van Facebook
              advertising is. Maar vrees niet, er zijn manieren waardoor we de
              schade kunnen beperken en verschillende zaken kunnen vermijden
              door een alternatieve aanpak te hanteren.
              <br />
              <br />
              Lees hier enkele acties waarmee je de gevolgen voor jouw bedrijf
              inperkt:
              <br />
              <ol>
                <li>
                  <strong>
                    Installeer de Conversion API van Facebook op jouw website
                  </strong>
                  <br />
                  Het is niet de meest eenvoudige oplossing, maar wel de
                  krachtigste. De Conversion API (voorheen de server side events
                  genoemd), is een API toepassing die dient als alternatief voor
                  de Facebook-pixel. Hierdoor ben je niet afhankelijk van je
                  browser, maar van je eigen server. Dit zorgt ervoor dat
                  adblockers en cookies geen effect hebben op het vergaren van
                  essentiële data.
                  <br />
                  <br />
                  Met andere woorden: wanneer je Facebook-gebruikers tot op jouw
                  website krijgt, heb je al een groot deel van de gevolgen
                  ontweken. Dit dan vooral voor je remarketing campagnes. De
                  conversion-fase uit de funnel is dus al grotendeels gecoverd.
                </li>
                <li>
                  <strong>Verifieer je domein bij Facebook</strong>
                  <br />
                  Deze actie is een pak eenvoudiger dan het instellen van de
                  Conversion API en is evenals essentieel om verder te kunnen
                  adverteren.
                  <br />
                  <br />
                  Je domein verifiëren doe je als volgt: je gaat naar de
                  Facebook Business Manager, hier kies je bedrijfsinstellingen,
                  je klikt op merkveiligheid, domeinen en hier voeg je het
                  domein van jouw website toe. <br />
                  <br />
                  Facebook genereert een TXT-record die je daarna binnen het
                  hosting platform van je website kan toevoegen, hierna ga je
                  terug naar Facebook (let op: dit kan tot 72 uur duren) en
                  verifieer je jouw domein.
                </li>
                <li>
                  <strong>
                    Selecteer de 8 belangrijkste conversie-events voor jouw
                    bedrijf
                  </strong>
                  <br />
                  Met de update van iOS 14, wordt Facebook beperkt om te
                  optimaliseren voor maximaal 8 conversie-events. Daarom is het
                  belangrijk om de 8 belangrijkste conversie-events voor jouw
                  bedrijf te selecteren.
                  <br />
                  <br />
                  Voor elk bedrijf is dit natuurlijk anders, het ene bedrijf
                  heeft maar 3 events nodig, terwijl het andere zich voordien
                  baseerde op 10+ events.
                  <br />
                  <br />
                  Best houd je ook rekening dat de pixel / het algoritme steeds
                  ‘opgewarmd’ dient te worden. Een plek vrij houden voor een
                  event dat regelmatig voorkomt, en de eerste stap zet richting
                  een waardevolle conversie, is dus een slim idee. Voor
                  e-commerce zou dit bijvoorbeeld kunnen zijn: ‘View Content’.
                </li>
                <li>
                  <strong>
                    Meet het huidige aandeel van iOS devices in jouw campagnes
                  </strong>
                  <br />
                  Door een rapportage te trekken van de performances van jouw
                  Facebook-campagnes in het afgelopen kwartaal, krijg je een
                  beter zicht op wat het aandeel van Apple is binnen je
                  audiences. Hierdoor kan je jezelf beter voorbereiden op de
                  impact die het zal hebben op je cijfers.
                  <br />
                  <br />
                  Dit kan je doen door binnen het advertentiebeheer de
                  specificatie aan te passen naar ‘Per weergave -
                  weergave-apparaat’.
                </li>
                <li>
                  <strong>Denk outside the box en bekijk alternatieven</strong>
                  <br />
                  Het is belangrijk om je voor te bereiden op een wereld waarin
                  de bescherming van gebruikersdata belangrijker en belangrijker
                  wordt. Er komen hoogstwaarschijnlijk meer en meer
                  gelijkaardige aanpassingen komen aan advertentieplatformen.
                  Maar laat dit je vooral niet tegenhouden.
                  <br />
                  <br />
                  Om je in te dekken tegen een implosie van je advertising
                  performance, is het interessant om alternatieve mogelijkheden
                  te bekijken. Don’t put all your eggs in one basket.
                  <br />
                  <br />
                  Denk hiervoor aan platformen zoals YouTube ads, TikTok, het
                  Google Display netwerk,…
                </li>
              </ol>
            </>
          ),
        },
        {
          type: "smalltitle",
          content: "De wereld van adverteren in verandering",
        },
        {
          type: "smalltext",
          content: (
            <>
              De update van iOS 14 was een eerste groot keerpunt, de kans dat er
              gelijkaardige zaken gebeuren in de nabije toekomst is groot.
              Rendabel én effectief adverteren wordt steeds complexer
              <br />
              <br />
              Het wordt namelijk steeds moeilijker voor adverteerders om ervoor
              te zorgen dat de ROI goed blijft en de performance van de
              campagnes geen duik neemt.
              <br />
              <br />
              Zie je de bomen door het bos niet meer? Geen zorgen. Ik kijk graag
              met je mee en help je bij het creëren van een kogelvrije strategie
              in een digitale wereld van verandering.
            </>
          ),
        },
      ],
    },
  ],
  contactText:
    "Winnen met Facebook? Laat jouw gegevens achter en we contacteren jou spoedig terug.",
};

class SkillLabIOS14 extends Component<Props, State> {
  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    return (
      <PageView>
        <Helmet>
          <title>Skillmedia | {data.title}</title>
        </Helmet>
        <Header />
        <Grid container direction="column" className={classes.topContainer}>
          <Grid
            container
            direction="column"
            className={classes.topBox}
            style={{
              paddingTop: 80,
              paddingLeft: 10,
              overflow: "hidden",
              paddingRight: 10,
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <div
                  className="animate__animated animate__flipInX"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 680,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <BigTitle
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        lineHeight: 1,
                        maxWidth: 600,
                        textAlign: "left",
                      }}
                      bigger={true}
                    >
                      {data.title}
                    </BigTitle>
                    <SmallText
                      style={{
                        maxWidth: 600,
                        marginBottom: 16,
                        lineHeight: 1.4,
                        fontSize: 21,
                        letterSpacing: "-0.003em",
                        fontWeight: 400,
                        wordBreak: "break-word",
                        color: "rgba(41, 41, 41, 1)",
                      }}
                    >
                      {data.subTitle}
                    </SmallText>
                    <div>
                      <div className="sharethis-inline-share-buttons"></div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ height: 80 }} />
            <div
              style={{ width: "100%" }}
              className={"animate__animated animate__flipInX"}
            >
              <RadiusImg
                src={TopImg}
                maxWidth={ThemeAbstracts.layout.maxPageWidth}
                maxHeight={460}
                noEffect={true}
              />
            </div>
            <Grid container style={{ height: 100 }} />
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <p>
                  Door {data.author} - {data.readTime} minuten lezen
                </p>
                {data.blocks.map((block, b) => {
                  return (
                    <div key={b} className={classes.blogItemsHolder}>
                      {block.items.map((item, i) => {
                        if (item.type === "smalltitle") {
                          return (
                            <SmallTitle
                              key={i}
                              style={{
                                marginTop: 20,
                                lineHeight: 1.3,
                                textAlign: "left",
                                marginBottom: 20,
                              }}
                            >
                              {item.content}
                            </SmallTitle>
                          );
                        }
                        if (item.type === "smalltext") {
                          return (
                            <SmallBlogText
                              key={i}
                              style={{
                                maxWidth: 600,
                                marginBottom: 16,
                                lineHeight: 1.4,
                                fontSize: 21,
                                letterSpacing: "-0.003em",
                                fontWeight: 400,
                                wordBreak: "break-word",
                                color: "rgba(41, 41, 41, 1)",
                              }}
                            >
                              {item.content}
                            </SmallBlogText>
                          );
                        }
                        if (item.type === "img") {
                          return (
                            <div
                              key={i}
                              style={{
                                width: "100%",
                                maxWidth: 680,
                                marginTop: 20,
                                marginBottom: 20,
                              }}
                            >
                              <RadiusImg
                                src={item.content}
                                maxWidth={680}
                                noEffect={true}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <NavLink
              to="/skilllab"
              style={{ marginTop: 60, textDecoration: "none" }}
            >
              <StyledButton
                className={"animate__animated animate__bounceInUp"}
                style={{ marginTop: 60 }}
              >
                Meer in Skill Lab
              </StyledButton>
            </NavLink>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: 100 }}>
            <Grid container className={classes.blogHolder}>
              <Grid className={classes.blogBox}>
                <BigText style={{ marginLeft: 10, marginRight: 10 }}>
                  {data.contactText}
                </BigText>
                <ContactForm
                  sendText="Verstuur"
                  title="Contacteer ons"
                  subTitle={data.contactText}
                  minimized={true}
                />
              </Grid>
            </Grid>
            <Grid container style={{ height: 120 }} />
          </Grid>
        </Grid>
        <Footer />
      </PageView>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(SkillLabIOS14));
